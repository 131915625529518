import React from 'react';
import EnterNewStoreSectionView from './EnterNewStoreSectionView';
import SisterStoreSectionView from './SisterStoreSectionView';
import DCSourcingContainer from './DCSourcingContainer';
import "./Storedetailsconfig.css";
import StoreOpeningDetails from './StoreOpeningDetails';
import PageLoader from "../../../../components/Loader/PageLoader";

/**
 * Dc Source View Component
 * @returns DC Source Component
 */
const StoreDetailsDcConfigView = (props) => {
    return (
        <React.Fragment>
            <PageLoader loader={props.saveLoading}>
            <div className="section_container shadow-sm mb-4">
                <EnterNewStoreSectionView editMode = {props?.editMode} editStoreCode={props.editStoreCode} />
            </div>
            <div className="section_container shadow-sm">
                <StoreOpeningDetails editMode = {props?.editMode} />
            </div>
            {/* <React.Fragment>
                <DCSourcingContainer setDcSrcRowsSelection={props?.setDcSrcRowsSelection} />
            </React.Fragment> */}
            </PageLoader>
        </React.Fragment >
    )
}


export default StoreDetailsDcConfigView;