import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import "../NewStoreView.css"
import StepperComponent from '../../../../components/StepperComponent/StepperComponent';
import StoreDetailsConfigContainer from "../StoreDetailsConfig/StoreDetailsConfigContainer";
import InventoryDropContainer from "../InventoryDrop/InventoryDropView";
import DemandConstraints from "../DemandConstraints/DemandConstraints"
import ReviewAllocation from "../ReviewAllocation/ReviewAllocation"
import Notification from "../../../../components/Notification/Notifications";
import * as Notify from "../../../../components/Notification/Notifications";
// import ErrorModal from "../../../components/ErrorModal/ErrorModal"
// import NameingModal from "../../../components/NameingModal/NameingModal"
import { connect } from "react-redux";
import { resetDemandData, saveNewStoreAllocation, reviewNewStoreAllocation } from "../../actions/DemandAction/DemandAction"
import { upsertNewStoreDetails, resetAll } from "../../actions/DCConfigAction/DCConfigAction"
import { setEditModeDetails, resetStoreDetailsConfig, updateDcSourceLeadTime } from "../../actions/StoreDetailsConfigActions/StoreConfigActions";
import {
    NEW_STORE_PATH_DETAILSCONFIG_LABEL, NEW_STORE_PATH_INVDROP_LABEL,
    NEW_STORE_PATH_DEMANDCONSTRAINTS_LABEL, NEW_STORE_PATH_REVIEWALLOCATION_LABEL
} from '../../NewStoreConstants';
import { SSE_CREATE_ALLOCATON } from "../../../../constants/apiConstants";
import DCConfig from '../DCConfig/DCConfig';
import { cloneDeep, isEmpty } from 'lodash';
import { PRODUCT_STORE_STATUS } from '../../../../constants/routeConstants';
import PageLoader from '../../../../components/Loader/PageLoader';

let eventSource

const CreateEditNewStore = (props) => {
    const { allocation_id, store_code, sister_store_code, opening_date, is_edit, allocation_name, resetDemandData, saveNewStoreError,
        saveNewStoreSuccess, saveLoading, saveNewStoreAllocation, resetStoreDetailsConfig, setEditModeDetails, dcSourceSelected,
        reviewNewStoreAllocation, newAllocationError, newAllocationData, newAllocationSuccess, isRelease, sisterStoreFilters,
        selectedStore, upsertNewStoreDetails, saveDetailsSuccess, saveDetailsError, resetAll, saveDetailsLoading, updateDcSourceLeadTime,
        leadTimeDcMapping } = props

    let history = useHistory();

    const [activeStep, setActiveStep] = useState(0);
    const [isEditFlow, setIsEditFlow] = useState(false);
    const [demandSelectedRows, setDemandSelectedRows] = useState([])
    const [isAddProduct, setIsAddProduct] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [showNameModal, setShowNameModal] = useState(false)
    const [reviewAllocationRows, setReviewRows] = useState([])
    const [errorModalMessage, setErrorModalMessage] = useState("")
    const [dcSrcRows, setDcSrcRowsSelection] = useState([]);
    const [editMode, setIsEditMode] = useState(false)
    const [allocationCode, setAllocationCode] = useState("")
    const [allocationLoading, setAllocationLoading] = useState(false)
    const [finalProducts, setFinalSelectedProducts] = useState([])
    const [editStoreCode, setStoreCode] = useState()

    const isMoveToNextStep = () => {
        let mandatoryKeys = {
            storeDetails: ["store_code", "open_date", "reservation_start_date"],
            dcConfig: ["sister_store_filters", "dcs"]
        }
        if (activeStep === 0) {
            for (let i = 0; i < mandatoryKeys.storeDetails.length; i++) {
                if (!selectedStore?.[mandatoryKeys.storeDetails[i]]) {
                    return false
                }
            }
            return true
        }
        else if (activeStep === 1) {
            return sisterStoreFilters?.length && dcSourceSelected?.length
        }
        else {
            return true
        }
    }

    const saveStoreDetails = () => {
        if (isMoveToNextStep()) {
            let selectedStoreDetails = cloneDeep(selectedStore)
            delete selectedStoreDetails["dcs"]
            let payload = activeStep === 0 ? selectedStoreDetails : { ...selectedStoreDetails, sister_store_filters: sisterStoreFilters, dcs: dcSourceSelected }
            upsertNewStoreDetails(payload)
            updateDCLeadTime()
        }
        else {
            notifyError("All Mandatory Options!!")
        }
    }

    const componentArr = [{
        label: NEW_STORE_PATH_DETAILSCONFIG_LABEL,
        component: <StoreDetailsConfigContainer editStoreCode={editStoreCode} editMode={editMode} />,
        nextButtonText: "Next",
        funcButtons: [{ name: "Save", onClickHandler: saveStoreDetails, disabled: localStorage.getItem('group_id') != 1 || editMode && new Date(selectedStore?.reservation_start_date) < new Date() }]
    },
    {
        label: NEW_STORE_PATH_INVDROP_LABEL,
        component: <DCConfig setDcSrcRowsSelection={setDcSrcRowsSelection} editMode={editMode} setIsEditMode={setIsEditMode} />,
        nextButtonText: "Import Demand & Constraints",
        funcButtons: [{ name: "Save", onClickHandler: saveStoreDetails, disabled: localStorage.getItem('group_id') != 1 }]
    },
    {
        label: NEW_STORE_PATH_DEMANDCONSTRAINTS_LABEL,
        component: <DemandConstraints setIsMaxError={setErrorMessage} loading={allocationLoading} editMode={editMode}
            allocation_id={allocation_id} sister_store_code={sister_store_code} store_code={store_code} setIsEditMode={setIsEditMode}
            setDemandSelectedRows={setDemandSelectedRows} setIsAddProduct={setIsAddProduct} setFinalSelectedProducts={setFinalSelectedProducts} />,
        // nextButtonText: "Review Allocation",
        // funcButtons: [{ name: "Save", onClickHandler: saveStoreDetails }]
    },
        // {
        //     label: NEW_STORE_PATH_REVIEWALLOCATION_LABEL,
        //     component: <ReviewAllocation allocation_code={allocationCode} setReviewRows={setReviewRows}
        //         sister_store_code={isRelease ? sister_store_code : props.selectedSisterStoreId} data={demandSelectedRows} />,
        //     nextButtonText: isRelease ? "Release" : "Confirm Reserve Qty",
        //     isRelease
        // }
    ];

    useEffect(() => {
        if (isRelease) {
            setAllocationCode(allocation_id)
            setActiveStep(3)
        }
        if(props?.location?.state?.is_edit) {
            setIsEditMode(true)
            setStoreCode(props?.location?.state?.store_code)
        }
    }, [isRelease, props?.location?.state?.is_edit])

    const goBack = () => {
        let count = activeStep - 1
        if (count < 0) {
            history.push(PRODUCT_STORE_STATUS, { isNewStore: true });
        }
        else {
            setActiveStep(count < 0 ? 0 : count)
        }

    }

    const moveForward = () => {
        const { selectedNewStore, selectedSisterStore } = props;
        let count = activeStep + 1;
        if (count === 1) {
            if (!isMoveToNextStep()) {
                notifyError("mandatory options")
            }
            else if (errorMessage) {
                setShowErrorModal(true);
            }
            else {
                setActiveStep(count < 4 ? count : 2);
            }
        } else if (count === 2) {
            if (!sisterStoreFilters?.length) {
                Notify.error("Click save to continue!!")
            }
            else if (isEmpty(dcSrcRows)) {
                notifyError("minimum of 1 Dc Source option")
            }
            else {
                setAllocationLoading(false)
                setActiveStep(count)
            }
        } else if (count === 3) {
            if (!finalProducts?.length) {
                notifyError("atleast 1 product")
            }
            else if (errorMessage) {
                setShowErrorModal(true)
            }
            else {
                // reviewAllocation()
                saveAllocationPlan()
            }
        }
        else {
            if (count === 4) {
                if (!isRelease) {
                    setShowNameModal(true)
                }
            }
            setActiveStep(count < 4 ? count : 3)
        }
    }

    const reviewAllocation = () => {
        const { selectedStore, selectedSisterStoreId } = props
        if (checkWos()) {
            setAllocationLoading(true)
            let req = {
                articles: finalProducts,
                new_store_id: selectedStore.store_code,
                sister_store_id: selectedSisterStoreId
            }
            reviewNewStoreAllocation(req)
        }
    }

    const checkWos = () => {
        let articlesWithZeroWOS = demandSelectedRows.filter(row => row.wos === 0).map(item => item.article)
        let errorMsg;
        if (articlesWithZeroWOS.length) {
            errorMsg = articlesWithZeroWOS.length === 1 ? `WOS for ${articlesWithZeroWOS.join(', ')} article is Zero!!` : `WOS for ${articlesWithZeroWOS.join(', ')} articles are Zero!!`
            setErrorMessage(errorMsg)
            setShowErrorModal(true)
        }
        else {
            return true
        }
    }

    const notifyError = (text) => {
        Notify.error(`Please select ${text} to continue!!`)
    }

    const updateDCLeadTime = () => {
        if(!isEmpty(leadTimeDcMapping)) {
            updateDcSourceLeadTime(Object.keys(leadTimeDcMapping).map(dc => {return {store_code: selectedStore.store_code, dc_code: dc, lead_time: leadTimeDcMapping[dc]}}))
        }
    }

    const saveAllocationPlan = (name) => {
        const { selectedStore, selectedSisterStoreId, openDate,
            dcSelected, selectedDemandRows, editedDcSourceRowsTime } = props
        let articles = cloneDeep(finalProducts)
        articles.forEach(item => {
            if (!item?.sister_store_codes) {
                item.sister_store_codes = item.sister_stores
            }
        })
        let saveObj = {
            "store_id": selectedStore.store_code,
            "store_name": selectedStore.store_name,
            // "allocation_name": name,
            "opening_date": openDate,
            // "inventory_source": { "dc": dcSelected.map(item => item.dc) },
            // allocation_id: allocationCode,
            "article_data": articles,
            "user_name": localStorage?.getItem("email"),
            "reservation_start_date": selectedStore?.reservation_start_date
        }
        saveStoreDetails()
        saveNewStoreAllocation(saveObj)
        // updateDcSourceLeadTime(editedDcSourceRowsTime);
    }

    useEffect(() => {
        if (editMode) {
            setEditModeDetails({
                isEditMode: true,
                editModeNewStoreCode: store_code?.toString(),
                editModeOpeningDate: opening_date,
                editModeSisterStoreCode: sister_store_code?.toString(),
            });
        } else {
            setEditModeDetails({
                isEditMode: false,
            });
        }
    }, []);

    useEffect(() => {
        if (saveNewStoreSuccess) {
            setIsEditMode(false)
            setShowNameModal(false)
            setTimeout(() => {
                Notify.success("New Store Saved Successfully!!")
            }, 100)
            resetStoreDetailsConfig();
            resetDemandData();
            setTimeout(() => {
                // setActiveStep(0)
                history.push(PRODUCT_STORE_STATUS, { isNewStore: true });
            }, 2000)
        }
        else if (saveNewStoreError) {
            setErrorModalMessage(saveNewStoreError)
        }
    }, [saveNewStoreSuccess, saveNewStoreError])

    useEffect(() => {
        if (newAllocationSuccess) {
            eventSource = new EventSource(`${SSE_CREATE_ALLOCATON}?uuid_str=${newAllocationData}`)

            // Navigate to review step after got success message from SSE

            eventSource.onmessage = (event) => {
                setAllocationLoading(false)
                const result = JSON.parse(event.data).data;
                eventSource.close();
                if (result.status && result.allocation_code) {
                    setAllocationCode(result.allocation_code)
                    setActiveStep(pre => pre + 1)
                }
                else if (result?.message) {
                    Notify.error(result.message)
                }
            }

            eventSource.onerror = (event) => {
                if (event.target.readyState === EventSource.CLOSED) {
                    console.log('SSE closed (' + event.target.readyState + ')')
                }
                eventSource.close();
            }

            // eventSource.onopen = (event) => {
            //     console.log("connection opened")
            // }
        }
        else if (newAllocationError) {
            Notify.error(`${newAllocationError}!!`)
        }
    }, [newAllocationSuccess, newAllocationError])

    useEffect(() => {
        return () => {
            resetStoreDetailsConfig();
            resetAll()
            setTimeout(() => {
                resetDemandData();
            }, 1000)
        }
    }, []);

    useEffect(() => {
        if (saveDetailsError) {
            Notify.error("Error in Save!!")
        }
        else if (saveDetailsSuccess && activeStep < 2) {
            Notify.success("New Store Details Saved Successfully!!")
        }
    }, [saveDetailsSuccess, saveDetailsError])

    return (
        <>
            {/* {
                !isAddProduct &&
                <Notification />
            } */}
            <PageLoader loader={saveLoading} gridLoader={true}>
                <StepperComponent activeStep={activeStep} steps={componentArr}
                    heading={`Store Status/${editMode ? "Edit" : "New"} Store Setup`}
                    goBack={goBack} moveForward={moveForward} />
            </PageLoader>

            {/* {showErrorModal && <ErrorModal message={errorMessage} handleCancel={() => setShowErrorModal(false)} handleOk={() => setShowErrorModal(false)} handleClose={() => setShowErrorModal(false)} />}
            {showNameModal && <NameingModal label="Allocation Plan Name" showModal={showNameModal}
                closeModal={() => { setShowNameModal(false) }} save={saveAllocationPlan}
                errorModalMessage={errorModalMessage} loading={saveLoading} name={allocation_name} />} */}
        </>
    )
}

const mapStateToProps = (newStoreSetup) => {
    const { newStoreDetailConfig, demandConstraints, sisterStoreDCConfig } = newStoreSetup
    return {
        selectedStore: newStoreDetailConfig?.selectedNewStore[0],
        selectedSisterStoreId: newStoreDetailConfig?.selectedSisterStore[0]?.store_code,
        openDate: newStoreDetailConfig?.newStoreOpeningDate,
        dcSelected: newStoreDetailConfig?.dcSourceSelectedRowsData,
        saveNewStoreSuccess: demandConstraints.saveNewStoreSuccess,
        saveNewStoreError: demandConstraints.saveNewStoreError,
        saveLoading: demandConstraints.saveLoading,
        selectedDemandRows: demandConstraints.selectedDemandRows,
        selectedNewStore: newStoreDetailConfig.selectedNewStore,
        selectedSisterStore: newStoreDetailConfig.selectedSisterStore,
        editedDcSourceRowsTime: newStoreDetailConfig.editedDcSourceRowsTime,
        newAllocationData: demandConstraints.newAllocationData,
        newAllocationError: demandConstraints.newAllocationError,
        newAllocationSuccess: demandConstraints.newAllocationSuccess,
        sisterStoreFilters: sisterStoreDCConfig?.sisterStoreFilters,
        saveDetailsSuccess: sisterStoreDCConfig?.saveNewStoreSuccess,
        saveDetailsError: sisterStoreDCConfig?.saveNewStoreError,
        saveDetailsLoading: sisterStoreDCConfig?.saveDetailsLoading,
        dcSourceSelected: sisterStoreDCConfig?.selectedDc,
        leadTimeDcMapping: sisterStoreDCConfig?.leadTimeDcMapping?.mapping
    }
}

const mapDispatchToProps = (dispatch) => ({
    resetDemandData: () => dispatch(resetDemandData()),
    saveNewStoreAllocation: (payload) => dispatch(saveNewStoreAllocation(payload)),
    setEditModeDetails: (payload) => dispatch(setEditModeDetails(payload)),
    updateDcSourceLeadTime: (payload) => dispatch(updateDcSourceLeadTime(payload)),
    resetStoreDetailsConfig: () => dispatch(resetStoreDetailsConfig()),
    reviewNewStoreAllocation: (payload) => dispatch(reviewNewStoreAllocation(payload)),
    upsertNewStoreDetails: (payload) => dispatch(upsertNewStoreDetails(payload)),
    resetAll: () => dispatch(resetAll()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditNewStore);