import React, { useEffect, useMemo, useState } from "react";
import ConstraintsFilter from "../../components/Filters/ConstraintsFilter";
import {
  fetchStoreGradeData,
  fetchStoreGroupData,
  fetchStoreData,
  resetAll,
  fetchMinPerStore,
  resetStyleIndex,
  resetUpdateTableData
} from "../../containers/Constraints/ConstraintsAction";
import { connect } from "react-redux";
import StoreGroup from "./ConstraintsTable/StoreGroup";
import StoreGrade from "./ConstraintsTable/StoreGrade";
import Store from "./ConstraintsTable/Store";
import "./Constraints.css";
import Notification from "../../components/Notification/Notifications";
import * as Notify from "../../components/Notification/Notifications";
import { cloneDeep } from "lodash";

export const rowsCount = 1000
export const styleIndex = 100

const initialindex = 0

const Constraints = (props) => {
  const [storeGroupData, setStoreGroupData] = useState([]);
  const [storeGradeData, setStoreGradeData] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("store");
  const [filters, setFilters] = useState(null);
  const [RTinstanceStoreGroup, setRTinstanceStoreGroup] = useState(null)
  const [RTinstanceStoreGrade, setRTinstanceStoreGrade] = useState(null)
  const [RTinstanceStore, setRTinstanceStore] = useState(null)
  const [isFirstCall,setIsFirstCall] = useState(true)
  const [isFilterClicked, setIsFilterClicked] = useState(false)
  const [minBatchID,setMinBatchID] = useState(10000)

  useEffect(() => {
    if(selectedValue == "storeGrade"){
      if (props.storeGradeData?.length > 0) {
        // setStoreGradeData(props.storeGradeData);
        if(RTinstanceStoreGrade && RTinstanceStoreGrade.data){
          setStoreGradeData([...RTinstanceStoreGrade.data,...props.storeGradeData]);
        }
        else{
          setStoreGradeData([...props.storeGradeData]);
        }
        // setStoreGradeData(true)
      }
      else if(props.callNext) {
        // props.fetchStoreGradeData({filters:filters,"rowCount":rowsCount,"rowIndex":initialindex, "styleIndex": props?.styleIndex });
      }
      else{
        // setStoreGradeData([])
        // setRTinstanceStoreGrade(null)
      }
    }
  }, [props.storeGradeData, props.callNext]);
  
  useEffect(() => {
    if(selectedValue == "store"){
      if (props.storeData?.length > 0) {
        // setStoreGroupData(props.storeData);
        if(RTinstanceStore && RTinstanceStore.data && !props.isUpdated){
          setStoreData([...RTinstanceStore.data,...props.storeData]);
        }
        else{
          props.resetUpdateTableData()
          setStoreData([...props.storeData]);
        }
        // setStoreGroupData(true)
      }
      // else if(props.callNext) {
      //   props.fetchStoreData({filters:filters,"rowCount":rowsCount,"rowIndex":initialindex, "styleIndex": props?.styleIndex });
      // }
      else {
        // setStoreData([])
        // setRTinstanceStore(null)
      }
    }
  }, [props.storeData,props.callNext]);

  useEffect(() => {
    if(selectedValue == "storeGroup"){
      if(isFirstCall){
        setIsFirstCall(false)
      }
      console.log(props.storeGroupData,'dvefvfdss')
      if (props.storeGroupData?.length > 0) {
        if(RTinstanceStoreGroup && RTinstanceStoreGroup.data ){
        //  console.log([...RTinstanceStoreGroup.data.filter(val =>  val.batchID < minBatchID)],props.storeGroupData,RTinstanceStoreGroup.data,minBatchID,'csjbsdhc')
        //   setStoreGroupData([...RTinstanceStoreGroup.data.filter(val =>  val.batchID < minBatchID),...props.storeGroupData]);
        //   setMinBatchID(1000000000)
        setStoreGroupData([...RTinstanceStoreGroup.data,...props.storeGroupData]);
        }
        else{
          setStoreGroupData([...props.storeGroupData]);
        }
        // setStoreGroupData(true)
      }
      else if(props.callNext) {
        // props.fetchStoreGroupData({filters:filters,"rowCount":rowsCount,"rowIndex":initialindex, "styleIndex": props?.styleIndex });
      }
      // else{
      //   setStoreGroupData([])
      //   setRTinstanceStoreGroup(null)
      // }
    }
  }, [props.storeGroupData]);
  
  useEffect(() => {
    
    return () => {
      props.resetAll()
    }
  }, [])

  // const callAllApis = (p_payload) => {
  //   // props.fetchStoreGroupData(p_payload);
  //   // props.fetchStoreGradeData(p_payload);
  //   // props.fetchStoreData(p_payload);
  //   // props.fetchMinPerStore(p_payload);
  // };

  const setOtherViewsEmpty = (p_view,p_isModalUpdate = false, minBatchID) => {
   if(p_view === "storeGroup"){
     if(p_isModalUpdate){
      props.fetchStoreGroupData({filters:filters,"rowCount":rowsCount,"rowIndex":initialindex, "styleIndex": 0});
      // props.fetchMinPerStore(filters);
      setStoreGroupData([])
      setRTinstanceStoreGroup(null)
    }
    setMinBatchID(minBatchID)
    // else{
    //   setStoreGroupData((oldData) => {
    //     let oldDataCopy = cloneDeep(oldData);
    //     let newData = oldDataCopy?.filter(val => val.batchID < minBatchID)
    //     return newData;
    //   });

    //   setRTinstanceStoreGroup((oldData) => {
    //     let oldDataCopy = cloneDeep(oldData);
    //     let newData = oldDataCopy?.data?.filter(val => val.batchID < minBatchID)
    //     return newData;
    //   });
    // }
     setStoreGradeData([])
     setStoreData([])
   }
   if(p_view === "storeGrade"){
     if(p_isModalUpdate){
      props.fetchStoreGradeData({filters:filters,"rowCount":rowsCount,"rowIndex":initialindex, "styleIndex": 0});
      // props.fetchMinPerStore(filters);
      setStoreGradeData([])
      setRTinstanceStoreGrade(null)
     }
     setStoreGroupData([])
     setStoreData([])
   }
   if(p_view === "store"){
    // setStoreData([])
    //  setRTinstanceStore(null)
     setStoreGroupData([])
     setStoreGradeData([])
   }
  }

  useEffect(() => {
    props.resetStyleIndex()
    if(selectedValue === "storeGroup"  && filters){
      setRTinstanceStoreGroup(null)
      setStoreGroupData([])
      props.fetchStoreGroupData({filters:filters,"rowCount":rowsCount,"rowIndex":initialindex, "styleIndex": 0 });
      // props.fetchMinPerStore(filters);
    }
    if(selectedValue === "storeGrade" && filters){
      setRTinstanceStoreGrade(null)
      setStoreGradeData([])
      props.fetchStoreGradeData({filters:filters,"rowCount":rowsCount,"rowIndex":initialindex, "styleIndex": 0 });
      // props.fetchMinPerStore(filters);
    }
    if(selectedValue === "store" 
    // && !isFirstCall 
    && filters){
      setRTinstanceStore(null)
      setStoreData([])
      props.fetchStoreData({filters:filters,"rowCount":rowsCount,"rowIndex":initialindex, "styleIndex": 0});
      // props.fetchMinPerStore(filters);
    }
  }, [selectedValue])

  const applyFilters = (p_payload) => {
    props.resetStyleIndex()
    setIsFilterClicked(true)
    setStoreData([])
    setStoreGradeData([])
    setStoreGroupData([])
    setRTinstanceStore(null)
    setRTinstanceStoreGrade(null)
    setRTinstanceStoreGroup(null)
    setFilters(p_payload);
    setSelectedValue('store')
    setIsFirstCall(true)
    // props.fetchStoreGroupData({filters:p_payload,"rowCount":rowsCount,"rowIndex":initialindex});
    props.fetchStoreData({filters:p_payload,"rowCount":rowsCount,"rowIndex":initialindex,  "styleIndex": 0});
    // props.fetchMinPerStore(p_payload);
  };

  // const applyFilters = (p_payload) => {
  //   setFilters(p_payload);
  //   callAllApis(p_payload);
  // };

  const radioChanged = (event) => {
    setSelectedValue(event.target.value);
  };

  const setRTinstancecallbackStoreGroup = (instance) => {
    setRTinstanceStoreGroup(instance)
  }
  
  const setRTinstancecallbackStoreGrade = (instance) => {
    setRTinstanceStoreGrade(instance)
  }

  const setRTinstancecallbackStore = (instance) => {
    setRTinstanceStore(instance)
  }

  const resetTableData = () => {
    setStoreGroupData([])
    setStoreGradeData([])
    setStoreData([])
    setRTinstanceStoreGroup(null)
    setRTinstanceStoreGrade(null)
    setRTinstanceStore(null)
  }

  const resetStoreData = () => {
    props.resetStyleIndex()
    setStoreData([])
  }

  useEffect(()=>{
    if(props.error)
    Notify.error("Failed in applying your edits!!")
  },[props.error])

  return (
    <>
      {/* <Notification /> */}
      <div className="constraints__container">
        <div className="container__header">
          <h1 className="fnt-lg fnt-bold">Constraints</h1>
        </div>
        <div className="container__body">
          <div className="constarints__body__filter">
            <ConstraintsFilter resetTableData={resetTableData} applyFilters={applyFilters} setFilters={setFilters}/>
          </div>
          <div className="constraints__body__table">
            <div className="constraints__body__radio">
              <div className="constraints__body__radio__message">
               {/* {props.totalMinimum && <label className="fnt-md">Total Minimum: {props.totalMinimum}</label>} */}
              </div>
              <div>
                <div className="radio__group" onChange={radioChanged}>
                  <label className="fnt-md">View By : </label>
                  <div className="radio">
                    <input
                      id="storeGroup"
                      checked={selectedValue === "storeGroup"}
                      name="allocationByRadio"
                      value="storeGroup"
                      type="radio"
                    />
                    <label htmlFor="storeGroup">Store Eligibility Group</label>
                  </div>
                  <div className="radio">
                    <input
                      id="storeGrade"
                      checked={selectedValue === "storeGrade"}
                      name="allocationByRadio"
                      value="storeGrade"
                      type="radio"
                    />
                    <label htmlFor="storeGrade">Store Grade</label>
                  </div>
                  <div className="radio">
                    <input
                      id="store"
                      checked={selectedValue === "store"}
                      name="allocationByRadio"
                      value="store"
                      type="radio"
                    />
                    <label htmlFor="store">Store</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="constraints__body__table__data">
              {selectedValue == "storeGroup"  ? (
                <StoreGroup
                  resetIsFilterClicked= {() => setIsFilterClicked(false)}
                  storeGroupData={storeGroupData}
                  setOtherViewsEmpty={setOtherViewsEmpty}
                  filters={filters}
                  setRTinstancecallbackStoreGroup={setRTinstancecallbackStoreGroup}
                  // callAllApis={callAllApis}
                  fetchStoreGroupData = {props.fetchStoreGroupData}
                  // fetchMinPerStore = {props.fetchMinPerStore}
                  initialindex= {initialindex}
                  isFilterClicked={isFilterClicked}
                  rowsCount= {rowsCount}
                />
              ) : selectedValue === "storeGrade" ? (
                <StoreGrade
                  storeGradeData={storeGradeData}
                  setOtherViewsEmpty={setOtherViewsEmpty}
                  filters={filters}
                  // callAllApis={callAllApis}
                  fetchStoreGradeData = {props.fetchStoreGradeData}
                  setRTinstancecallbackStoreGrade={setRTinstancecallbackStoreGrade}
                  // fetchMinPerStore = {props.fetchMinPerStore}
                  initialindex= {initialindex}
                  rowsCount= {rowsCount}
                />
              ) : (
                <Store
                  resetIsFilterClicked= {() => setIsFilterClicked(false)}
                  isFilterClicked={isFilterClicked}
                  storeData={storeData}
                  setOtherViewsEmpty={setOtherViewsEmpty}
                  filters={filters}
                  // callAllApis={callAllApis}
                  fetchStoreData = {props.fetchStoreData}
                  setRTinstancecallbackStore={setRTinstancecallbackStore}
                  // fetchMinPerStore = {props.fetchMinPerStore}
                  initialindex= {initialindex}
                  rowsCount= {rowsCount}
                  resetStoreData= {resetStoreData}
                  resetStyleIndex={props.resetStyleIndex}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ constraints }) => ({
  storeGroupData: constraints.storeGroupData,
  storeGradeData: constraints.storeGradeData,
  storeData: constraints.storeData,
  callNext: constraints.callNext,
  // totalMinimum: constraints.totalMinimum,
  error: constraints?.error,
  styleIndex: constraints?.styleIndex,
  isUpdated: constraints.isUpdated,
});

const mapDispatchToProps = (dispatch) => ({
  resetAll: () => dispatch(resetAll()),
  resetStyleIndex: () => dispatch(resetStyleIndex()),
  fetchStoreGroupData: (payload) => dispatch(fetchStoreGroupData(payload)),
  fetchStoreGradeData: (payload) => dispatch(fetchStoreGradeData(payload)),
  fetchStoreData: (payload) => dispatch(fetchStoreData(payload)),
  // fetchMinPerStore: (payload) => dispatch(fetchMinPerStore(payload)),
  resetUpdateTableData: () => dispatch(resetUpdateTableData())
});

export default connect(mapStateToProps, mapDispatchToProps)(Constraints);
