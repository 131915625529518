import React from "react"
import PageLoader from "../../../../components/Loader/PageLoader";

import ViewAllocation from "../../../ViewAllocation/ViewAllocation"

const NewStoreAllocations = (props) => {

    const { loading, data, error, store, setDetailedView, isDetailedView } = props

    return (
        <div>
            {
                !isDetailedView &&
                <>
                    <h3 className="fnt-bold m-0 p-3">Allocations (store - {store}) </h3>
                    <hr className="m-0" />
                </>
            }
            <section className="sectionm-0">
                <PageLoader loader={loading} gridLoader={true}>
                    {(error) ? (<div className="error">{error}</div>) :
                        (data ? (
                            <ViewAllocation data={data} isNewStore showDetailedView={(p_bool) => setDetailedView(p_bool)} store={store} />
                        ) : null)}
                </PageLoader>
            </section>
        </div>
    )
}

export default NewStoreAllocations
