export const PO_ALLOCATION_TABLE_HEADERS = {
    ASN_ID: "ASN ID",
    PO_ID: "PO ID",
    DC: "DC ID",
    INVENTORY: "Inventory", 
    STYLE_COUNT: "#Style colors",
    STYLE: "Style Color ID",
    DESC: "Description",
    EXPECTED_DATE: "Expected Date",
    CREATION_DATE: "Creation Date",
    RECEIPT_DATE: "Receipt Date",
    TOTAL_QTY: "Total Qty",
    EACHES: "# Eaches",
    PACKS: "# Packs",
    TOTAL: "Total",
    QTY_VARIANCE: "Qty Variance",
    RECEIPT_QTY: "Receipt Qty",
    ALLOCATION_STATUS: "Allocation Status",
    VENDOR_ID: "Vendor ID",
    VENDOR_NAME: "Vendor Name",
    ALLOCATION_NAME: "Allocation Name",
    BULK_REMAINING: "Bulk Remaining",
    ALLOCATED_QTY: "Allocated Qty",
    STYLE_DESC: "Style Desc",
    STYLE_ID: "Style ID",
    COLOR_DESC: "Color Desc",
    COLOR_ID: "Color ID",
    STORE_GROUP: "Store Group",
    INTRODUCTION_DATE: "Introduction Date",
    PACK_NAME: "Pack ID",
    LW_UNITS : "LW Sales Units",
    WTD_UNITS: "WTD Sales Units",
    ASSORTMENT_INDICATOR: "Assortment Indicator",
    FACTORY_TYPE: "Factory Type",
    OH_OO: "OO+OH+IT",
    LAST_ALLOCATION_DATE: "Last Allocated",
}