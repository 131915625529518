import React, { useEffect, useState, createRef } from "react"
import ReactTable from "../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import ColumnFilter from "../../../UserManagement/ColumnFilter";
import PageLoader from "../../../../components/Loader/PageLoader";
import InputCell from "../../../../components/Table/Cellrenderer/InputCell";
import * as Notify from "../../../../components/Notification/Notifications";
import { numberUSFormatting } from "../../../../utils/formatters/valueFormatters";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const ArticleAllocationTable = (props) => {
    const { data, loading, error, article, getReviewTableData, updateArticleAllocation, 
        updateArticleAllocationError, updateArticleAllocationSuccess } = props

    const [tableData, setTableData] = useState([])
    const [tableInstance, setRTInstance] = useState([])
    const [inputRefs, setInputRefs] = useState([])


    const columns = [
        {
            Header: "Pack Name",
            accessor: "size"
        },
        {
            Header: "Inv Available",
            accessor: "inv_avai",
            Cell: (inst) => numberUSFormatting(inst?.value)
        },
        {
            Header: "Allocated",
            accessor: "allocated_total",
            Cell: (instance) => (
                <InputCell {...instance} style={{ textAlign: "center", width: "90%", height: "30px", margin: "auto" }} min={0} max={instance?.row?.original?.inv_avai} type="number"
                    ref={inputRefs[parseInt(instance?.row?.id)]}
                    changeHandler={(rowIdx, columnId, values) => {
                        handleInputChange(rowIdx, columnId, values)
                    }}
                />
            )
        }
    ]

    // useEffect(() => {
    //     let obj = {
    //         sizes: [],
    //         allocated: []
    //     }
    //     data.forEach(element => {
    //         obj.sizes.push(element.size)
    //         obj.allocated.push(element.allocated_total)
    //     });
    //     setTableData([obj])
    // }, [data])

    // const getSizes = () => {

    //     if (tableData.length) {
    //         tableData[0].sizes.forEach((s, index) => {
    //             columns.push({
    //                 Header: s,
    //                 accessor: row => row.allocated[index],
    //                 Cell: (instance) => (
    //                     <InputCell {...instance} style={{ textAlign: "center" }} min={0} max={100} type="number"
    //                     />
    //                 )
    //             })
    //         });
    //     }

    //     return true
    // }

    const handleInputChange = (rowIdx, columnId, values) => {
        setTimeout(() => {
            inputRefs[rowIdx].current.focus();
        }, 0)
    }

    useEffect(() => {
        setInputRefs(inputRefs => (
            Array(data.length).fill().map((_, i) => inputRefs[i] || createRef())
        ));
    }, [data.length])

    const updateAllocated = () => {
        if (tableInstance?.length) {
            updateArticleAllocation(tableInstance)
        }
    }

    useEffect(() => {
        if(updateArticleAllocationError) {
            Notify.error(updateArticleAllocationError)
        }
        else if(updateArticleAllocationSuccess) {
            getReviewTableData()
            Notify.success(updateArticleAllocationSuccess)
        }
    }, [updateArticleAllocationError, updateArticleAllocationSuccess])

    return (
        // getSizes() &&
        <section className="section mr-4 mt-4">

            <PageLoader loader={loading} gridLoader={true}>
                {/* <div className="flex-box-column"> */}
                <div className="storesStore__heading mb-4" style={{ width: "100%" }}>
                    <h1 className="fnt-md fnt-bold text-center" style={{ margin: "auto" }}>
                        Article : {article}
                    </h1>
                </div>
                {(error) ? (<div className="error">{error}</div>) :
                    (tableData ? (
                        <ReactTableWithPlugins
                            // margin={"0"}
                            data={(data ? data : [])}
                            columns={columns}
                            renderMarkup='TableMarkup'
                            getInstanceOnMount={(instance) => {
                                setRTInstance(instance?.data)
                            }}
                            tableId="newstore_article"
                            tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                            headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                        />
                    ) : null)}
                {/* </div> */}
                <div className="text-center mt-4 mb-4">

                    <button className="btn btn-primary px-2" onClick={updateAllocated}>
                        Save Changes
                    </button>
                </div>
            </PageLoader>

        </section>
    )
}

export default ArticleAllocationTable