import React, { useCallback, useEffect, useRef, useState } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { debounce, filter, forEach, isEmpty, map } from "lodash";
import moment from "moment";
import ReactPaginate from 'react-paginate';
import "./TableStyle.css";
import { EMPTY_DATA_HELPER_MSG, EMPTY_HIERARCHY_MSG, HIERARCHY_ID_DESC, HIERARCHY_ID_HEADER, ITEMS_PER_PAGE, NETWORK_ERROR_MSG } from './AdaVisualConstants';
import ExportFile from '../../components/ExportFile/ExportFile';
import * as adaVisualActions from './actions/AdaVisualActions';
import Expand from '../../components/svg/Expandsvg';
import Collapse from '../../components/svg/Collapsesvg';
import StoreView from './StoreView';
import { getAdditionalDiffOfUpdatedArticles, getEditedWeeklyHierarchyData, getEditedWeeklyStoreHierarchyData } from './AdaUtilities';
import PageLoader from '../../components/Loader/PageLoader';
import { sliceObject } from '../../utils/commonUtilities';
import DownloadProgressBar from '../../components/DownloadProgressBar/DownloadProgressBar';

/**
 * Hierarchy Table View
 * @param {article level data} props 
 * @returns 
 */
const rowCount = 100;
const HierarchyTable = (props) => {
    const {
      data,
      currentWeek,
      downloadExcelLowerData,
      adaVisualActions,
      isHierarchyModified,
      isSaveReq,
      adaStandAloneReqBody,
      startFiscalYearWeek,
      endFiscalYearWeek,
      getAdaStoreHierarchyData,
      isStoreHierarchyLoading,
      isStoreHierarchyFailed,
      storeHierarchyData,
      hierarchyData,
      hierarchyDataCopy,
      updatedStoreData,
      isReviewSuccess,
      updatedArticleData,
      downloadNextIndex,
      lowerHierarchyNextIndex,
      totalRecords,
      isSaveSuccess,
    } = props;

    const containerRef = useRef(null);

    // Calculates the total articles count.
    const getTotalCount = () => {
        const { articles, allArticles } = adaStandAloneReqBody;
        const articleList = articles?.length ? articles : allArticles
        return (articleList?.[0] == "*" ? articleList.length - 1 : articleList.length);
    }

    const [excelData, setExcelData] = useState({})
    const [filterValue, setFilter] = useState("");
    const [filteredData, setFilteredData] = useState({});
    const [downloadExcel, setDownloadExcel] = useState(false)
    const [selectedRow , setSelectedRow] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalCount, setTotalCount] = useState(getTotalCount());
    const [nextIndex, setNextIndex] = useState(0);
    const [nextIndexExcel, setNextIndexExcel] = useState(0);
    const [totalCountExcel, setTotalCountExcel] = useState(0);
    const [downloadExcelLoading, setDownloadLoading] = useState(false)
    const [nextLoweDataIndex, setNextLowerDataIndex] = useState(0);

    useEffect(() => {
        if (isSaveSuccess) {
            setSelectedRow(null)
        }
    }, [isSaveSuccess])
    
    const getHierarchyRequest = () => {
        const {
            current_fw,
            l1_name,
            l2_name,
            l3_name,
            l4_name,
            l5_name,
            l6_name,
            l7_name,
            assortment_indicator,
            store_code,
        } = adaStandAloneReqBody;

        const currentDateKey = moment().format("YYYY-MM-DD");
        const hierarchyReqBody = {
        start_date: startFiscalYearWeek ? startFiscalYearWeek : current_fw,
        end_date: endFiscalYearWeek ? endFiscalYearWeek : current_fw,
        current_date: currentDateKey,
        l1_name: l1_name,
        l2_name: l2_name,
        l3_name: l3_name && l3_name,
        l4_name: l4_name && [...l4_name],
        l5_name: l5_name && [...l5_name],
        l6_name: l6_name && [...l6_name],
        l7_name: l7_name && [...l7_name],
        article_forecast: null,
        assortment_indicator: assortment_indicator && [...assortment_indicator],
        store_code: store_code && [...store_code],
        };
        return hierarchyReqBody;
    }

    let articleMapping={}
    const tableHeader = data
      ? [...new Set(data.map((obj) => Number(obj.fw)))]
          .sort((a, b) => a - b)
          .map((week) => (week + "").padStart(2, "0"))
      : null;
    
    data.forEach((obj)=>{
     tableHeader.forEach((week)=>{
         if(articleMapping[obj.article])
         {
            articleMapping[obj.article][week]={fw: week, 'adjusted_forecast':"-"}
         }
         else{
          articleMapping[obj.article]= {[week]:{fw: week, 'adjusted_forecast':"-"}}
         }
         
    })})

    data.forEach((obj)=>{ 
        articleMapping[obj.article][obj.fw] = obj
    })
    const getArticle = (item) => {
      let article;
      if (item) {
        article = new Set(map(item, (e) => e.article));
        article = [...article];
      }
      return article?.[0] ? article?.[0] : article?.[1];
    };

    const getArticleName = (item) => {
        let articleName;
        if (item) {
          articleName = new Set(map(item, (e) => e.style_name));
          articleName = [...articleName];
        }
        return articleName?.[0] ? articleName?.[0] : articleName?.[1];
    };

    const handleClick = (item) => {
        let article = getArticle(item);
        const { current_fw } = adaStandAloneReqBody;

        let updatedHierarchyWeekData = getEditedWeeklyHierarchyData(hierarchyData, hierarchyDataCopy, current_fw);
        let additionalHierarchyWeekData = getAdditionalDiffOfUpdatedArticles(updatedArticleData[article]);
        // Both of the above objects have common data in some cases hence removing same data from 1 and making it common.
        forEach(updatedHierarchyWeekData[article], (item, index) => {
            if (additionalHierarchyWeekData[item.fy_fw]) {
                delete updatedHierarchyWeekData[article]?.[index]
            }
        })
        if (!isEmpty(updatedHierarchyWeekData)) {
            if (updatedHierarchyWeekData[article] && !isEmpty(additionalHierarchyWeekData)) {
                updatedHierarchyWeekData[article] = [...Object.values(additionalHierarchyWeekData)];
            }

        } else {
            updatedHierarchyWeekData = { [article] : [...Object.values(additionalHierarchyWeekData)]};
        }
        
        const updatedHierarchyStoreData = getEditedWeeklyStoreHierarchyData(updatedStoreData[article] ? updatedStoreData[article] : []);       
        let articleForecast = {}
        let articleValues = {};
        if (storeHierarchyData[article]) {
            if (selectedRow == article) {
                setSelectedRow(null);
                return;
            } else {
                setSelectedRow(article);
            }
            if (!isEmpty(updatedHierarchyWeekData[article])) {
                articleForecast = {[article] : updatedHierarchyWeekData[article]}
                // if the current article is already edited check for store level edits as well.
                if (updatedHierarchyWeekData[article] && updatedHierarchyStoreData[article]) {
                    forEach(updatedHierarchyStoreData[article], week => {
                        forEach(storeHierarchyData[article], stores => {
                            forEach(stores, storeData => {
                                // Collect all stores data for edited weeks.
                                if (storeData.fy_fw === week.fy_fw) {
                                    const {fy_fw, fy_fm, adjusted_forecast, store } = storeData
                                    articleValues[`${fy_fw}-${store}`] = {
                                        "fy_fm": fy_fm,
                                        "fy_fw": fy_fw,
                                        "input": adjusted_forecast,
                                        "store": store,
                                    }
                                }
                            })
                        })    
                    })
                    // Combine the existing article level edits with all the store data for edited weeks.
                    articleForecast = { [article] : [...articleForecast[article], ...Object.values(articleValues)]}
                }
            } else return; // If only store level edit is there do not call the API for stores.
        } else if (!isEmpty(updatedHierarchyWeekData[article])) { // If it is only article update just collect that only.
            articleForecast = { [article] : updatedHierarchyWeekData[article]}
        }

      getAdaStoreHierarchyData({...getHierarchyRequest(),  article_forecast: articleForecast, articles: [article]});
      if (selectedRow == article) {
        setSelectedRow(null);
      } else {
        setSelectedRow(article);
      }
    };

    const isExpanded = (article) => {
      return article == selectedRow;
    };

    useEffect(() => {
        if(data.length!==0){
        setDownloadExcel(true)
            setExcelData([])
        }
    },[data])

    useEffect(() => {
        if (filterValue == "") {
            setFilteredData({})
            setCurrentPage(0);
        } else {
            let trimmedFilterValue = filterValue.replace(/\s*,\s*/g, ",").trim();
            let bulkArticles = trimmedFilterValue.split(",");
            const {
                current_fw,
                l1_name,
                l2_name,
                l3_name,
                l4_name,
                l5_name,
                l6_name,
                l7_name,
                articles,
                allArticles,
                assortment_indicator,
                store_code,
              } = adaStandAloneReqBody;
              const currentDateKey = moment().format("YYYY-MM-DD");
              const hierarchyReqBody = {
                start_date: startFiscalYearWeek
                  ? startFiscalYearWeek
                  : current_fw,
                end_date: endFiscalYearWeek ? endFiscalYearWeek : current_fw,
                current_date: currentDateKey,
                l1_name: l1_name,
                l2_name: l2_name,
                l3_name: l3_name && l3_name,
                l4_name: l4_name && [...l4_name],
                l5_name: l5_name && [...l5_name],
                l6_name: l6_name && [...l6_name],
                l7_name: l7_name && [...l7_name],
                assortment_indicator: assortment_indicator && [
                  ...assortment_indicator,
                ],
                store_code: store_code && [...store_code],
              };
              const searchedData = filter(articleMapping, (e, articleId) => {
                let isConditionTrue = bulkArticles.indexOf(articleId) > -1;
                // Remove the article from the search if it is already present on UI
                if (bulkArticles.indexOf(articleId) > -1) {
                    bulkArticles.splice(bulkArticles.indexOf(articleId), 1);
                }
                  return isConditionTrue;
                })
                const articlesReq = articles?.length ? articles : allArticles
                const articlesList = articlesReq?.filter((item) => item !== "*");
                
                setFilteredData(searchedData.length > 0 ? searchedData : {})
                  adaVisualActions.getAdaHierarchyData({
                    request: hierarchyReqBody,
                    rowIndex: lowerHierarchyNextIndex,
                    rowCount,
                    total: totalRecords,
                    articles: articlesList,
                    isSearch: true,
                    searchedArticles: bulkArticles,
                  });
        }
    },[filterValue])


    useEffect(() => {
        let trimmedFilterValue = filterValue.replace(/\s*,\s*/g, ",").trim();
        const bulkArticles = trimmedFilterValue.split(",");
        const searchedData = filter(articleMapping, (e, articleId) => {
            return bulkArticles.indexOf(articleId) > -1
        })
        setFilteredData(searchedData.length > 0 ? searchedData : {})
    },[data]) // if the data is updated re-calculate the filtered data.

    // After a successful review close the accordion of stores.
    useEffect(() => {
        if (isReviewSuccess)
            setSelectedRow(null)
    }, [isReviewSuccess])

    useEffect(() => {
        setNextIndex(props.nextIndex ? props.nextIndex : 0 )
    }, [props.nextIndex])

    useEffect(() => {
        if (Object.values(props.excelData)?.length > 0) {
            if (!isEmpty(excelData)) {
                let newData = props.excelData;
                // Update the existing store level excel data with new data.
                setExcelData(prev => Object.assign(prev, newData ));
            }
            else {
                setExcelData(props.excelData);
            }
        }
        else {
            setExcelData({})
        }
        setTotalCountExcel(props.totalCountExcel)
        setNextIndexExcel(props.nextIndexExcel)
    }, [props.excelData,props.totalCountExcel, props.nextIndexExcel])

    useEffect(() => {
        // If there are more articles then continue to fetch store data 
        if (downloadExcelLoading && props.downloadNextIndex < props.totalCountExcel) {
            let articleValues = adaStandAloneReqBody.articles?.length ? adaStandAloneReqBody.articles : adaStandAloneReqBody.allArticles
            let articlesList = articleValues.filter((item) => item !== "*");
            adaVisualActions.generateExcel({request: getHierarchyRequest(), total: totalCount, articles: articlesList, rowIndex: downloadNextIndex, rowCount: 1 })
        }
        else if (props.downloadNextIndex >= props.totalCountExcel) {
            // Once all the store data is fetched format the data for excel download. 
           formatExcelData(Object.assign(excelData, props.excelData))
        }
    }, [props.downloadNextIndex])

    useEffect(() => {
        setNextLowerDataIndex(props.lowerHierarchyNextIndex ? props.lowerHierarchyNextIndex : 0 )
    }, [props.lowerHierarchyNextIndex])

    const formatExcelData = (storeData) => {
        let excelData =[]
        data.forEach(item=>{
            // Only iterate when valid storeData is present.
            if (storeData[item?.article]) {
                Object.values(storeData[item?.article]).forEach((stores) => {
                    stores.forEach(week => {
                        if (week.fw == item.fw) {
                            excelData.push({
                                "Style Color Id":item?.article,
                                "Style Color Description": item?.style_name,
                                "Week": `W${item.fw}`,     
                                "Article Adjusted Forecast": item.adjusted_forecast.toFixed(2),
                                "Store": week?.store,
                                "Store Name": week?.store_name,
                                "Store Adjusted Forecast": week.adjusted_forecast.toFixed(2),
                            }) 
                            return 
                        }
                    })
                })
            }
        })
        setExcelData(excelData)
        setTimeout(() => {
            setDownloadLoading(false)
            setDownloadExcel(true)
        }, 1000)
        
        setTimeout(() => {
            setExcelData([])
            adaVisualActions.resetDownloadExcel()
        }, 2000)
    }   

    const generateExcel = () => {
        setExcelData([])
        setDownloadExcel(false)
        setDownloadLoading(true)

        // fetch all the articles selected in the filter.
        let articleValues = adaStandAloneReqBody.articles?.length ? adaStandAloneReqBody.articles : adaStandAloneReqBody.allArticles
        let articlesList = articleValues.filter((item) => item !== "*");
        adaVisualActions.generateExcel({request: getHierarchyRequest(), total: totalCount, articles: articlesList, rowIndex: nextIndex, rowCount: 1 })
      }

      const closeDownloadModal = () => {
        setDownloadLoading(false)
        adaVisualActions.resetDownloadExcel()
    }

    const debouncedHandleChange = useCallback(debounce((data) => {
        setFilter(data)
    },2000),[])
      
    const handleChange = (e) => {
        debouncedHandleChange(e.target.value)
    }
    
    const debouncedHandleHierarchyChange = useCallback(debounce((data, article, store) => {
        const inputValue = data.value == "" ? "0.00" : data.value;

        let dates = data.getAttribute('name').split('-');
        // let article = data.parentElement.getAttribute('id').split("-")
        // let store = "";
        // if (article.length > 1) {
        //     store = article[1];
        // }
        // article = article[0];
        let req = {
            art: article,
            date: Number(dates[1]),
            input: Number(inputValue.replaceAll(',', '')),
            store: store || "",
        }
        adaVisualActions.changeHierarchy(req);
    }, 200), []);

    const handleHierarchyChange = (e, article, store) => {
        if (e) {
            debouncedHandleHierarchyChange(e.originalEvent.target, article, store);
        }
    }

    // calculate the total number of pages
    const totalPages = Math.ceil(
        Object.values(!isEmpty(filteredData) ? filteredData : articleMapping).length /
        ITEMS_PER_PAGE
    );

    // define a function to handle page change events
    const handlePageChange = (selectedPage) => {
        const { lowerHierarchyNextIndex, updatedArticleData } = props;
        const isLastPage = (selectedPage.selected + 1) == Math.ceil(totalRecords / 20);
        // Check if it is last page and more data is pending to be fetched.
        if (isLastPage && lowerHierarchyNextIndex < totalCount ) {
            const {
              current_fw,
              l1_name,
              l2_name,
              l3_name,
              l4_name,
              l5_name,
              l6_name,
              l7_name,
              articles,
              allArticles,
              assortment_indicator,
              store_code,
            } = adaStandAloneReqBody;
            const currentDateKey = moment().format("YYYY-MM-DD");
            const hierarchyReqBody = {
              start_date: startFiscalYearWeek
                ? startFiscalYearWeek
                : current_fw,
              end_date: endFiscalYearWeek ? endFiscalYearWeek : current_fw,
              current_date: currentDateKey,
              l1_name: l1_name,
              l2_name: l2_name,
              l3_name: l3_name && l3_name,
              l4_name: l4_name && [...l4_name],
              l5_name: l5_name && [...l5_name],
              l6_name: l6_name && [...l6_name],
              l7_name: l7_name && [...l7_name],
              assortment_indicator: assortment_indicator && [
                ...assortment_indicator,
              ],
              store_code: store_code && [...store_code],
            };
            const articlesReq = articles?.length ? articles : allArticles
            let articlesList = articlesReq?.filter((item) => item !== "*");
            
            // Remove any article that may have already searched and updated before the next set of data is fetched.
            articlesList = articlesList.filter(article => Object.keys(updatedArticleData).indexOf(article) === -1 )
            
            adaVisualActions.getAdaHierarchyData({
              request: hierarchyReqBody,
              rowIndex: nextLoweDataIndex,
              rowCount,
              total: totalRecords,
              articles: articlesList,
            });
        }
        setCurrentPage(selectedPage.selected);
    };

    // slice the list of items based on the current page and number of items per page
    const itemsToShow = sliceObject(
        !isEmpty(filteredData) ? filteredData : articleMapping,
        !isEmpty(filteredData) ? 0 : currentPage * ITEMS_PER_PAGE,
        !isEmpty(filteredData) ? ITEMS_PER_PAGE : (currentPage + 1) * ITEMS_PER_PAGE
    );

    const handleScroll = debounce(() => {
        const container = containerRef.current;
        const subTable = container.querySelector('.empty');
        if (subTable) {
            subTable.style.left = `${container.scrollLeft}px`;
            subTable.style.position = `relative`;
        }
      }, 100);
      
      const sortData = (data, sortOrder) => {
        // Function to check if an object has the "fw" key
        const hasFwKey = (obj) => obj.hasOwnProperty("fw");

        // Separate objects with "fw" key from the ones without it
        const objectsWithFw = data?.filter(hasFwKey);
        const objectsWithoutFw = data?.filter(obj => !hasFwKey(obj));

        // Sort objects with "fw" key based on the sorting order list
        objectsWithFw.sort((a, b) => sortOrder.indexOf(a.fw) - sortOrder.indexOf(b.fw));

        // Combine the sorted objects with objects without "fw" key
        return objectsWithFw.concat(objectsWithoutFw);
      };

    return (
       <>
       {
           (!isHierarchyModified || isSaveReq) &&
                <div style={{float:"right",marginTop:"0px"}}>
                     <ExportFile text={"Excel"}  downloadExcelData={downloadExcel} data={excelData} sheetName={"Lower Heirarchy Forecast"} callFunc={generateExcel} fileName={'lowerheirarchyforecast'} type={3} /> 
                </div>
            
       }
       <DownloadProgressBar
          nextIndex={nextIndexExcel}
          totalCount={totalCountExcel}
          showModal={downloadExcelLoading}
          closeModal={closeDownloadModal}
        />
        {data && data.length ?
            <div style={{ padding: "2px" }}>
                <table  ref={containerRef} onScroll={handleScroll} className="hierarchy-table table-set" style={{marginTop:"38px",maxWidth:"100%"}}>
                    <thead className="sticky-header">
                        <tr>
                            <th className="th-set sticky-col" key={0} ></th>
                            <th className="th-set sticky-col2" key={1}>{HIERARCHY_ID_HEADER} 
                            <input
                                className="table-input-field"
                                // value={filterValue}
                                onChange={(e) => handleChange(e)}
                                placeholder={"Search in bulk by ','"}
                            />
                            </th>
                            <th className="th-set sticky-col3" key={3} >{HIERARCHY_ID_DESC}</th>
                            {tableHeader.map((week, index) => (
                                <th className="th-set" scope="col" key={index + 3}>{`W${week}`}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        { filterValue && isEmpty(filteredData) ?
                         <div className="empty">{EMPTY_HIERARCHY_MSG}
                            <p>{EMPTY_DATA_HELPER_MSG}</p>
                        </div> :
                        Object.keys(itemsToShow).map((k, rowIndex) => 
                        (
                            <>
                            <tr className="tr-set" key={getArticle(!isEmpty(filteredData) ? filteredData[k] : itemsToShow[k])}>
                                <td className="td-set sticky-col" key={0} onClick={() => handleClick(!isEmpty(filteredData) ? filteredData[k] : itemsToShow[k])}>{ !isExpanded(getArticle(!isEmpty(filteredData) ? filteredData[k] : itemsToShow[k])) ? <Expand /> : <Collapse />}</td>
                                <td className="td-set sticky-col2" key={1}>{getArticle(!isEmpty(filteredData) ? filteredData[k] : itemsToShow[k])}</td>
                                <td className="td-set sticky-col3" key={2}>{getArticleName(!isEmpty(filteredData) ? filteredData[k] : itemsToShow[k])}</td> 
                                {sortData(Object.values(itemsToShow[k]), tableHeader).map((item, i) => 
                                (
                                    item.fy_fw < currentWeek || item.adjusted_forecast=="-" ?
                                        <td className="td-set data_disabled" key={i + 3}>
                                            <InputNumber
                                                value={item?.adjusted_forecast!== "-" ? item['adjusted_forecast'].toFixed(2) : item['adjusted_forecast']}
                                                key={i + 1}
                                                name={`${item.fy_fm}-${item.fy_fw}`}
                                                id={item.article}
                                                className="span-data"
                                                disabled
                                            />
                                        </td>
                                        :
                                        <td className="td-set data_input" key={i + 3}>
                                            <InputNumber
                                                min={0}
                                                mode="decimal"
                                                minFractionDigits={2} 
                                                maxFractionDigits={2} 
                                                value={item?.adjusted_forecast!== "-" ? item['adjusted_forecast'].toFixed(2) : item['adjusted_forecast']}
                                                key={i + 1}
                                                name={`${item.fy_fm}-${item.fy_fw}`}
                                                id={item.article}
                                                onChange={(e) => handleHierarchyChange(e, item.article, item.store)}
                                                className="edit-span"
                                                disabled={isExpanded(getArticle(itemsToShow[k]))}
                                            />
                                        </td>
                                ))}
                            </tr>
                            {isExpanded(getArticle(itemsToShow[k])) && (
                                isStoreHierarchyFailed
                                ? <div className="error">{NETWORK_ERROR_MSG}</div>
                                : 
                                <PageLoader loader={isStoreHierarchyLoading} style={{ display: !isStoreHierarchyLoading && "contents", padding: "50px 10px" ,margin: "10px", left: isStoreHierarchyLoading && `${containerRef.current.scrollLeft}px`}} gridLoader={true}>
                                    {!isEmpty(Object.values(storeHierarchyData)) ? 
                                        <StoreView 
                                            article={getArticle(itemsToShow[k])}
                                            tableHeader={tableHeader}
                                            isStoreHierarchyLoading={isStoreHierarchyLoading} 
                                            currentWeek={currentWeek}
                                            handleHierarchyChange={handleHierarchyChange}
                                            sortData={sortData}
                                        />
                                        : <div className={isStoreHierarchyLoading ? 'hidden': "empty"}>{EMPTY_HIERARCHY_MSG}
                                            <p>{EMPTY_DATA_HELPER_MSG}</p>
                                          </div>}
                                </PageLoader>
                               
                            )}
                            </>
                        ))}
                    </tbody>
                </table>
                {totalPages > 1 && (
                    <ReactPaginate
                        pageCount={totalPages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                    />
                )}
            </div>
            : null}
        </>    
    );
};

const mapStateToProps = ({ adaVisual }) => ({
    downloadExcelLowerData: adaVisual.downloadExcelLowerData,
    isHierarchyModified: adaVisual.isHierarchyModified,
    isSaveReq:adaVisual.isSaveReq,
    startFiscalYearWeek: adaVisual.startFiscalYearWeek,
    endFiscalYearWeek: adaVisual.endFiscalYearWeek,
    adaStandAloneReqBody: adaVisual.adaStandAloneReqBody,
    isStoreHierarchyLoading: adaVisual.isStoreHierarchyLoading,
    isStoreHierarchyFailed: adaVisual.isStoreHierarchyFailed,
    storeHierarchyData: adaVisual.storeHierarchyData,
    hierarchyData: adaVisual.hierarchyData,
    hierarchyDataCopy: adaVisual.hierarchyDataCopy,
    updatedStoreData: adaVisual.updatedStoreData,
    isReviewSuccess: adaVisual.isReviewSuccess,
    updatedArticleData: adaVisual.updatedArticleData,
    excelData: adaVisual.excelData,
    loading: adaVisual.loading,
    excelError: adaVisual.excelError,
    excelSuccess: adaVisual.excelSuccess,
    totalCountExcel: adaVisual.totalCountExcel,
    nextIndexExcel: adaVisual.nextIndexExcel,
    downloadNextIndex: adaVisual.downloadNextIndex,
    nextIndex: adaVisual.nextIndex,
    lowerHierarchyNextIndex: adaVisual.lowerHierarchyNextIndex,
    totalRecords: adaVisual.totalRecords,
    isSaveSuccess: adaVisual.isSaveSuccess,
});

const mapDispatchToProps = (dispatch) => ({
    adaVisualActions: bindActionCreators(adaVisualActions, dispatch),
    getAdaStoreHierarchyData: (payload) => dispatch(adaVisualActions.getAdaStoreHierarchyData(payload))
});

export default connect(mapStateToProps,mapDispatchToProps)(HierarchyTable);