import React, { useState, useEffect } from 'react';
import ReactTable from '../../../../components/Table/ReactTable';
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import ColumnFilter from '../../../UserManagement/ColumnFilter';
import NumberRangeColumnFilter from "../../../../components/Filters/NumberRangeColumnFilter";
import InputCell from "../../../../components/Table/Cellrenderer/InputCell";
import { isEmpty } from 'lodash';
import * as StoreDetailsConsts from './StoreDetailsDcConfigConstants';
import "./Storedetailsconfig.css";

/**
 * Table Plugin
 */
const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

/**
 * DC source Data Section View Component
 * @param {*} props 
 * @returns 
 */
const DCSourcingView = (props) => {
    const { storeConfigDetailsActionsProp, dcSourceData, dcSourceRowsSelected, selectedSisterStoreCode, setDcSrcRowsSelection } = props;
    const [dcSrcTableInst, setDcSrcTableInst] = useState(null);

    const onDCSourcingInventoryMount = (inst) => {
        setDcSrcTableInst(inst);
        setDcSrcRowsSelection(inst.getSelectedRows);
    }

    function setRowsSelected(rows) {
        if (!isEmpty(rows)) {
            let checkedRows = {};
            rows.forEach((item) => {
                checkedRows[item.id] = true;
            });
            storeConfigDetailsActionsProp.setDcSourceRowsSelected(checkedRows);
        }
    }

    useEffect(() => {
        return () => {
            const selectedRows = dcSrcTableInst?.selectedFlatRows.map(row => row.original);
            if (!isEmpty(selectedRows)) {
                storeConfigDetailsActionsProp.setSelectedDcSourceRowsDetail(selectedRows);
                setRowsSelected(dcSrcTableInst?.selectedFlatRows);
            } else {
                //No code here
            }
        };
    });

    const dcSourceTableColumns = React.useMemo(
        () => [
            {
                Header: StoreDetailsConsts.DC_SOURCING_DC_LABEL,
                accessor: "dc",
                width: 120,
                Filter: ColumnFilter,
            },
            {
                Header: StoreDetailsConsts.DC_SOURCING_DC_NAME_LABEL,
                accessor: "dc_name",
                width: 150,
                Filter: ColumnFilter,
            },
            {
                Header: StoreDetailsConsts.DC_SOURCING_DC_STATE_LABEL,
                accessor: "state",
                width: 150,
                Filter: ColumnFilter,
            },
            {
                Header: StoreDetailsConsts.DC_SOURCING_DC_COUNTRY_LABEL,
                accessor: "country",
                width: 150,
                Filter: ColumnFilter,
            },
            {
                Header: StoreDetailsConsts.DC_SOURCING_DC_LEADTIME_LABEL,
                accessor: "transit_time",
                width: 150,
                Cell: (instance) => <InputCell {...instance} style={{ textAlign: "center", border: "none" }} min={0} type="number"
                    debouncedChangeHandler={(rowIndex, columnId, value) => {
                        storeConfigDetailsActionsProp.editDcLeadTime({
                            store_code: selectedSisterStoreCode?.value,
                            dc_code: instance?.row?.original.dc,
                            lead_time: value,
                        });
                    }}
                />,
                Filter: NumberRangeColumnFilter,
                filter: 'between',
            },
            {
                Header: StoreDetailsConsts.DC_SOURCING_DC_DISTANCE_LABEL,
                accessor: "distance",
                width: 150,
                Filter: NumberRangeColumnFilter,
                filter: 'between',
            }
        ],
        [dcSrcTableInst]
    );

    return (
        <>
            <h2 className="font-weight-bold mx-auto p-3 text-left">
                {StoreDetailsConsts.DC_SOURCING_SECTION_LABEL}
            </h2>
            <hr />
            <ReactTableWithPlugins
                getInstanceOnMount={(inst) => onDCSourcingInventoryMount(inst)}
                shouldPagination
                pageSize={8}
                totalRecordsLen={dcSourceData.length}
                data={dcSourceData}
                columns={dcSourceTableColumns}
                initialSelectedRows={dcSourceRowsSelected}
                renderMarkup='TableMarkup'
                keyRT="StrategyPageTable"
                features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                tableId="config_dc"
                tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
            />
        </>
    );
}

export default DCSourcingView;