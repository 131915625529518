import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Filter from '../../containers/ProductProfile/ProductProfileTable/Filter';

const SetAllModal = (props) => {

  const [modal, setModal] = useState(true);

  const toggle = () => {
    setModal(!modal);
  }

  const handleCancel = () => {
    if(props.handleCancel)
    props.handleCancel()
    setModal(!modal)
  }

  const handleOk = () => {
    if(props.handleOk)
    props.handleOk()
    setModal(!modal)
  }

  const handleClose = () => {
      if(props.handleClose)
      props.handleClose()
  }

  return (
    <div>
      <Modal onClosed={handleClose} contentClassName="modalContent" style={props.customModalStyle} scrollable={true} isOpen={modal} modalTransition={{ timeout: 600 }} backdropTransition={{ timeout: 700 }}
        toggle={toggle}>
        <ModalHeader toggle={toggle} className="modalHeader">{props.heading || 'Set All'}</ModalHeader>
        <ModalBody style={props.modalBodyStyle}>
        <div className="filter__wrapper">
            {props.filter && <Filter data={props.filter} />}
            {props.showMessage &&  props.message && <div style={props.messageStyle}>{props.message}</div>}
        </div>
        </ModalBody>
        <ModalFooter className="modalFooter">
          <Button color="primary" disabled={props?.disableApply} onClick={handleOk}>{props.buttonLabel || "Apply"}</Button>{' '}
          <Button color="secondary" onClick={handleCancel}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default SetAllModal;