import React, { useEffect, useState } from "react";
import "./ColumnSelection.css"
import Filter from "../../containers/ProductProfile/ProductProfileTable/Filter";
import { handleSelectAll } from '../../utils/SelectAllHandler/SelectAllHander'
import Api from "../../configs/api_config"
import { SAVE_USER_PREFERENCE_COLUMNS } from "../../constants/apiConstants";
import { getUserHiddenColumns, getDefaultHiddenColumns } from "../../utils/commonUtilities";
import * as Notify from "../Notification/Notifications";
import SaveOptionModal from "./SaveOptionModal";

const ColumnSelection = (props) => {
    const { columnConfig, setManualHiddenColumns, defaultHiddenColumns, tableId, hideColumns } = props
    const [columnOptions, setColumnOptions] = useState([])
    const [selectedColumns, setSelectedColumns] = useState([])
    const [showSaveLoader, setLoading] = useState(false)
    const [showSaveModal, setShowSaveModal] = useState(false)

    useEffect(() => {
        if(!columnOptions?.length) {
            createColumnOptions(columnConfig)
        }
    }, [columnConfig])

    let options = []

    const createColumnOptions = (config) => {
        let defaultColumns = defaultHiddenColumns || []
        let selectedOptions = []
        let hiddenColumns = []
        config?.forEach(column => {
            let label = column?.parent?.Header && column?.parent?.Header !== " " ? `${column?.parent?.Header} - ${column.Header}` : typeof column.Header === "string" ? column.Header : "" 
            // if (item?.columns) {
            //     let preHeader = item.Header && item.Header !== " " ? `${item.Header} - ` : ""
            //     item.columns.forEach(col => {
            //         if (col.Header && !(["Action", "Actions"].includes(col.Header)) &&
            //             !defaultColumns.includes(col.accessor)) {
            //             options.push({ label: (preHeader + col.Header).trim(), value: col.id && col.id.includes("dynamic") ? col.id?.split("dynamic")[1] : col.accessor })
            //         }
            //     })
            // }
            // else {
            if (column.Header && !(["Action", "Actions"].includes(column.Header)) &&
                !defaultColumns.includes(column.id) && label ) {
                // options.push({ label: column.Header, value: column.id && column.id.includes("dynamic") ? column.id?.split("dynamic")[1] : column.accessor })
                options.push({ label, value: column.id && column.id.includes("dynamic") ? column.id?.split("dynamic")[1] : column.id })
            }
            // }
            // createColumnOptions(item.columns)
        })
        // else {
        //     if (item.Header && !(["Action", "Actions"].includes(item.Header)) &&
        //         !defaultColumns.includes(item.accessor)) {
        //         options.push({ label: (preHeader + item.Header).trim(), value: item.id && item.id.includes("dynamic") ? item.id?.split("dynamic")[1] : item.accessor })
        //     }
        // }
        // })
        options.forEach(col => {
            let flag = true
            getUserHiddenColumns(null, tableId).forEach(item => {
                if (col.value === item.value) {
                    // selectedOptions.push(item)
                    flag = false
                }
            })
            if (flag) {
                selectedOptions.push(col)
                // hiddenColumns.push(col)
            }
        })
        let hideColumnValues = getDefaultHiddenColumns().concat(hideColumns)
        options = options.filter(col => !hideColumnValues.includes(col.value))
        selectedOptions = selectedOptions.filter(col => !hideColumnValues.includes(col.value))
        setColumnOptions(options)
        setSelectedColumns(selectedOptions)
        // localStorage.setItem("hiddenColumns", JSON.stringify(hiddenColumns))
    }

    const onChangeHandler = (val, event, that) => {
        let valuesArray = handleSelectAll(val, event, that)
        setSelectedColumns(valuesArray[1])
    }

    const filterObj = [{
        id: 1,
        isRequired: false,
        label: "Show Columns",
        name: "Columns",
        dropdownValue: selectedColumns,
        onDropDownChange: onChangeHandler,
        placeHolder: `Select columns`,
        optionsArray: columnOptions,
        isMultiSelect: true,
        className: "fill col-md-3 p-0",
        labelClass: "show_columns__label"
    }]

    const savePreference = async (option) => {
        const user_id = localStorage.getItem("user_id")
        let column_preferences = JSON.parse(localStorage.getItem("column_preferences")) || {}
        setLoading(true)
        let selectedColumnValues = selectedColumns.map(col => col.value)
        let allOptions = columnOptions.map(col => col.value)
        let hiddenColumns = option === 2 ? (column_preferences?.["hiddenColumns"]?.[tableId] || []) : (column_preferences?.["hiddenColumns"]?.["global"] || [])
        let newHiddenColumns = columnOptions.concat(hiddenColumns).filter(column => !selectedColumnValues.includes(column.value))
        if (option === 1) {
            let hideValues = newHiddenColumns.map(col => col.value)
            if (column_preferences?.hiddenColumns?.global) {
                let global = [...column_preferences?.hiddenColumns?.global].concat([...new Set(newHiddenColumns.map(JSON.stringify))].map(JSON.parse))
                global.forEach((col, index) => {
                    if (hideValues.includes(col.value)) {
                        global.splice(index, 1)
                    }
                })
            }
            else {
                global = [...new Set(newHiddenColumns.map(JSON.stringify))].map(JSON.parse)
            }
            column_preferences = { ...column_preferences, hiddenColumns: { global: [...new Set(newHiddenColumns.map(JSON.stringify))].map(JSON.parse) } }
        }
        else {
            column_preferences = { ...column_preferences, hiddenColumns: { ...column_preferences.hiddenColumns, [tableId]: [...new Set(newHiddenColumns.map(JSON.stringify))].map(JSON.parse) } }
        }
        localStorage.setItem("column_preferences", JSON.stringify(column_preferences))
        let response = await Api.post(SAVE_USER_PREFERENCE_COLUMNS, { user_id, column_preferences: [column_preferences] })
        if (response?.data?.status) {
            setShowSaveModal(false)
            setLoading(false)
            setTimeout(() => {
                Notify.success("User Preference columns saved successfully!");
            }, 200)
        }
        // localStorage.setItem("column_preferences", JSON.stringify(selectedColumns))


        // setManualHiddenColumns(allOptions.filter(column => !selectedColumnValues.includes(column)))
        setManualHiddenColumns(newHiddenColumns.map(col => col.value))
    }

    const showSaveOptionModal = () => {
        setShowSaveModal(true)
    }

    return (
        <div className="col_selection">
            <div className="d-flex align-items-end">
                <Filter data={filterObj} />
                <div className="text-center">
                    <button className="btn btn-primary px-2 ml-4" onClick={showSaveOptionModal}>
                        {/* {
                            showSaveLoader ?
                                <i class="fa fa-spinner fa-pulse fa-2x"></i>
                                : <span> */}
                        Save Preference
                        {/* </span>
                        } */}

                    </button>
                </div>
            </div>
            {
                showSaveModal &&
                <SaveOptionModal showModal={showSaveModal} loading={showSaveLoader} closeModal={() => { setShowSaveModal(false) }} savePreference={savePreference} />
            }
            <div className="font-italic py-2"> Selected columns are shown in the table</div>
        </div>
    );
}

export default ColumnSelection