import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import {
    addDollar,
    addPer,
    numberUSFormatting,
    twoDecimal,
} from "../../../utils/formatters/valueFormatters";
import LinkCell from "../../../components/Table/Cellrenderer/LinkCell";

import {
    getStyleInvPopupData,
    getReviewRecommendData,
    resetPageIndex,
} from "../DashboardActions";
import { getStrategyTableDataFromDashboard } from "../../Strategy/actions";

import { DashboardModal } from "../DashboardModal";
import { STRATEGY_PAGE, NEW_STORE_RESERVED_ALLOCATIONS } from "../../../constants/routeConstants";
import EditIconCell from '../../../components/Table/Cellrenderer/EditIconCell';
import ReviewRecommendation from './ReviewRecommendation';
import ColumnFilter from '../../UserManagement/ColumnFilter';
import '../Dashboard.css'
import { updateArticleName } from "../../../utils/filterLevelMapping"
import { getLevelFiltersForReq, prepareRequestObject } from "../../../utils/commonUtilities"

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);


const RecommendedAction = (props) => {
    const history = useHistory()

    const [RTinstance, setRTinstance] = useState(null)
    const [reviewRecommendation, setreviewRecommendation] = useState(false)
    const [filters, setFilters] = useState({})
    const [styleColorCount, setStyleCount] = useState(0)

    const RecommendedActionCols = [
        {
            Header: "Action Item",
            accessor: "stock_status",
            Cell: row => <div className = "column__left-align">{row.value}</div>,
            width: "280",
            // Filter: ColumnFilter
        },
        {
            Header: "Description",
            accessor: "description",
            width: '350',
            Cell: row => <div className = "column__left-align text-wrap">{row.value}</div>,
            // Filter: ColumnFilter
        },
        {
            Header: `${updateArticleName(false)} #`,
            accessor: "style_color_cnt",
            // Filter: ColumnFilter
        },
        {
            Header: "Bulk Remaining",
            accessor: "bulk_remaining",
            Cell: (inst) => numberUSFormatting(inst?.value),
            // Filter: ColumnFilter
        },
        {
            Header: "Allocated Units",
            accessor: "po_asn_receipt_quantity",
            id:'allocated_units',
            Cell: (inst) => numberUSFormatting(inst?.value),
            // Filter: ColumnFilter
        },
        {
            Header: "LW Sales Units",
            accessor: "lw_qty",
            Cell: (instance) => (
                <div>
                    {instance?.row?.original.lw_qty !== null ? numberUSFormatting(instance?.row?.original.lw_qty): "-"}   
                </div>
            ),
          },
        
        // {
        //     Header: "PO/ASN #",
        //     accessor: "po_asn_count",
        //     // Filter: ColumnFilter
        // },
        // {
        //     Header: "PO/ASN/Receipts Quantity",
        //     accessor: "po_asn_receipt_quantity",
        //     // Filter: ColumnFilter
        // },
        {
            Header: "Action",
            accessor: "action",
            sticky: "right",
            Cell: (instance) => (
                <div>
                    <button
                        className="btn btn-outline-secondary btn-sm px-2 rounded-lg text-body"
                        onClick={() => onReviewRecommendation(props.filters, instance.row.values.stock_status, instance.row.original)}
                    >
                        Review Recommendation
                        <i
                            className="fa fa-angle-right ml-2"
                            title="Configure"
                            aria-hidden="true"
                        ></i>
                    </button>
                </div>
            ),
            width: 250,
            // disableFilters: true
        },
    ]

    const onReviewRecommendation = (filters, storesFilter, row) => {
        setStyleCount(row?.style_color_cnt)
        props.resetPageIndex()
        if (["new_store_approval", "new_store_release"].includes(row?.recommendation_id)) {
            history.push(`${NEW_STORE_RESERVED_ALLOCATIONS}`, { type: row?.recommendation_id })
        }
        else if (row.pos) {
            let req = {
                l1_name: filters?.l1_name?.map(val => val.value),
                l2_name: Array.isArray(filters?.l2_name?.[0]) ? filters?.l2_name?.[0]?.map(val => val.value) : filters?.l2_name?.map(val => val.value),
                l3_name: Array.isArray(filters?.l3_name?.[0]) ? filters?.l3_name?.[0]?.map(val => val.value) : filters?.l3_name?.map(val => val.value),
                l4_name: Array.isArray(filters?.l4_name?.[0]) ? filters?.l4_name?.[0]?.map(val => val.value) : filters?.l4_name?.map(val => val.value),
                l5_name: Array.isArray(filters?.l5_name?.[0]) ? filters?.l5_name?.[0]?.map(val => val.value) : filters?.l5_name?.map(val => val.value),
                l6_name: Array.isArray(filters?.l6_name?.[0]) ? filters?.l6_name?.[0]?.map(val => val.value) : filters?.l6_name?.map(val => val.value),
                dc: Array.isArray(filters?.dc?.[0]) ? filters.dc[0].map(val => val.value) : filters.dc.map(val => val.value),
                start_date: filters?.start_date && filters?.start_date[0],
                end_date: filters?.end_date && filters?.end_date[0],
                start_date_last_allocated: filters?.min_allocation_date && filters?.min_allocation_date[0],
                end_date_last_allocated: filters?.max_allocation_date && filters?.max_allocation_date[0],
                factory_type: Array.isArray(filters?.factory_type?.[0]) ? filters?.factory_type?.[0]?.map(val => val.value) : filters?.factory_type?.map(val => val.value),
                storesFilter
            }
            setFilters(req)
            setreviewRecommendation(true)
            props.getReviewRecommendData({ ...req, row_index: 0, row_count: 100 });
        }
        else {
            const types = {
                "New PO Allocation": "PO",
                "New ASN Allocation": "ASN",
                "New allocation to be released": "POASN",
                "Re-allocation for quantity mismatch": "RECEIPT",
                "Re-allocation for pack error": "ERROR"
            }
            const status = {
                "New PO Allocation": "Pending",
                "New ASN Allocation": "Pending",
                "New allocation to be released": "Processed",
                "Re-allocation for quantity mismatch": "Mismatch",
                "Re-allocation for pack error": "Error"
            }
            
            // if (types[row.stock_status]) {
            //     props.setShowPOAllocation(types[row.stock_status],status[row.stock_status])
            // }
            // else {
            //     history.push(`${NEW_STORE_RESERVED_ALLOCATIONS}`)
            // }
            props.setShowPOAllocation(true, row.status,storesFilter)
            // }
        }
    }

    const closeAlert = () => {
        setreviewRecommendation(false)
    }


    return (
        <div>
            <ReactTableWithPlugins
                getInstanceOnMount={(instance) => {
                    setRTinstance(instance)
                }}
                columns={RecommendedActionCols}
                data={props.data}
                pageSize={15}
                shouldPagination
                keyRT="RecommendedActionTable"
                totalRecordsLen={props.data.length}
                prepareRequest={true}
                renderMarkup='TableMarkup'
                tableId="recommended_action"
                tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
            />

            {reviewRecommendation && (
                <ReviewRecommendation
                    showAlert={reviewRecommendation}
                    closeAlert={closeAlert}
                    data={props.reviewRecommendedData}
                    filters={props.filters}
                    stylesCount={styleColorCount}
                    reviewRequest={filters}
                />
            )}
        </div>
    )
}

const mapStateToProps = ({ dashboard }) => ({
    reviewRecommendedData: dashboard.reviewRecommendedData,
});

const mapDispatchToProps = (dispatch) => ({
    getReviewRecommendData: (payload) => dispatch(getReviewRecommendData(payload)),
    resetPageIndex: () => dispatch(resetPageIndex())
})


export default connect(mapStateToProps, mapDispatchToProps)(RecommendedAction);
