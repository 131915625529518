import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { connect } from "react-redux";
import '../ProductStoreStatus.css'
import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import { getMissingConfigData } from "./Actions/ProductStatusAction"
import ColumnFilter from "../../UserManagement/ColumnFilter";
import PageLoader from '../../../components/Loader/PageLoader';
import { isEmpty } from 'lodash';
import { CREATE_CONFIGURATION } from "../../../constants/routeConstants";
import MissingConfigDetail from './Popups/MissingConfigDetailModal';
import { levelFiltersLabelFormatter } from '../../../utils/filterLevelMapping';

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);


const MissingConfigTable = (props) => {
    const history = useHistory();
    const [showDetailModal, setShowDetailModal] = useState(false)
    const [detailInstance, setDetailInstance] = useState(null)
    const [noData, setNoData] = useState({})

    const columns = [
        {
            id: 'expander',
            Header: "Department",
            accessor: "l1_name",
            Cell: ({ value, row }) =>
                row.canExpand && value ? (
                    <div className="row"
                        {...row.getToggleRowExpandedProps()}
                    >
                        <span
                        // className={row.original.noData ? 'red-text' : 'green-text'}
                        >
                            {value}
                        </span>
                        {
                            row.isExpanded ? (
                                <div
                                    className="cursor-pointer"
                                    style={{ color: "#50778e" }}
                                >
                                    <i className="fa fa-angle-down fa-lg ml-2 expand-collapse" title="Collpase" ></i>
                                </div>) :
                                <div
                                    className="cursor-pointer"
                                    style={{ color: "#50778e" }}
                                >
                                    <i className="fa fa-angle-right fa-lg ml-2 expand-collapse" title="Expand" ></i>
                                </div>
                        }
                    </div>
                ) : <span style={{ minHeight: "100%", minWidth: "100%" }}> </span>,
        },
        {
            id: 'expander1',
            Header: levelFiltersLabelFormatter("level2"),
            accessor: "l2_name",
            Cell: ({ value, row }) =>
                row.canExpand && value ? (
                    <div className="row"
                        {...row.getToggleRowExpandedProps()}
                    >
                        <span className={row.original.noData ? 'red-text' : 'green-text'}>

                            {value}
                        </span>
                        {
                            row.isExpanded ? (
                                <div
                                    className="cursor-pointer"
                                    style={{ color: "#50778e" }}
                                >
                                    <i className="fa fa-angle-down fa-lg ml-2 expand-collapse" title="Collpase" ></i>
                                </div>) :
                                <div
                                    className="cursor-pointer"
                                    style={{ color: "#50778e" }}
                                >
                                    <i className="fa fa-angle-right fa-lg ml-2 expand-collapse" title="Expand" ></i>
                                </div>
                        }
                    </div>
                ) : <span style={{ minHeight: "100%", minWidth: "100%" }}> </span>,
        },
        {
            id: 'expander2',
            Header: "Sub-Category",
            accessor: "l3_name",
            Cell: ({ value, row }) =>
                row.canExpand && value ? (
                    <div className="row"
                        {...row.getToggleRowExpandedProps()}
                    >
                        <span className={row.original.noData ? 'red-text' : 'green-text'}>

                            {value}
                        </span>
                        {
                            row.isExpanded ? (
                                <div
                                    className="cursor-pointer"
                                    style={{ color: "#50778e" }}
                                >
                                    <i className="fa fa-angle-down fa-lg ml-2 expand-collapse" title="Collpase" ></i>
                                </div>) :
                                <div
                                    className="cursor-pointer"
                                    style={{ color: "#50778e" }}
                                >
                                    <i className="fa fa-angle-right fa-lg ml-2 expand-collapse" title="Expand" ></i>
                                </div>
                        }
                    </div>
                ) : <span style={{ minHeight: "100%", minWidth: "100%" }}> </span>,
        },
        {
            Header: "DCS",
            accessor: "l4_name",
            Cell: ({ value, row }) =>
                <span className={row.original.noData ? 'red-text' : 'green-text'}>
                    {value}
                </span>
        },
        {
            Header: "Actions",
            accessor: "actions",
            Cell: (instance) => (
                <div className="row">
                    {
                        instance.row.id.length > 1 &&
                        <>
                            <button
                                className={"btn btn-outline-secondary btn-sm px-2 mr-4 rounded-lg text-body " + (instance.row.original.noData ? 'red-btn' : 'green-btn')}
                                onClick={() => { createConfig(instance) }}
                            >
                                Create Config
                                <i
                                    className="fa fa-angle-right ml-2"
                                    title="Configure"
                                    aria-hidden="true"
                                ></i>
                            </button>

                            <button
                                className={"btn btn-outline-secondary btn-sm px-2 rounded-lg text-body"}
                                onClick={() => { goToDetails(instance) }}
                            >
                                Detail View
                                <i
                                    className="fa fa-angle-right ml-2"
                                    title="Configure"
                                    aria-hidden="true"
                                ></i>
                            </button>
                        </>
                    }
                </div>
            ),
            width: 200,
            disableFilters: true
        },
    ]

    useEffect(() => {
        if (!isEmpty(props.filters)) {
            props.getMissingConfigData()
        }
    }, [props.filters])

    const createConfig = (ins) => {
        let { id } = ins.row
        let index = 0
        let filters = {}
        while ((index * 2) + 1 <= id.length) {
            filters[`l${index + 1}_name`] = ins.rowsById[id.substr(0, (index * 2) + 1)].original[`l${index + 1}_name`]
            index++;
        }
        history.push(`${CREATE_CONFIGURATION}`, { filters, isCreateMissing: true });
    }

    const goToDetails = (instance) => {
        setShowDetailModal(true)
        let { id } = instance.row
        let index = 0
        let filters = {}
        let noData = {}
        while ((index * 2) + 1 <= id.length) {
            filters[`l${index + 1}_name`] = instance.rowsById[id.substr(0, (index * 2) + 1)].original[`l${index + 1}_name`]
            noData[`l${index + 1}_name`] = instance.rowsById[id.substr(0, (index * 2) + 1)].original.noData
            index++;
        }
        setDetailInstance(filters)
        setNoData(noData)
    }
    console.log('ss',props.data)

    return (
        <section className="missing-config__table section">
            <div className="storesStore__heading mb-4">
                <h1 className="fnt-md fnt-bold">Missing Config Hierarchy</h1>
            </div>
            {(props.dataError) ? (<div className="error">{props.dataError}</div>) :
                <ReactTableWithPlugins
                    data={props.data}
                    columns={columns}
                    renderMarkup='TableMarkup'
                    tableId="product_config_missing"
                    tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                    headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                />
            }
            {
                showDetailModal &&
                <MissingConfigDetail closeModal={() => { setShowDetailModal(false) }} filters={detailInstance}
                noData={noData} />
            }
        </section>

    )
}

const mapStateToProps = ({ productStatus }) => ({
    data: productStatus.noConfigData,
    dataError: productStatus.noConfigDataError,
    dataLoading: productStatus.noConfigDataLoading,
})

const mapDispatchToProps = (dispatch) => ({
    getMissingConfigData: () => dispatch(getMissingConfigData())
})

export default connect(mapStateToProps, mapDispatchToProps)(MissingConfigTable)