export const GET_STORE_DATA = "@@finalize/GET_STORE_DATA";
export const GET_STORE_DATA_SUCCESS = "@@finalize/GET_STORE_DATA_SUCCESS";
export const GET_STORE_DATA_ERROR = "@@finalize/GET_STORE_DATA_ERROR";

export const GET_STORE_DETAILS = "@@finalize/GET_STORE_DETAILS";
export const GET_STORE_DETAILS_SUCCESS = "@@finalize/GET_STORE_DETAILS_SUCCESS";
export const GET_STORE_DETAILS_ERROR = "@@finalize/GET_STORE_DETAILS_ERROR";

export const GET_STORE_PRODUCT_DETAILS = "@@finalize/GET_STORE_PRODUCT_DETAILS";
export const GET_STORE_PRODUCT_DETAILS_SUCCESS = "@@finalize/GET_STORE_PRODUCT_DETAILS_SUCCESS";
export const GET_STORE_PRODUCT_DETAILS_ERROR = "@@finalize/GET_STORE_PRODUCT_DETAILS_ERROR";

export const GET_PRODUCT_DATA = "@@finalize/GET_PRODUCT_DATA";
export const GET_PRODUCT_DATA_SUCCESS = "@@finalize/GET_PRODUCT_DATA_SUCCESS";
export const GET_PRODUCT_DATA_ERROR = "@@finalize/GET_PRODUCT_DATA_ERROR";

export const GET_PRODUCT_DETAILS = "@@finalize/GET_PRODUCT_DETAILS";
export const GET_PRODUCT_DETAILS_SUCCESS = "@@finalize/GET_PRODUCT_DETAILS_SUCCESS";
export const GET_PRODUCT_DETAILS_ERROR = "@@finalize/GET_PRODUCT_DETAILS_ERROR";

export const GET_PACK_DETAILS = "@@finalize/GET_PACK_DETAILS";
export const GET_PACK_DETAILS_SUCCESS = "@@finalize/GET_PACK_DETAILS_SUCCESS";
export const GET_PACK_DETAILS_ERROR = "@@finalize/GET_PACK_DETAILS_ERROR";

export const UPDATE_PACKS_EACHES_FOR_STORES = "@@finalize/UPDATE_PACKS_EACHES_FOR_STORES";
export const UPDATE_PACKS_EACHES_FOR_STORES_SUCCESS = "@@finalize/UPDATE_PACKS_EACHES_FOR_STORES_SUCCESS";
export const UPDATE_PACKS_EACHES_FOR_STORES_ERROR = "@@finalize/UPDATE_PACKS_EACHES_FOR_STORES_ERROR";

export const DISABLE_EDIT = "@@finalize/DISABLE_EDIT";
export const DISABLE_EDIT_SUCCESS = "@@finalize/DISABLE_EDIT_SUCCESS";
export const DISABLE_EDIT_ERROR = "@@finalize/DISABLE_EDIT_ERROR";

export const EDIT_ALLOCATED_UNITS = "@@finalize/EDIT_ALLOCATED_UNITS"
export const EDIT_ALLOCATED_UNITS_SUCCESS = "@@finalize/EDIT_ALLOCATED_UNITS_SUCCESS"
export const EDIT_ALLOCATED_UNITS_ERROR = "@@finalize/EDIT_ALLOCATED_UNITS_ERROR"
export const DC_AVAILABLE_ERROR = "@@finalize/DC_AVAILABLE_ERROR"
export const RESET_EDIT_ERROR = "@@finalize/RESET_EDIT_ERROR"

export const FINALIZE_ALLOCATION = "@@finalize/FINALIZE_ALLOCATION"
export const FINALIZE_ALLOCATION_SUCCESS = "@@finalize/FINALIZE_ALLOCATION_SUCCESS"
export const FINALIZE_ALLOCATION_ERROR = "@@finalize/FINALIZE_ALLOCATION_ERROR"

export const CONFIRM_DATA_ADA = "@@finalize/CONFIRM_DATA_ADA"
export const CONFIRM_DATA_ADA_SUCCESS = "@@finalize/CONFIRM_DATA_ADA_SUCCESS"
export const CONFIRM_DATA_ADA_FAILED = "@@finalize/CONFIRM_DATA_ADA_FAILED"

export const UPLOAD_FILE = "@@finalize/UPLOAD_FILE"
export const UPLOAD_FILE_SUCCESS = "@@finalize/UPLOAD_FILE_SUCCESS"
export const UPLOAD_FILE_ERROR = "@@finalize/UPLOAD_FILE_ERROR"

export const UPDATE_FINALIZE_INFO = "@@finalize/UPDATE_FINALIZE_INFO"
export const UPDATE_FINALIZE_INFO_SUCCESS = "@@finalize/UPDATE_FINALIZE_INFO_SUCCESS"
export const UPDATE_FINALIZE_INFO_ERROR = "@@finalize/UPDATE_FINALIZE_INFO_ERROR"

export const DOWNLOAD_XML = "@@finalize/DOWNLOAD_XML"
export const DOWNLOAD_XML_SUCCESS = "@@finalize/DOWNLOAD_XML_SUCCESS"
export const DOWNLOAD_XML_ERROR = "@@finalize/DOWNLOAD_XML_ERROR"

export const DOWNLOAD_EXCEL = "@@finalize/DOWNLOAD_EXCEL"
export const DOWNLOAD_EXCEL_SUCCESS = "@@finalize/DOWNLOAD_EXCEL_SUCCESS"
export const DOWNLOAD_EXCEL_ERROR = "@@finalize/DOWNLOAD_EXCEL_ERROR"

export const DOWNLOAD_CSV = "@@finalize/DOWNLOAD_CSV"
export const DOWNLOAD_CSV_SUCCESS = "@@finalize/DOWNLOAD_CSV_SUCCESS"
export const DOWNLOAD_CSV_ERROR = "@@finalize/DOWNLOAD_CSV_ERROR"

export const DOWNLOAD_CSV_BEFORE_FINALIZE = "@@finalize/DOWNLOAD_CSV_BEFORE_FINALIZE"
export const DOWNLOAD_CSV_BEFORE_FINALIZE_SUCCESS = "@@finalize/DOWNLOAD_CSV_BEFORE_FINALIZE_SUCCESS"
export const DOWNLOAD_CSV_BEFORE_FINALIZE_ERROR = "@@finalize/DOWNLOAD_CSV_BEFORE_FINALIZE_ERROR"

export const UPDATE_RESERVE_QTY = "@@finalize/UPDATE_RESERVE_QTY"
export const UPDATE_RESERVE_QTY_SUCCESS = "@@finalize/UPDATE_RESERVE_QTY_SUCCESS"
export const UPDATE_RESERVE_QTY_ERROR = "@@finalize/UPDATE_RESERVE_QTY_ERROR"

export const GET_PAST_ALLOCATION_PO = "@@finalize/GET_PAST_ALLOCATION_PO"
export const GET_PAST_ALLOCATION_PO_SUCCESS = "@@finalize/GET_PAST_ALLOCATION_PO_SUCCESS"
export const GET_PAST_ALLOCATION_PO_ERROR = "@@finalize/GET_PAST_ALLOCATION_PO_ERROR"

export const GET_PACKS_FOR_BULK_UPDATE = "@@finalize/GET_PACKS_FOR_BULK_UPDATE";
export const GET_PACKS_FOR_BULK_UPDATE_SUCCESS = "@@finalize/GET_PACKS_FOR_BULK_UPDATE_SUCCESS";
export const GET_PACKS_FOR_BULK_UPDATE_ERROR = "@@finalize/GET_PACKS_FOR_BULK_UPDATE_ERROR";

export const RESET_ALL = "@@finalize/RESET_ALL"
export const RESET_FINALISE_DATA = "@@finalize/RESET_FINALISE_DATA"

export const SAVE_STORE_DATA = "@@finalize/SAVE_STORE_DATA"
export const SAVE_STORE_DATA_SUCCESS = "@@finalize/SAVE_STORE_DATA_SUCCESS";
export const SAVE_STORE_DATA_ERROR = "@@finalize/SAVE_STORE_DATA_ERROR";

export const getPastAllocationPO = (payload) => ({
    type: GET_PAST_ALLOCATION_PO,
    payload,
});

export const getPastAllocationPOSuccess = (payload) => ({
    type: GET_PAST_ALLOCATION_PO_SUCCESS,
    payload,
});

export const getPastAllocationPOError = (payload) => ({
    type: GET_PAST_ALLOCATION_PO_ERROR,
    payload,
});


export const getStoreData = (payload) => ({
    type: GET_STORE_DATA,
    payload,
});

export const getStoreDataSuccess = (payload) => ({
    type: GET_STORE_DATA_SUCCESS,
    payload,
});

export const getStoreDataError = (payload) => ({
    type: GET_STORE_DATA_ERROR,
    payload,
});

export const getStoreDetails = (payload) => ({
    type: GET_STORE_DETAILS,
    payload
})

export const getStoreDetailsSuccess = (payload) => ({
    type: GET_STORE_DETAILS_SUCCESS,
    payload
})

export const getStoreDetailsError = (payload) => ({
    type: GET_STORE_DETAILS_ERROR,
    payload
})

export const getStoreProductDetails = (payload) => ({
    type: GET_STORE_PRODUCT_DETAILS,
    payload
})

export const getStoreProductDetailsSuccess = (payload) => ({
    type: GET_STORE_PRODUCT_DETAILS_SUCCESS,
    payload
})

export const getStoreProductDetailsError = (payload) => ({
    type: GET_STORE_PRODUCT_DETAILS_ERROR,
    payload
})

export const getProductData = (payload) => ({
    type: GET_PRODUCT_DATA,
    payload
})

export const getProductDataSuccess = (payload) => ({
    type: GET_PRODUCT_DATA_SUCCESS,
    payload
})

export const getProductDataError = (payload) => ({
    type: GET_PRODUCT_DATA_ERROR,
    payload
})

export const getProductDetails = (payload) => ({
    type: GET_PRODUCT_DETAILS,
    payload
})

export const getProductDetailsSuccess = (payload) => ({
    type: GET_PRODUCT_DETAILS_SUCCESS,
    payload
})

export const getProductDetailsError = (payload) => ({
    type: GET_PRODUCT_DETAILS_ERROR,
    payload
})

export const editAllocatedUnits = (payload) => ({
    type: EDIT_ALLOCATED_UNITS,
    payload
})

export const editAllocatedUnitSuccess = (payload) => ({
    type: EDIT_ALLOCATED_UNITS_SUCCESS,
    payload
})

export const editAllocatedUnitError = (payload) => ({
    type: EDIT_ALLOCATED_UNITS_ERROR,
    payload
})

export const setDcAvailableError = (payload) => ({
    type: DC_AVAILABLE_ERROR,
    payload
})

export const resetEditAllocationError = () => ({
    type: RESET_EDIT_ERROR
})

export const downloadXml = (payload) => ({
    type: DOWNLOAD_XML,
    payload
})

export const downloadXmlSuccess = (payload) => ({
    type: DOWNLOAD_XML_SUCCESS,
    payload
})


export const downloadXmlError = (payload) => ({
    type: DOWNLOAD_XML_ERROR,
    payload
})

export const downloadExcel = (payload) => ({
    type: DOWNLOAD_EXCEL,
    payload
})

export const downloadExcelSuccess = (payload) => ({
    type: DOWNLOAD_EXCEL_SUCCESS,
    payload
})


export const downloadExcelError = (payload) => ({
    type: DOWNLOAD_EXCEL_ERROR,
    payload
})

//func to download csv for client - ashley
export const downloadCsv = (payload) => ({
    type: DOWNLOAD_CSV,
    payload
})

export const downloadCsvBeforeFinalize = (payload) => ({
    type: DOWNLOAD_CSV_BEFORE_FINALIZE,
    payload
})

export const downloadCsvBeforeFinalizeSuccess = (payload) => ({
    type: DOWNLOAD_CSV_BEFORE_FINALIZE_SUCCESS,
    payload
})

export const downloadCsvBeforeFinalizeError = (payload) => ({
    type: DOWNLOAD_CSV_BEFORE_FINALIZE_ERROR,
    payload
})


export const finalizeAllocation = (payload, source) => ({
    type: FINALIZE_ALLOCATION,
    payload,
    source
})

export const finalizeAllocationSuccess = (payload) => ({
    type: FINALIZE_ALLOCATION_SUCCESS,
    payload
})

export const finalizeAllocationError = (payload) => ({
    type: FINALIZE_ALLOCATION_ERROR,
    payload
})

export const confirmDataAda = (payload) => ({
    type: CONFIRM_DATA_ADA,
    payload
})

export const confirmDataAdaSuccess = (payload) => ({
    type: CONFIRM_DATA_ADA_SUCCESS,
    payload
})

export const confirmDataAdaFailed = (payload) => ({
    type: CONFIRM_DATA_ADA_FAILED,
    payload
})


export const uploadFile = (payload) => ({
    type: UPLOAD_FILE,
    payload
})

export const uploadFileSuccess = (payload) => ({
    type: UPLOAD_FILE_SUCCESS,
    payload
})

export const uploadFileError = (payload) => ({
    type: UPLOAD_FILE_ERROR,
    payload
})

export const updateFinalizeInfo = (payload) => ({
    type: UPDATE_FINALIZE_INFO,
    payload
})

export const updateFinalizeInfoSuccess = (payload) => ({
    type: UPDATE_FINALIZE_INFO_SUCCESS,
    payload
})

export const updateFinalizeInfoError = (payload) => ({
    type: UPDATE_FINALIZE_INFO_ERROR,
    payload
})


export const updateReserveQuantity = (payload) => ({
    type: UPDATE_RESERVE_QTY,
    payload
})

export const updateReserveQuantitySuccess = (payload) => ({
    type: UPDATE_RESERVE_QTY_SUCCESS,
    payload
})

export const updateReserveQuantityError = (payload) => ({
    type: UPDATE_RESERVE_QTY_ERROR,
    payload
})

export const getPackDetails = (payload) => ({
    type: GET_PACK_DETAILS,
    payload
})

export const getPackDetailsSuccess = (payload) => ({
    type: GET_PACK_DETAILS_SUCCESS,
    payload
})

export const getPackDetailsError = (payload) => ({
    type: GET_PACK_DETAILS_ERROR,
    payload
})

export const disableEdit = (payload) => ({
    type: DISABLE_EDIT,
    payload
})

export const disableEditSuccess = (payload) => ({
    type: DISABLE_EDIT_SUCCESS,
    payload
})

export const disableEditError = (payload) => ({
    type: DISABLE_EDIT_ERROR,
    payload
})

export const updatePacksEachesForMultipleStores = (payload) => ({
    type: UPDATE_PACKS_EACHES_FOR_STORES,
    payload
})

export const getPacksForBulkEdit = (payload) => ({
    type: GET_PACKS_FOR_BULK_UPDATE,
    payload
})

export const getPacksForBulkEditSuccess = (payload) => ({
    type: GET_PACKS_FOR_BULK_UPDATE_SUCCESS,
    payload
})

export const getPacksForBulkEditError = (payload) => ({
    type: GET_PACKS_FOR_BULK_UPDATE_ERROR,
    payload
})

export const updatePacksEachesForMultipleStoresSuccess = (payload) => ({
    type: UPDATE_PACKS_EACHES_FOR_STORES_SUCCESS,
    payload
})

export const updatePacksEachesForMultipleStoresError = (payload) => ({
    type: UPDATE_PACKS_EACHES_FOR_STORES_ERROR,
    payload
})
export const resetAll = () => ({
    type: RESET_ALL,
});

export const resetFinaliseData = () => ({
    type: RESET_FINALISE_DATA
})

export const saveStoreData = (payload) => ({
    type: SAVE_STORE_DATA,
    payload,
});

export const saveStoreDataSuccess = (payload) => ({
    type: SAVE_STORE_DATA_SUCCESS,
    payload,
});

export const saveStoreDataError = (payload) => ({
    type: SAVE_STORE_DATA_ERROR,
    payload,
});
