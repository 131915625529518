export const GET_DEMAND_DATA = "@@demandConstraints/GET_DEMAND_DATA";
export const GET_DEMAND_DATA_SUCCESS = "@@demandConstraints/GET_DEMAND_DATA_SUCCESS";
export const GET_DEMAND_DATA_ERROR = "@@demandConstraints/GET_DEMAND_DATA_ERROR";

export const GET_PP_DATA = "@@demandConstraints/GET_PP_DATA";
export const GET_PP_DATA_SUCCESS = "@@demandConstraints/GET_PP_DATA_SUCCESS";
export const GET_PP_DATA_ERROR = "@@demandConstraints/GET_PP_DATA_ERROR";

export const GET_REVIEW_ALLOCATION_DATA = "@@demandConstraints/GET_REVIEW_ALLOCATION_DATA";
export const GET_REVIEW_ALLOCATION_DATA_SUCCESS = "@@demandConstraints/GET_REVIEW_ALLOCATION_DATA_SUCCESS";
export const GET_REVIEW_ALLOCATION_DATA_ERROR = "@@demandConstraints/GET_REVIEW_ALLOCATION_DATA_ERROR";

export const GET_ADD_PRODUCTS_DATA = "@@demandConstraints/GET_ADD_PRODUCTS_DATA";
export const GET_ADD_PRODUCTS_DATA_SUCCESS = "@@demandConstraints/GET_ADD_PRODUCTS_DATA_SUCCESS";
export const GET_ADD_PRODUCTS_DATA_ERROR = "@@demandConstraints/GET_ADD_PRODUCTS_DATA_ERROR";

export const SAVE_NEW_STORE_ALLOCATION = "@@demandConstraints/SAVE_NEW_STORE_ALLOCATION";
export const SAVE_NEW_STORE_ALLOCATION_SUCCESS = "@@demandConstraints/SAVE_NEW_STORE_ALLOCATION_SUCCESS";
export const SAVE_NEW_STORE_ALLOCATION_ERROR = "@@demandConstraints/SAVE_NEW_STORE_ALLOCATION_ERROR";

export const GET_NEW_STORE_LIST = "@@demandConstraints/GET_NEW_STORE_LIST";
export const GET_NEW_STORE_LIST_SUCCESS = "@@demandConstraints/GET_NEW_STORE_LIST_SUCCESS";
export const GET_NEW_STORE_LIST_ERROR = "@@demandConstraints/GET_NEW_STORE_LIST_ERROR";

export const REVIEW_NEW_STORE_ALLOCATION = "@@demandConstraints/REVIEW_NEW_STORE_ALLOCATION";
export const REVIEW_NEW_STORE_ALLOCATION_SUCCESS = "@@demandConstraints/REVIEW_NEW_STORE_ALLOCATION_SUCCESS";
export const REVIEW_NEW_STORE_ALLOCATION_ERROR = "@@demandConstraints/REVIEW_NEW_STORE_ALLOCATION_ERROR";

export const GET_ARTICLE_ALLOCATION = "@@demandConstraints/GET_ARTICLE_ALLOCATION";
export const GET_ARTICLE_ALLOCATION_SUCCESS = "@@demandConstraints/GET_ARTICLE_ALLOCATION_SUCCESS";
export const GET_ARTICLE_ALLOCATION_ERROR = "@@demandConstraints/GET_ARTICLE_ALLOCATION_ERROR";

export const UPDATE_ARTICLE_ALLOCATION = "@@demandConstraints/UPDATE_ARTICLE_ALLOCATION";
export const UPDATE_ARTICLE_ALLOCATION_SUCCESS = "@@demandConstraints/UPDATE_ARTICLE_ALLOCATION_SUCCESS";
export const UPDATE_ARTICLE_ALLOCATION_ERROR = "@@demandConstraints/UPDATE_ARTICLE_ALLOCATION_ERROR";

export const SET_SELECTED_DEMAND_TABLE_ROWS = "@@demandConstraints/SET_SELECTED_DEMAND_TABLE_ROWS"

export const RESET_ALL = "@@demandConstraints/RESET_ALL";
export const RESET_RESERVED_DATA = "@@demandConstraints/RESET_RESERVED_DATA"
export const RESET_ADD_PRODUCTS_DATA = "@@demandConstraints/RESET_ADD_PRODUCTS_DATA"

export const GET_NEW_STORE_SIZE_CURVE = "@@demandConstraints/GET_NEW_STORE_SIZE_CURVE";
export const GET_NEW_STORE_SIZE_CURVE_SUCCESS = "@@demandConstraints/GET_NEW_STORE_SIZE_CURVE_SUCCESS";
export const GET_NEW_STORE_SIZE_CURVE_ERROR = "@@demandConstraints/GET_NEW_STORE_SIZE_CURVE_ERROR";

export const RESET_STYLE_INDEX = "@@demandConstraints/RESET_STYLE_INDEX";
export const RESET_CA_STYLE_INDEX = "@@demandConstraints/RESET_CA_STYLE_INDEX";

export const GET_CHECK_ALL_DEMAND_DATA_SUCCESS = "@@demandConstraints/GET_CHECK_ALL_DEMAND_DATA_SUCCESS";
export const SET_PAGE_INDEX = "@@demandConstraints/SET_PAGE_INDEX";
