import React, { useEffect, useState } from 'react'
import '../../ProductStoreStatus.css'
import ReactTable from "../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import ColumnFilter from "../../../UserManagement/ColumnFilter";
import PageLoader from '../../../../components/Loader/PageLoader';
import { Tab, Tabs } from 'react-bootstrap';
import { levelFiltersLabelFormatter, updateArticleName } from '../../../../utils/filterLevelMapping';
import ReactTollTip from "../../../../components/Table/Cellrenderer/ReactToolTip";


const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const ProductProfileMapping = (props) => {
    const [activeTab, setActiveTab] = useState("pen")
    const [showStoreSize, setShowStoreSize] = useState(false)
    const [pencolumns, setPenColumns] = useState([])
    const [isPenColumns, setIsPenColumns] = useState(false)

    const columns = React.useMemo(
        () => [
            {
                Header: "Product Profile",
                accessor: "product_profile",
                Cell: (instance) => (
                    <div>
                        <div className="clickable" onClick={() => getStoreSizeContribution(instance.row?.original?.product_profile_code)}>{instance.value}</div>
                    </div>
                ),
                Filter: ColumnFilter,
                sticky: "left",
                width: 300
            },
            {
                Header: levelFiltersLabelFormatter("level1"),
                accessor: "l1_name",
                Filter: ColumnFilter
            },
            {
                Header: levelFiltersLabelFormatter("level2"),
                accessor: "l2_name",
                Filter: ColumnFilter
            },
            {
                Header: levelFiltersLabelFormatter("level3"),
                accessor: "l3_name",
                Cell: (instance) => <ReactTollTip {...instance}/>,
                Filter: ColumnFilter
            },
            {
                Header: levelFiltersLabelFormatter("level4"),
                accessor: "l4_name",
                Cell: (instance) => <ReactTollTip {...instance}/>,
                Filter: ColumnFilter
            },
            {
                Header: levelFiltersLabelFormatter("level5"),
                accessor: "l5_name",
                Filter: ColumnFilter
            },
            {
                Header: levelFiltersLabelFormatter("level6"),
                accessor: "l6_name",
                Filter: ColumnFilter,
            },
            {
                Header: "Time Period",
                accessor: "period",
                Filter: ColumnFilter
            },
            {
                Header: "Sales Attribute",
                accessor: "sales_attribute",
                Filter: ColumnFilter
            },
            {
                Header: "Product Attribute",
                accessor: "product_attribute",
                Filter: ColumnFilter
            }
        ]
    )

    const styleColorColumns = [
        {
            Header:  `${updateArticleName(false)} ID`,
            accessor: "article",
            Filter: (instance) => <ColumnFilter {...instance} placeholder="Search in bulk by ','" />,
            filter: 'bulkFilterCommaSeperated',
        },
        // {
        //     Header: 'Style ID',
        //     accessor: 'style_code',
        //     Filter: ColumnFilter
        // },
        // {
        //     Header: 'Color ID',
        //     accessor: 'color_code',
        //     Filter: ColumnFilter
        // },
        {
            Header: "Style Description",
            accessor: "style_name",
            Filter: ColumnFilter,
            width: 250
        },
        {
            Header: "Global Color",
            accessor: "color",
            Filter: ColumnFilter
        },
        {
            Header: "Style Color",
            accessor: "color_desc",
            Filter: ColumnFilter,
            width: 200
        },
        {
            Header: "Contribution",
            accessor: "contribution",
            Filter: ColumnFilter
        }
    ]

    const getStoreSizeContribution = (value) => {
        setShowStoreSize(true)
        props.getStoreSizeContribution(value)
    }

    const handleTabSelect = (f_tab) => {
        setActiveTab(f_tab)
    }

    useEffect(() => {
        if (props.storeSizeContributionData?.sizeArr) {
            let sizes = props.storeSizeContributionData?.sizeArr
            const penetrationColumns = [
                {
                    Header: "Store Number",
                    accessor: "store_code",
                    Filter: ColumnFilter
                },
                {
                    Header: "Store Name",
                    accessor: "store_name",
                    Filter: ColumnFilter
                },
                {
                    Header: "Overall",
                    accessor: "overall",
                    Filter: ColumnFilter
                },
                {
                    Header: "Product Profile Pen%",
                    columns: []
                },
            ]
            sizes.map(item => {
                penetrationColumns[3].columns.push({
                    Header: item,
                    accessor: row => Number(row.size_data[item]) ? (Number(row.size_data[item]) * 100).toFixed(1) + " %" : "-",
                    Filter: ColumnFilter
                })
            })
            setPenColumns(penetrationColumns)
            setIsPenColumns(true)
        }
    }, [props.storeSizeContributionData])


    return (
        <>
            <section className="section mr-4">
                {(props.productProfilesDataError) ? (<div className="error">{props.productProfilesDataError}</div>) :
                    <PageLoader loader={props.productProfilesDataLoading} gridLoader={true}>
                        <div className="note"> <span>Please Select Exactly One Product Profile</span></div>
                        <ReactTableWithPlugins
                            shouldPagination
                            totalRecordsLen={props.data ? props.data.length : (props.productProfilesData?.product_profiles?.length)}
                            data={props.data ? props.data : (props.productProfilesData?.product_profiles ? props.productProfilesData?.product_profiles : [])}
                            columns={props.isAddActionColumn ? columns : columns.slice(0, columns.length - 1)}
                            initialSelectedRows={props.initialSelectedRows}
                            // features={["CHECKBOX_FOR_FIRSTCOLUMN", "HIDE_CHECKALL"]}
                            renderMarkup='TableMarkup'
                            keyRT="productProfileMapping"
                            getInstanceOnMount={(instance) => {
                                props.setRTinstance(instance)
                            }}
                            tableId="product_config_pp"
                            tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                            headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                        />
                    </PageLoader>
                }
            </section>
            {
                showStoreSize &&
                <section className="section mr-4 mt-4">
                    <div className="container__header m-0">
                        <h3 className="fnt-bold">Store and Size Contributions</h3>
                    </div>
                    <hr />
                    <div className="container__tabs">
                        <Tabs defaultActiveKey="pen" activeKey={activeTab}
                            onSelect={handleTabSelect}>
                            <Tab eventKey="pen" title="Penetration">
                                {(props.storeSizeContributionDataError) ? (<div className="error">{props.storeSizeContributionDataError}</div>) :
                                    <section className="row section">
                                        <PageLoader loader={props.storeSizeContributionDataLoading} gridLoader={true}>
                                            <ReactTableWithPlugins
                                                hideColumnsFilter
                                                shouldPagination
                                                totalRecordsLen={props.storeSizeContributionData?.storeData?.length}
                                                data={props.storeSizeContributionData?.storeData ? props.storeSizeContributionData?.storeData : []}
                                                columns={pencolumns}
                                                renderMarkup='TableMarkup'
                                                keyRT="sortAndSearch"
                                                tableId="product_config_store_penetration"
                                                tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                                                headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                                            />
                                        </PageLoader>
                                    </section>
                                }

                            </Tab>
                            <Tab eventKey="scd" title="Style Color Description">
                                <section className="row section">
                                    <ReactTableWithPlugins
                                        shouldPagination
                                        totalRecordsLen={props.storeSizeContributionData?.articleData?.length}
                                        data={props.storeSizeContributionData?.articleData ? props.storeSizeContributionData?.articleData : []}
                                        columns={styleColorColumns}
                                        renderMarkup='TableMarkup'
                                        keyRT="sortAndSearch"
                                        tableId="product_config_style_desc"
                                        tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                                        headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                                    />
                                </section>
                            </Tab>
                        </Tabs>
                    </div>

                </section>
            }
        </>
    )
}

export default ProductProfileMapping