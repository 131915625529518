export const HEADER = "Reserved Allocations"
export const TABLE_HEADERS = {
    STORE_CODE: "Store #",
    REGION: "Region",
    PLAN_NAME: "Plan Name",
    CREATION_DATE: "Creation Date",
    STYLE_COUNT: "# Style Color",
    STORE_NAME: "Store Name",
    ESTIMATED_ALLOCATION: "Estimated Allocation",
    OPENING_DATE: "Expected Opening Date",
    DEPARTMENT: "Department",
    SEASON_CODE: "Season Code"
}