import React, { useState, useEffect } from "react";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import ReactTable from "../../../components/Table/ReactTable"
import ColumnFilter from "../../UserManagement/ColumnFilter"
import { numberUSFormatting, oneDecimal, twoDecimal } from "../../../utils/formatters/valueFormatters";
import { levelFiltersLabelFormatter, updateArticleName } from "../../../utils/filterLevelMapping"
import NumberRangeColumnFilter from "../../../components/Filters/NumberRangeColumnFilter";
import ReactTollTip from "../../../components/Table/Cellrenderer/ReactToolTip";
import { isEmpty } from "lodash";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const ProductListTable = (props) => {
    const { initialHiddenColumns } = props
    const [tableColums, setTableColumns] = useState([])

    const productDatacolumns = React.useMemo(() => [
        {
            Header: " ",
            sticky: "left",
            columns: [
                {
                    Header: `${updateArticleName(false)} ID`,
                    accessor: 'article',
                    Cell: (instance) => (
                        <div>
                            <div className="clickable" onClick={() => props.getProductDetails(instance.row?.original)}>{instance.row?.original?.article}</div>
                        </div>
                    ),
                    Filter: (instance) => <ColumnFilter {...instance} placeholder="Search in bulk by ','" />,
                    filter: 'bulkFilterCommaSeperated',
                },
                {
                    Header: 'Style Description',
                    accessor: 'style_name',
                    width: 200,
                    Filter: ColumnFilter,
                },
                {
                    Header: 'Style Color',
                    accessor: 'color_desc',
                    Filter: ColumnFilter,
                },
            ]
        },
        {
            Header: 'Pack ID',
            accessor: 'packs',
            id: "pack_id",
            Filter: ColumnFilter
        },
        {
            Header: 'Demand Type',
            accessor: 'demand_type',
            Filter: ColumnFilter,
        },
        {
            Header: 'Store Group',
            accessor: 'store_groups',
            Cell: (instance) => <ReactTollTip {...instance}/>,
            width: 300,
            Filter: ColumnFilter,
        },
        {
            Header: '# Stores',
            accessor: 'store_group_store_count',
            id: "no_of_stores",
            Filter: ColumnFilter,
        },

        {
            Header: levelFiltersLabelFormatter("level1"),
            accessor: "l1_name",
            Filter: ColumnFilter
        },
        {
            Header: levelFiltersLabelFormatter("level2"),
            accessor: "l2_name",
            Filter: ColumnFilter,
        },
        {
            Header: levelFiltersLabelFormatter("level3"),
            accessor: "l3_name",
            Filter: ColumnFilter,
        },
        {
            Header: levelFiltersLabelFormatter("level4"),
            accessor: "l4_name",
            Filter: ColumnFilter,
        },
        {
            Header: levelFiltersLabelFormatter("level5"),
            accessor: "l5_name",
            Filter: ColumnFilter,
        },
        {
            Header: levelFiltersLabelFormatter("level6"),
            accessor: "l6_name",
            Filter: ColumnFilter,
        },
        // {
        //     Header: "LW Sales Units",
        //     accessor: "lw_qty",
        //     Filter: NumberRangeColumnFilter,
        //     filter: 'between',
        // },
        // {
        //     Header: "WTD Sales Units",
        //     accessor: "tw_qty",
        //     Cell: (inst) => (
        //         <div>
        //             {inst.value || inst.value === 0
        //                 ? numberUSFormatting(inst.value)
        //                 : null}
        //         </div>
        //     ),
        //     Filter: NumberRangeColumnFilter,
        //     filter: 'between',

        // },
        {
            Header: '# Allocated Stores',
            id: "store_cnt",
            accessor: 'store_code',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'Max Constraint',
            accessor: 'max',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'Min Constraint',
            accessor: 'min',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        // {
        //     Header: 'Ecom Inventory',
        //     accessor: "ecom_inventory",
        //     Filter: NumberRangeColumnFilter,
        //     filter: 'between',
        // },
        {
            Header: 'Inv. Source',
            accessor: "inventory_source",
            Filter: ColumnFilter,
        },
        {
            Header: "Allocated Total Quantity",
            identify: "total_qty",
            columns: [
                {
                    Header: '# Packs Allocated',
                    accessor: 'packs_allocated',
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
                {
                    Header: '# Eaches Allocated',
                    accessor: 'loose_units_allocated',
                    Cell: (inst) => numberUSFormatting(inst?.value),
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
                // {
                //     Header: 'Total Allocated Units',
                //     accessor: 'allocated_quantity',
                //     Cell: (inst) => numberUSFormatting(inst?.value),
                //     Filter: NumberRangeColumnFilter,
                //     filter: 'between',
                //     width: 200
                // }
            ]
        },
        {
            Header: 'Net DC Available',
            identify: "net_available",
            columns:[]
        },
        {
            Header: 'Original APS',
            accessor: row => row.original_aps ? oneDecimal(Number(row.original_aps)) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',

        },
        {
            Header: 'Forecasted APS',
            accessor: row => row.forecast_aps ? oneDecimal(Number(row.forecast_aps)) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',

        },
        {
            Header: 'Target WOS',
            accessor: row => row.target_wos,
            Filter: NumberRangeColumnFilter,
            filter: 'between',

        },
        {
            Header: 'Forecasted WOS',
            accessor: row => row.actual_wos,
            Cell: (inst) => numberUSFormatting(inst?.value),
            Filter: NumberRangeColumnFilter,
            filter: 'between',

        },
        {
            Header: 'Size',
            identify: "size",
            columns: []
        },
    ])

    const setSizeColumns = () => {
        if (props.columns) {
            return true
        }
        // let columns = props.columns ? props.columns : productDatacolumns
        else if (props.sizes.length) {
            let sizeIndex = productDatacolumns.findIndex(col => col.identify === "size");
            let totalIndex = productDatacolumns.findIndex(col => col.identify === "total_qty");
            let netIndex = productDatacolumns.findIndex(col => col.identify === "net_available");

            props.sizes.forEach((s, index) => {
                productDatacolumns[sizeIndex].columns.push({
                    Header: s,
                    accessor: row => row.size_value[index] ? Number(row.size_value[index]).toFixed(0) : 0,
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                })
            })
            props?.dc_list?.length && props?.dc_list?.map((val) =>{
                if(val){
                    productDatacolumns[totalIndex].columns.push({
                      Header: val + " ",
                      accessor: row => row.dc?.[val]?.["allocated_quantity"] || 0,
                      Cell: (inst) => inst?.value ? numberUSFormatting(inst?.value) : 0,
                      Filter: NumberRangeColumnFilter,
                      filter: 'between',
                  })
                  productDatacolumns[netIndex].columns.push({
                    Header: val,
                    accessor: row => row.dc?.[val]?.["dc_available"] || 0,
                    Cell: (inst) => inst?.value ? numberUSFormatting(inst?.value) : 0,
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                  })
                }  
            })
            console.log(props,'csdcsdc')
            
            // setTableColumns(columns)
            return true
        }
        return false
    }

    return (

        setSizeColumns() &&
        // setSizeColumns() &&
        <ReactTableWithPlugins
            hideOptions={props.sizes}
            // shouldPagination
            embededScroll
            totalRecordsLen={props.data ? props.data.length : 0}
            data={props.data ? props.data : []}
            // sortBy={[{ id: 'allocated_quantity', desc: true }]}
            columns={props.columns ? props.columns : productDatacolumns}
            renderMarkup='TableMarkup'
            keyRT="sortAndSearch"
            tableId="finalize_product_list"
            tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
            headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
            initialHiddenColumns={initialHiddenColumns || []}
        />
    )

}

export default ProductListTable