import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux";
import ReactTable from "../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import ColumnFilter from "../../../UserManagement/ColumnFilter";
import NumberRangeColumnFilter from "../../../../components/Filters/NumberRangeColumnFilter";
import InputCell from "../../../../components/Table/Cellrenderer/InputCell";
import { REVIEW_TABLE_HEADERS } from "./ReviewAllocationConstants"
import { cloneDeep } from "lodash";
import SetAllModal from "../../../../components/ErrorModal/SetAllModal";
import { getArticleAllocationDetails, updateArticleAllocation } from '../../actions/DemandAction/DemandAction'
import ArticleAllocationTable from "./ArticleAllocationTable"
import { levelFiltersLabelFormatter } from "../../../../utils/filterLevelMapping";
import { numberUSFormatting } from "../../../../utils/formatters/valueFormatters";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const ReviewAllocationTable = (props) => {

    const detailsRef = useRef()

    const { data, setReviewRows, getArticleAllocationDetails, allocation_code, store,
        articleAllocationData, articleAllocationError, articleAllocationLoading,
        updateArticleAllocationLoading, updateArticleAllocationError, updateArticleAllocationSuccess,
        getReviewTableData, updateArticleAllocation } = props

    const [tableData, setTableData] = useState([])
    const [reserveSA, setReserveSA] = useState(null)
    const [showSetAll, setShowSetAll] = useState(false)
    const [showAllocationTable, setShowAllocation] = useState(false)
    const [clickedArticle, setClickedArticle] = useState(null)

    const reviewColumns = [
        {
            sticky: "left",
            Header: REVIEW_TABLE_HEADERS.ARTICLE,
            accessor: 'article',
            Cell: (instance) => (
                <div>
                    <div className="clickable" onClick={() => getAllocationDetails(instance.row?.original?.article)}>{instance.row?.original?.article}</div>
                </div>
            ),
            Filter: ColumnFilter,
        },
        {
            Header: REVIEW_TABLE_HEADERS.DESC,
            accessor: 'color_desc',
            Filter: ColumnFilter,
            width: 300
        },
        {
            Header: REVIEW_TABLE_HEADERS.STYLE_COLOR,
            accessor: 'style_name',
            Filter: ColumnFilter,
            width: 300
        },
        {
            Header: REVIEW_TABLE_HEADERS.GLOBAL_COLOR,
            accessor: 'color',
            Filter: ColumnFilter
        },
        {
            Header: levelFiltersLabelFormatter("level1"),
            accessor: "l1_name",
            Filter: ColumnFilter,
        },
        {
            Header: levelFiltersLabelFormatter("level2"),
            accessor: "l2_name",
            Filter: ColumnFilter,
        },
        {
            Header: levelFiltersLabelFormatter("level3"),
            accessor: "l3_name",
            Filter: ColumnFilter,
        },
        {
            Header: levelFiltersLabelFormatter("level4"),
            accessor: "l4_name",
            Filter: ColumnFilter,
        },
        {
            Header: levelFiltersLabelFormatter("level5"),
            accessor: "l5_name",
            Filter: ColumnFilter,
        },
        {
            Header: REVIEW_TABLE_HEADERS.DROP,
            accessor: 'inv_available',
            Cell: (inst) => numberUSFormatting(inst?.value),
            Filter: NumberRangeColumnFilter,
            filter: 'between'
            // width: 180
        },
        {
            Header: REVIEW_TABLE_HEADERS.STORE_ALLOCATION,
            accessor: 'allocated',
            Cell: (inst) => numberUSFormatting(inst?.value),
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        },
        {
            Header: REVIEW_TABLE_HEADERS.DEMAND,
            accessor: 'allocated_units',
            Cell: (inst) => numberUSFormatting(inst?.value),
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        },
        {
            Header: REVIEW_TABLE_HEADERS.RESERVED,
            accessor: 'reserved',
            Cell: (inst) => numberUSFormatting(inst?.value),
            // Cell: (instance) => (
            //     <InputCell {...instance} style={{ textAlign: "center" }} min={0} max={100} type="number"
            //         changeHandler={(rowIdx, columnId, value) => {
            //             value = value > 100 ? 100 : value
            //             let storeAllocation = Number(((value * instance.data[rowIdx].inv_available) / 100).toFixed(0))
            //             instance.updateMyData(rowIdx, 'allocated', storeAllocation);
            //         }} />
            // ),
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        }

    ]

    const prepFilter = [
        {
            id: 1,
            isRequired: false,
            label: REVIEW_TABLE_HEADERS.RESERVED,
            name: "reserved",
            // className: "setAll__input",
            value: reserveSA,
            onChange: (e) => {
                let val = e.target.value
                if (val < 0) {
                    val = 0
                }
                else if (val > 100) {
                    val = 100
                }
                setReserveSA(val)
            },

            input: true,
            type: "number",
            min: 0
        }]

    useEffect(() => {
        let tableData = cloneDeep(data)
        let res;
        tableData.map(item => {
            res = Number(((item.demand_estimated / item.inv_available) * 100).toFixed(0))
            res = res ? (res >= 100 ? 100 : res) : 0
            item.reserved = res
            item.allocated = Number(((res * item.inv_available) / 100).toFixed(0))

        })
        setTableData(tableData)
    }, [data])

    const setRTInstance = (instance) => {
        setReviewRows(instance.data)
    }

    const handleSetAllOk = () => {
        showSetAll && setShowSetAll(false)
        setTableData((oldData) => {
            let newData = cloneDeep(oldData);
            newData.forEach(item => {
                item.reserved = reserveSA != null ? reserveSA : item.reserved
                item.allocated = Number(((reserveSA * item.inv_available) / 100).toFixed(0))
            })
            return newData;
        });
    }

    const handleSetAllCancel = () => {
        setShowSetAll(false)
    }

    const setAllHandler = () => {
        setShowSetAll(true)
        setReserveSA(null)
    }

    const getAllocationDetails = (article) => {
        setClickedArticle(article)
        setShowAllocation(true)
        setTimeout(() => {
            detailsRef.current.scrollIntoView()
        }, 200)
        let req = {
            article_id: article,
            store_code: store,
            allocation_code
        }
        getArticleAllocationDetails(req)
    }

    const updateArticleAllocatedUnits = (req) => {
        let data = []
        req.forEach(item => {
            data.push({
                article_id: clickedArticle,
                store_code: store,
                allocation_code,
                allocated_total: parseInt(item.allocated_total),
                size: item.size
            })
        })
        updateArticleAllocation({changes: data})
    }

    return (
        <div>
            <ReactTableWithPlugins
                shouldPagination
                data={(tableData ? tableData : [])}
                columns={reviewColumns}
                renderMarkup='TableMarkup'
                keyRT="sortAndSearch"
                getInstanceOnMount={(instance) => {
                    setRTInstance(instance)
                }}
                tableId="newstore_review"
                tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
            />
            {/* <div className="text-center">
                <button
                    className="btn btn-primary"
                    onClick={setAllHandler}
                >
                    Set All
                </button>
            </div> */}
            {
                showSetAll &&
                <SetAllModal filter={prepFilter}
                    handleOk={handleSetAllOk} handleCancel={handleSetAllCancel}
                    handleClose={handleSetAllCancel}
                />
            }
            {
                showAllocationTable &&
                <>
                    <span ref={detailsRef}></span>
                    <ArticleAllocationTable data={articleAllocationData} loading={articleAllocationLoading || updateArticleAllocationLoading}
                        article={clickedArticle} error={articleAllocationError}
                        updateArticleAllocationError = {updateArticleAllocationError}
                        updateArticleAllocationSuccess = {updateArticleAllocationSuccess}
                        updateArticleAllocation = {updateArticleAllocatedUnits}
                        getReviewTableData={getReviewTableData} />
                </>
            }
        </div>
    )
}

const mapStateToProps = ({ demandConstraints }) => {
    return {
        articleAllocationLoading: demandConstraints.articleAllocationLoading,
        articleAllocationData: demandConstraints.articleAllocationData,
        articleAllocationError: demandConstraints.articleAllocationError,
        updateArticleAllocationLoading: demandConstraints.updateArticleAllocationLoading,
        updateArticleAllocationError: demandConstraints.updateArticleAllocationError,
        updateArticleAllocationSuccess: demandConstraints.updateArticleAllocationSuccess
    }
}

const mapDispatchToProps = (dispatch) => ({
    getArticleAllocationDetails: (payload) => dispatch(getArticleAllocationDetails(payload)),
    updateArticleAllocation: (payload) => dispatch(updateArticleAllocation(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ReviewAllocationTable)