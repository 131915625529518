import React, { useState, useEffect, createRef } from "react";
import Modal from "react-awesome-modal";
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import ReactTable from "../../../../components/Table/ReactTable"
import Notification from "../../../../components/Notification/Notifications";
import * as Notify from "../../../../components/Notification/Notifications";
import InputCell from "../../../../components/Table/Cellrenderer/InputCell";
import { isNull } from "lodash-es";
import { connect } from "react-redux";
import PageLoader from "../../../../components/Loader/PageLoader";
import { updateArticleName } from "../../../../utils/filterLevelMapping";
import { editAllocatedUnits, resetEditAllocationError, setDcAvailableError, resetAll } from "../../../Finalize/FinalizeAction";
import "../../NewStoreApprove.css"
import { cloneDeep } from "lodash"

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const EditModal = (props) => {
    const { isRelease, getNewStoreArticleDetails, getNewStoreApproveList } = props
    const [RTInstance, setRTInstance] = useState(null);
    const [data, setData] = useState([])
    const [maxSizeMapping, setMaxSizeMapping] = useState({})
    const [columns, setColumns] = useState([])
    const [request, setRequest] = useState({})
    const [minObj, setMinObj] = useState({})
    const [inputRefs, setInputRefs] = useState([])

    const columnTemplate = React.useMemo(() => [
        // {
        //   Header: "# Packs Allocated",
        //   id: 'allocated_packs',
        //   accessor: (data) => !isNull(data?.["allocated_packs"]) ? data?.["allocated_packs"]: '-',
        //   Cell: (instance) => (
        //    instance.row?.original?.['pack_id'] !== 'eaches' && instance.row?.original?.['pack_id'] !== 'eaches available' ? (
        //        <InputCell {...instance} min={0} type="number"
        //        changeHandler={(rowIdx, columnId, values) => {
        //            let l_allocated_units = Number(instance.row.original['units_in_pack']) * Number(values)
        //         //    let l_available_units = Number(instance.row.original['max_pack']) - Number(values)
        //         //    let l_available_qty = Number(instance.row.original['max_pack_available']) - l_allocated_units

        //            instance.updateMyData(rowIdx, 'allocated_units', l_allocated_units);
        //         //    instance.updateMyData(rowIdx, 'packs_available', l_available_units);
        //         //    instance.updateMyData(rowIdx, 'units_available', l_available_qty);

        //            setRequest((old) => {
        //                return {
        //                    ...old,
        //                    "updated_packs" : {
        //                        ...old.updated_packs,
        //                        [instance.row.original['pack_id']] : values, 
        //                    },
        //                 //    "available_packs" : {
        //                 //        ...old.available_packs,
        //                 //        [instance.row.original['pack_id']] : l_available_units, 
        //                 //    } 
        //                }
        //            })
        //        }} />
        //    ) : (
        //        <div>
        //            {instance.row?.original?.['allocated_packs']}
        //        </div>
        //    )
        //   ),
        //   disableSortBy: true,
        // },
        // {
        //   Header: "# Packs available",
        //   accessor: (data) => !isNull(data?.["packs_available"]) ? data?.["packs_available"]: '-',
        //   disableSortBy: true,
        // },
        {
            Header: `${isRelease ? "Allocated Units" : "Proposed Units"}`,
            accessor: (data) => !isNull(data?.["allocated_units"]) ? data?.["allocated_units"] : '-',
            disableSortBy: true,
        },
        // {
        //   Header: "Available total quantity",
        //   accessor: (data) => !isNull(data?.["units_available"]) ? data?.["units_available"]: '-',
        //   disableSortBy: true,
        // },
    ], [])

    const onSave = () => {
        let { store, article } = props.data
        let req_body = {
            "store_code": store,
            "article": article,
            "isNewStoreSetup": true,
            ...request
        }
        props.editAllocatedUnits(req_body)

    }

    const closeAlert = () => {
        props.setDcAvailableError(false)
        props.closeAlert()
    }

    useEffect(() => {
        if (!props.editAllocatedUnitError || !props.dcAvailableError) {
            props.resetEditAllocationError()
        }
    }, [props.editAllocatedUnitError, props.dcAvailableError])

    useEffect(() => {
        if (props.editAllocatedUnitSuccess) {
            props.setDcAvailableError(false)
            getNewStoreArticleDetails()
            props.closeAlert(true);
            Notify.success("Allocated Units Edited Sucessfully!!")
            getNewStoreApproveList()
        }
    }, [props.editAllocatedUnitSuccess])

    useEffect(() => {
        if (props.editAllocatedUnitError) {
            props.setDcAvailableError(false)
            Notify.error("Error in Editing Allocated Units")
        }
    }, [props.editAllocatedUnitError])

    const checkMin = (min, currentValue, id, index) => {
        if (Number(currentValue) < Number(min)) {
            setMinObj({ ...minObj, [id]: true })
        }
        else {
            setMinObj({ ...minObj, [id]: false })
        }
        setTimeout(() => {
            inputRefs?.[index]?.current?.focus();
        }, 200)
    }

    useEffect(() => {
        if (props.packColumns.length) {
            setInputRefs(inputRefs => (
                Array(props.packColumns.length)?.fill().map((_, i) => inputRefs[i] || createRef())
            ));
        }
    }, [props.packColumns])

    useEffect(() => {
        if (props.packColumns.length && inputRefs.length) {
            let columns = [{
                Header: ' ',
                accessor: 'col_name',
            }]
            props.packColumns.forEach((val, index) => {
                columns.push(
                    {
                        Header: val,
                        accessor: (data) => data[val],
                        Cell: (instance) => (
                            instance.row?.original?.['col_name'] === 'eaches' && !isRelease ? (
                                <InputCell {...instance} min={0} max={props.packData?.[1]?.[val]} type="number"
                                    ref={inputRefs[index]}
                                    className={minObj[val] ? "min-error" : ""}
                                    changeHandler={(rowIdx, columnId, values) => {
                                        let l_allocated_units = Number(instance.row.original['allocated_units']) - Number(instance.data[rowIdx][columnId]) + Number(values);
                                        checkMin(props.packData?.[0]?.[val], values, columnId, index)
                                        let l_available_units = props.max_size_mapping?.[val] - Number(values)
                                        //  let l_available_qty =  Number(instance.data[rowIdx+1]['units_available']) - Number(instance.data[rowIdx+1][columnId]) + l_available_units
                                        instance.updateMyData(rowIdx, 'allocated_units', l_allocated_units);
                                        //  instance.updateMyData(rowIdx+1, val, l_available_units);
                                        //  instance.updateMyData(rowIdx+1, 'units_available', l_available_qty);
                                        setRequest((old) => {
                                            return {
                                                ...old,
                                                "updated_eaches": {
                                                    ...old?.updated_eaches,
                                                    [columnId]: values,
                                                },
                                                // "available_eaches" : {
                                                //     ...old.available_eaches,
                                                //     [columnId] : l_available_units, 
                                                // } 
                                            }
                                        })
                                    }} />
                            ) : (
                                <div>
                                    {instance.row?.original?.[val]}
                                </div>
                            )
                        ),
                        disableSortBy: true,
                    }
                )
            })
            let finalColumns = [...columns, ...columnTemplate]
            setColumns(finalColumns)
        }
        if (props.packData.length) {
            setData(props.packData)
            setMaxSizeMapping(props.max_size_mapping)
        }
    }, [props.packColumns, props.packData, props.max_size_mapping, inputRefs, minObj])

    useEffect(() => {
        return () => {
            props.resetAll()
        }
    })


    return (
        <Modal
            visible={props.showAlert}
            width="80%"
            // height="90%"
            effect="fadeInDown"
            onClickAway={closeAlert}
            alignItems="flexStart"
        >
            {/* <Notification /> */}
            <div className={`modal-container` + `${isRelease ? " mb-5" : ""}`}>
                <div className="modal-header">
                    <h5 className="page-heading text-center m-0">
                        {isRelease ? "" : "Edit"} Proposed Units
                    </h5>
                    <button type="button" className="close" data-dismiss="modal">
                        <span style={{ cursor: "pointer" }}>
                            <i
                                className="fa fa-times text-right m-2 "
                                aria-hidden="true"
                                onClick={closeAlert}
                            />
                        </span>
                    </button>
                </div>
                <div className="modal-middle-container d-flex flex-column">
                    <div className="mx-2 pr-4 pl-4 row justify-content-center mb-4">
                        <div className="detail__div mr-4">
                            <span className="fnt-bold mr-2">Store :</span>
                            <span>{props.data.store}</span>
                        </div>

                        <div className="detail__div mr-4">
                            <span className="fnt-bold mr-2">{updateArticleName(false)} : </span>
                            <span>{props.data.article}</span>
                        </div>
                    </div>
                    <div className="mx-auto p-2 row justify-content-center">
                        <PageLoader loader={props.loading} gridLoader={true}>
                            {(props.packError) ? (<div className="error">Something Went Wrong!!</div>) :
                                <ReactTableWithPlugins
                                    hideColumnsFilter
                                    getInstanceOnMount={(instance) => {
                                        setRTInstance(instance)
                                    }}
                                    style={{ maxWidth: '100%', margin: 'auto' }}
                                    data={data}
                                    columns={columns}
                                    loading={props.loading}
                                    renderMarkup='TableMarkup'
                                    tableId="edit_packs"
                                    tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                                    headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                                />}
                        </PageLoader>
                    </div>
                </div>
                {
                    !isRelease && <div className="font-italic py-2 px-3" style={{ fontSize: "12px" }}> <span className="text-danger mr-2">*</span>Value less than min</div>
                }
                {/* <div className="modal-footer" style={{ padding: "10px" }}> */}
                {
                    !isRelease &&
                    <div className="text-center">
                        <button
                            style={{ margin: '2.5rem 1.5rem 1.5rem' }}
                            onClick={onSave}
                            className="btn btn-primary"
                            disabled={Object.values(minObj)?.includes(true)}
                        >
                            Save
                        </button>

                    </div>
                }
                {/* </div> */}
            </div>
        </Modal>
    )
}

const mapStateToProps = ({ finalize }) => {
    return {
        packData: finalize.packData,
        packColumns: finalize.packColumns,
        max_size_mapping: finalize.max_size_mapping,
        loading: finalize.packLoading,
        packError: finalize.packError,
        editAllocatedUnitSuccess: finalize.editAllocatedUnitSuccess,
        editAllocatedUnitError: finalize.editAllocatedUnitError,
        dcAvailableError: finalize.dcAvailableError
    }
}

const mapDispatchToProps = (dispatch) => ({
    editAllocatedUnits: (reqBody) => dispatch(editAllocatedUnits(reqBody)),
    setDcAvailableError: (payload) => dispatch(setDcAvailableError(payload)),
    resetEditAllocationError: () => dispatch(resetEditAllocationError()),
    resetAll: () => dispatch(resetAll())
})

export default connect(mapStateToProps, mapDispatchToProps)(EditModal)