import React, { useEffect, useState } from "react";
import "../Reporting.css";
import { connect } from "react-redux";
import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import PageLoader from '../../../components/Loader/PageLoader';
// import Filters from "./Filters"
import { getExcessInvData, generateExcel, resetDownloadExcel } from "./Actions/ExcessAction"
import ExportFile from "../../../components/ExportFile/ExportFile";
import ExcessWeekFilter from "./ExcessWeekFilter"
import { isEmpty, cloneDeep, reverse } from "lodash";
import ColumnFilterServerSide from "../../../components/Filters/ColumnFilterServerSide";
import NumberRangeColumnFilterServerSide from "../../../components/Filters/NumberRangeColumnFilterServerSide";
import { addDollar, numberFormatting, numberUSFormatting } from "../../../utils/formatters/valueFormatters";
import { updateArticleName } from "../../../utils/filterLevelMapping";
import DownloadProgressBar from '../../../components/DownloadProgressBar/DownloadProgressBar';
import { EXCESS_INV_COLUMNS_FORMATTING } from "../../../components/ExportFile/ExportExcelConstants";

const pageSize = 10
const rowCount = 100

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const ExcessTable = (props) => {

    const { downloadExcelData, downloadNextIndex, downloadExcelError, downloadTotalCount, resetDownloadExcel } = props

    const [fiscalWeekOptions, setFiscalWeekOptions] = useState([])
    const [totalDetails, setTotalDetail] = useState({ units: 0, cost: 0 })
    const [isFilterSelected, setIsFilterSelected] = useState(false)
    const [filters, setFilters] = useState({})
    const [excessData, setExcessData] = useState([])
    const [RTinstance, setRTinstance] = useState([])
    const [initialIndex, setInitialIndex] = useState(0)
    const [prevIndex, setPrevIndex] = useState(0)
    const [nextIndex, setNextIndex] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [isFirstCall, setisFirstCall] = useState(true)
    const [searchTermReq, setSearchTermReq] = useState({})
    const [sortReq, setSortReq] = useState({})
    const [selectedWeek, setWeek] = useState("")
    const [downloadExcel, setDownloadExcel] = useState(false)
    const [latestWeek, setLatestWeek] = useState(null)
    const [nextIndexExcel, setNextIndexExcel] = useState(0)
    const [totalCountExcel, setTotalCountExcel] = useState(0)
    const [excelData, setExcelData] = useState([])

    const resetExcelDetails = () => {
        setTotalCountExcel(0);
        setExcelData([]);
        setNextIndexExcel(0);
    }
    const [downloadExcelLoading, setDownloadLoading] = useState(false)

    const columns = [
        {
            Header: "Fiscal Week",
            accessor: "fiscal_week",
            disableFilters: true,
            sticky: "left"
        },
        {
            Header: "Fiscal Year",
            accessor: "fiscal_year",
            disableFilters: true,
            sticky: "left"
        },
        {
            Header: "Store Number",
            accessor: "store_code",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.store_code?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
            sticky: "left"
        },
        {
            sticky:"left",
            Header: "Store Name",
            accessor: "store_name",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.store_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
            width: 250,
        },
        {
            Header: `${updateArticleName(false)} ID`,
            accessor: "article",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.article?.search} placeholder={"Search in bulk by ','"} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "array", ",")} />
            ),
            sticky: "left",
            filter: 'bulkFilterCommaSeperated',
        },
        {
            Header: "Style ID",
            accessor: 'style_code',
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.style_code?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
                  
            // sticky: "left"
        },
        {
            Header: "Color ID",
            accessor: 'color_code',
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.color_code?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
            // sticky: "left"
        },
        // {
        //     Header: "Store Name",
        //     accessor: "store_name",
        //     Filter: (instance) => (
        //         <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.store_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
        //     ),
        //     width: 250,
        // },
       
        {
            Header: "Style Description",
            accessor: "style_name",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.style_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
            width: 300
        },
        {
            Header: "Style Color",
            accessor: "color_desc",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.color_desc?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
            width: 200
        },
        {
            Header: "Color",
            accessor: "color",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.color?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),

        },
        {
            Header: "Inventory Closing Balance",
            accessor: "inv",
            Cell: (inst) => numberUSFormatting(inst?.value),
            Filter: (instance) => (
                <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.inv?.value?.min} maxSearchTermValue={searchTermReq?.inv?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            ),
            filter: 'between',
        },
        {
            Header: "ROS",
            accessor: "ros",
            Filter: (instance) => (
                <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.ros?.value?.min} maxSearchTermValue={searchTermReq?.ros?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            ),
            filter: 'between',
        },
        {
            Header: "Units Sold",
            accessor: "units",
            Cell: (inst) => numberUSFormatting(inst?.value),
            Filter: (instance) => (
                <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.units?.value?.min} maxSearchTermValue={searchTermReq?.units?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            ),
            filter: 'between',
        },
        {
            Header: "WOS",
            accessor: "wos_predicted",
            Filter: (instance) => (
                <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.wos_predicted?.value?.min} maxSearchTermValue={searchTermReq?.wos_predicted?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            ),
            filter: 'between',
        },
        {
            Header: "WOS Threshold",
            accessor: "target_wos",
            Filter: (instance) => (
                <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.target_wos?.value?.min} maxSearchTermValue={searchTermReq?.target_wos?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            ),
            filter: 'between',
        },
        {
            Header: "Excess Inventory (Units)",
            accessor: "excess_inv",
            Cell: (inst) => numberUSFormatting(inst?.value),
            Filter: (instance) => (
                <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.excess_inv?.value?.min} maxSearchTermValue={searchTermReq?.excess_inv?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            ),
            filter: 'between',
        },
        {
            Header: "Excess Inventory (Cost)",
            accessor: "excess_inv_cost",
            Filter: (instance) => (
                <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.excess_inv_cost?.value?.min} maxSearchTermValue={searchTermReq?.excess_inv_cost?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            ),
            Cell: (inst) => (
                <div>
                    {inst.value || inst.value === 0
                        ? addDollar(numberUSFormatting(inst.value, 2))
                        : null}
                </div>),
            filter: 'between',
        },
    ]

    const fecthDataWithSearchTerm = (p_term, p_id, p_type, split_type) => {
        if (p_type === "array") {
            if(split_type) {
              p_term = p_term.replace(/\s*,\s*/g, ",")
            }
            setSearchTermReq((old) => {
              let l_searchTermReq = cloneDeep(old)
              var id = p_term.split(split_type ? split_type : " ")
              if (p_term?.trim() == 0) {
                l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
              }
              else {
      
                l_searchTermReq[p_id] = {
                  "type": p_type,
                  "values": id,
                  "search": p_term
                }
              }
              return {
                ...l_searchTermReq
              }
            })
          }
        else if (Array.isArray(p_term)) {
            setSearchTermReq((old) => {
                let l_searchTermReq = cloneDeep(old)
                let min = p_term[0] == "" ? null : p_term[0]
                let max = p_term[1] == "" ? null : p_term[1]
                if (min == null && max == null) {
                    l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
                }
                else {
                    l_searchTermReq[p_id] = {
                        "type": p_type,
                        "value": {
                            "min": min,
                            "max": max,
                        }
                    }
                }
                return {
                    ...l_searchTermReq,
                }
            })
        }
        else {
            setSearchTermReq((old) => {
                let l_searchTermReq = cloneDeep(old)
                if (p_term?.trim() == 0) {
                    l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
                }
                else {
                    l_searchTermReq[p_id] = {
                        "type": p_type,
                        "value": p_term
                    }
                }
                return {
                    ...l_searchTermReq,
                }
            })
        }
        isFirstCall && setisFirstCall(false)
    }

    const fecthDataWithSorting = (p_sort) => {
        if (!isEmpty(sortReq))
            isFirstCall && setisFirstCall(false)
        setSortReq(p_sort)
    }

    useEffect(() => {
        if ((!isEmpty(searchTermReq) || !isFirstCall || !isEmpty(sortReq)) && !isEmpty(filters)) {
            setExcessData([])
            setRTinstance(null)
            setInitialIndex(0)
            setPrevIndex(0)
            props.getExcessInvData({ ...filters, row_index: 0, row_count: rowCount, searchColumns: searchTermReq, sortColumn: sortReq?.[0] })
        }
    }, [searchTermReq, sortReq])


    useEffect(() => {
        setDownloadExcel(false)
        // if(selectedWeek) {
        //     // setRTinstance(null)
        //     setExcessData(props.tableData)
        // }
        if (props.tableData?.length > 0) {
            let data = []
            if (RTinstance && RTinstance.data) {
                data = [...RTinstance.data, ...props.tableData]
                setExcessData([...RTinstance.data, ...props.tableData]);
            }
            else {
                data = [...props.tableData]
                setExcessData([...props.tableData]);
            }
            // calcTotal(data)
        }
        else {
            setExcessData([])
            setRTinstance(null)
        }

    }, [props.tableData])

    useEffect(() => {
        if (props.weeks.length && isFilterSelected) {
            let fiscalWeek;
            fiscalWeek = [...new Set(props.yearData.map((item) => item))]
              .reverse()
              .map((element) => ({
                value: element.week,
                label: `${element.week} (${element.year})`,
              }));
            setLatestWeek(fiscalWeek[0]);
            setFiscalWeekOptions(fiscalWeek);
            getExcessData(fiscalWeek[0].value)
        }
    }, [props.weeks])

    const sortFiscalWeek = (a, b) => {
        return parseInt(b.value) - parseInt(a.value)
    }

    useEffect(() => {
        if (!isEmpty(props.sum)) {
            const { inv_cost_sum, inv_sum } = props.sum
            setTotalDetail({ units: inv_sum, cost: inv_cost_sum })
        }
    }, [props.sum])

    const handleWeekSelect = (weekValue) => {
        const yearData = props.yearData.find(item => item.week == weekValue)?.year;
        setLatestWeek({ value: weekValue, label: `${weekValue} (${yearData})` })
        setSearchTermReq({})
        setSortReq({})
        setExcessData([])
        setRTinstance(null)
        setInitialIndex(0)
        setPrevIndex(0)
        setTotalCount(0);
        setNextIndex(0);
        // resetExcelDetails();
        setWeek(weekValue)
        setIsFilterSelected(false)
        let req = filters
        req.selected_fw = weekValue
        props.getExcessInvData({ ...req, selected_fy: yearData, row_index: 0, row_count: rowCount })
    }

    useEffect(() => {
        setIsFilterSelected(true)
    }, [props.filters])

    const getExcessData = (week) => {
        const yearData = props.yearData.find(item => item.week == week)?.year;
        setDownloadExcel(false)
        if (!isEmpty(props.filters) && props.call && props.weeks.length) {
            let req = { ...props.filters, selected_fw: week }

            setWeek(week)
            setFilters(req)
            setPrevIndex(0)
            setInitialIndex(0)
            setRTinstance([])
            setExcessData([])
            props.getExcessInvData({ ...req, selected_fy: yearData, row_index: 0, row_count: 100 })
            setSearchTermReq({})
            setSortReq({})
            setisFirstCall(true)
        }
    }

    const fetchData = (index) => {
        if (prevIndex >= index || nextIndex >= totalCount)
            return;
        // if (prevIndex >= index || index < nextIndex || nextIndex >= totalCount)
        setPrevIndex(index)
        setInitialIndex(Math.floor(nextIndex / pageSize - 1))
        props.getExcessInvData({ ...filters, row_index: nextIndex, row_count: rowCount, searchColumns: searchTermReq, sortColumn: sortReq?.[0] })
    }


    useEffect(() => {
        setTotalCount(props.totalCount)
        setNextIndex(props.nextIndex)
    }, [props.totalCount, props.nextIndex])

    const generateExcel = () => {
        const yearData = props.yearData.find(item => item.week == selectedWeek)?.year;
        resetExcelDetails();
        setExcelData(excessData)
        setDownloadLoading(true)
        setDownloadExcel(false)
        props.generateExcel({ ...filters, selected_fw: selectedWeek, selected_fy: yearData, row_index: nextIndex, row_count: 500,  searchColumns: searchTermReq, sortColumn: sortReq?.[0] })
    }

    const getExcelData = () => {
        const yearData = props.yearData.find(item => item.week == selectedWeek)?.year;
        let req = filters
        if (selectedWeek) {
            req.selected_fw = selectedWeek
            req[`selected_fy`] = yearData
        }
        req["row_index"] = downloadNextIndex
        req["row_count"] = 500
        req["searchColumns"] = searchTermReq
        req["sortColumn"] = sortReq

        props.generateExcel(req)
    }

    // useEffect(() => {
    //     if (props.excelData.length) {
    //         setDownloadExcel(true)
    //     }
    // }, [props.excelData])

    useEffect(() => {
        if (props.excelData?.length > 0) {
            if (!isEmpty(excelData)) {
                setExcelData([...excelData, ...props.excelData]);
            }
            else {
                setExcelData([...props.excelData]);
            }
        }
        else {
            setExcelData([])
        }
        setTotalCountExcel(props.totalCountExcel)
        setNextIndexExcel(props.nextIndexExcel)
    }, [props.excelData,props.totalCountExcel, props.nextIndexExcel])
    
    useEffect(() => {
        if (downloadExcelData.length) {
            setExcelData(excelData.concat(downloadExcelData))
            // setDownloadExcel(true)
        }
    }, [downloadExcelData])

    useEffect(() => {
        if (downloadExcelLoading && downloadNextIndex < downloadTotalCount) {
            getExcelData()
        }
        else if (downloadNextIndex > downloadTotalCount) {
            formatExcelData(excelData.concat(downloadExcelData)) 
        }
    }, [downloadNextIndex])

    const formatExcelData = (downloadData) => {
        let data = []
        let obj = {}
        downloadData.forEach(item => {
            obj = {}
            obj["Store Number"] = item.store_code
            obj["Store Name"] = item.store_name
            obj["Fiscal Week"] = item.fiscal_week
            obj[`${updateArticleName(false)} ID`] = item.article
            obj["Style ID"] = item.style_code
            obj["Color ID"] = item.color_code
            obj["Style Description"] = item.style_name
            obj["Style Color"] = item.color_desc
            obj["Color"] = item.color
            obj["Inventory Closing Balance"] = item.inv
            obj["ROS"] = item.ros
            obj["Units Sold"] = item.units
            obj["WOS"] = item.wos_predicted
            obj["WOS Threshold"] = item.target_wos
            obj["Excess Inventory (Units)"] = item.excess_inv
            obj["Excess Inventory (Cost)"] = item.excess_inv_cost
            data.push(obj)
        })
        setExcelData(data)
        setTimeout(() => {
            setDownloadLoading(false)
            setDownloadExcel(true)
        }, 1000)
        
        resetDownloadExcel()
    }

    useEffect(() => {
        if (downloadExcelError) {
            setDownloadLoading(false)
            // Notify.error(downloadExcelError)
            resetDownloadExcel()
        }
    }, [downloadExcelError])

    const closeDownloadModal = () => {
        setDownloadLoading(false)
        resetDownloadExcel()
    }


    return (
        <PageLoader loader={props.tableDataLoading || props.graphLoading}>
            <DownloadProgressBar nextIndex={excelData?.length} totalCount={downloadTotalCount || totalCount} showModal={downloadExcelLoading}
                closeModal={closeDownloadModal} />
            {
                props.tableDataSuccess ?
                    <div>

                        <div className="d-flex justify-content-between mr-4 mb-2">
                            <div className="mr-4 row ml-0">
                                <ExcessWeekFilter latestWeek={latestWeek} fiscalWeekOptions={fiscalWeekOptions} handleWeekSelect={handleWeekSelect} />
                                <span className="detail__container ml-4">
                                    <h5 className="detail__heading">Total Excess Inv Units: {numberUSFormatting(totalDetails?.units)}</h5>
                                    <h5 className="detail__heading">Total Excess Inv Cost: $ {numberUSFormatting(totalDetails?.cost?.toFixed(0))}</h5>
                                </span>
                            </div>

                            <ExportFile downloadExcelData={downloadExcel} callFunc={generateExcel} text={"Excel"} data={excelData} fileName={`excess_inv_report_${new Date().getTime()}`} type={1} formateXLSX={true} excelColumnsToBeFormatted={EXCESS_INV_COLUMNS_FORMATTING}  />
                        </div>

                        <ReactTableWithPlugins
                            shouldPagination
                            hideDropdown
                            totalRecordsLen={excessData?.length}
                            totalCount={totalCount}
                            data={excessData ? excessData : []}
                            columns={columns}
                            renderMarkup='TableMarkup'
                            keyRT="sortAndSearch"
                            fetchData={(data) => fetchData(data)}
                            initialPageIndex={initialIndex}
                            pageSize={pageSize}
                            getInstanceOnMount={(instance) => {
                                setRTinstance(instance)
                            }}
                            getManualSortByOptions={(instance) => {
                                fecthDataWithSorting(instance)
                            }}
                            manualSortBy={true}
                            tableId="excess_inv"
                            tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                            headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                        />
                    </div>
                    : (props.tableDataError ? <div className="error">{props.tableDataError}</div> : null)

            }
        </PageLoader>
    )
}

const mapStateToProps = ({ excessReport }) => {
    return {
        tableData: excessReport.excessTableData,
        tableDataLoading: excessReport.tableDataLoading,
        graphLoading: excessReport.graphDataLoading,
        tableDataError: excessReport.tableDataError,
        tableDataSuccess: excessReport.tableDataSuccess,
        downloadExcelData: excessReport.excelData,
        nextIndex: excessReport.nextIndex,
        weeks: excessReport.weeks,
        totalCount: excessReport.totalCount,
        sum: excessReport.sum,
        totalCountExcel: excessReport.totalCountExcel,
        nextIndexExcel: excessReport.nextIndexExcel,
        downloadNextIndex: excessReport.downloadNextIndex,
        downloadTotalCount: excessReport.downloadTotalCount,
        downloadExcelError: excessReport.downloadExcelError,
        yearData: excessReport.yearData,
    }
};

const mapDispatchToProps = (dispatch) => ({
    getExcessInvData: (payload) => dispatch(getExcessInvData(payload)),
    generateExcel: (payload) => dispatch(generateExcel(payload)),
    resetDownloadExcel: () => dispatch(resetDownloadExcel()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExcessTable);
