import React, { useEffect, useState } from 'react';
import "./Storedetailsconfig.css";
import { isEmpty } from 'lodash';
import Select from '../../../../components/Filters/SingleSelect';

const DcLeadTimeCard = (props) => {
    const { card, dcDetails, saveCard, index } = props

    const [leadTime, setLeadTime] = useState()
    const [selectedDC, setSelectedDc] = useState([])

    useEffect(() => {
        if (!isEmpty(card)) {
            setLeadTime(card?.leadTime)
            setSelectedDc(card?.dc)
        }
    }, [card])

    const updateLeadTime = (value) => {
        setLeadTime(value < 1 ? 1 : value)
        if (value > 0 && !isEmpty(selectedDC)) {
            saveCard(true, { selectedDC, leadTime: value }, index)
        }
    }

    const onDcChange = (val) => {
        setSelectedDc(val);
        if (leadTime > 0 && !isEmpty(val)) {
            saveCard(true, { selectedDC: val, leadTime }, index)
        }
    };


    return (
        <div className="filter-card__container filter-set ml-0" style={{ width: "20%" }}>
            <div className="p-field p-grid mt-4 flex-column">
                <label className="non-editable-labels p-col-fixed label-35">
                    DC
                </label>
                <div>
                    <Select
                        id="NEW_STORE_SELECTOR"
                        name="seasoncode"
                        placeholder="Select DC"
                        className="width-50"
                        value={selectedDC}
                        onChange={onDcChange}
                        options={dcDetails.map(item => { return { label: item.dc_name, value: item.dc } }) || []}
                        disabled={localStorage.getItem("group_id") != 1}
                    />
                </div>
            </div>
            <div className="p-field p-grid flex-column">
                <label className="non-editable-labels p-col-fixed w-auto">
                    Lead Time(In days)
                </label>
                <div className="p-col" style={{ marginTop: "1%" }}>
                    <input
                        id="NEW_STORE_SELECTOR"
                        // className="form-input form-control"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                        min={1}
                        className="p-col w-100 form-control ml-0"
                        type={"number"}
                        onChange={(e) => { updateLeadTime(e?.target?.value) }}
                        value={leadTime}
                        placeholder="Lead Time"
                        disabled={localStorage.getItem("group_id") != 1}
                    />
                </div>
            </div>
        </div>
    )
}

export default DcLeadTimeCard