import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { cloneDeep } from "lodash";
import { storeConfigInitialSate } from './initialState';
import * as storeConfigActionTypes from '../../actions/StoreDetailsConfigActions/actionTypes';

const storeConfigReducer = {
    newStoreDetailConfig: handleActions(
        {
            [storeConfigActionTypes.GET_STORE_CONFIG_DETAILS_REQUEST]: (state) => {
                return immutable(state, {
                    isStoreConfigDetailsLoading: { $set: true },
                    isStoreConfigDetailsFailed: { $set: false },
                    isStoreConfigDetailsSuccess: { $set: false },
                });
            },
            [storeConfigActionTypes.GET_STORE_CONFIG_DETAILS_FAILED]: (state) => {
                return immutable(state, {
                    isStoreConfigDetailsLoading: { $set: false },
                    isStoreConfigDetailsFailed: { $set: true },
                    isStoreConfigDetailsSuccess: { $set: false },
                });
            },
            [storeConfigActionTypes.GET_STORE_CONFIG_DETAILS_SUCCESS]: (state, { response }) => {
                return immutable(state, {
                    isStoreConfigDetailsLoading: { $set: false },
                    isStoreConfigDetailsFailed: { $set: false },
                    isStoreConfigDetailsSuccess: { $set: true },
                    newStoreDetailsData: { $set: response.new_store },
                    newStoreOptionsData: { $set: response.new_store_options },
                    sisterStoreDetailsData: { $set: response.sister_store },
                    sisterStoreOptionsData: { $set: response.sister_store_options },
                    storeGroup: {$set: response.store_group}
                });
            },
            [storeConfigActionTypes.SET_SELECTED_NEW_STORE_DETAIL]: (state, { payload }) => {
                // const { open_date } = payload[0];
                return immutable(state, {
                    selectedNewStore: { $set: payload },
                    newStoreOpeningDate: { $set: payload[0]?.open_date },
                });
            },
            [storeConfigActionTypes.SET_SELECTED_NEW_STORE_CODE]: (state, { payload }) => {
                return immutable(state, {
                    selectedNewStoreCode: { $set: payload },
                });
            },
            [storeConfigActionTypes.SET_SELECTED_SISTER_STORE_DETAIL]: (state, { payload }) => {
                return immutable(state, {
                    selectedSisterStore: { $set: payload },
                    isDcSourceDataLoading: { $set: false },
                    isDcSourceDataFailed: { $set: false },
                    isDcSourceDataSuccess: { $set: false },
                });
            },
            [storeConfigActionTypes.SET_SELECTED_SISTER_STORE_CODE]: (state, { payload }) => {
                return immutable(state, {
                    selectedSisterStoreCode: { $set: payload },
                });
            },
            [storeConfigActionTypes.GET_DC_SOURCE_DATA_REQUEST]: (state, { payload }) => {
                return immutable(state, {
                    isDcSourceDataLoading: { $set: true },
                    isDcSourceDataFailed: { $set: false },
                    isDcSourceDataSuccess: { $set: false },
                });
            },
            [storeConfigActionTypes.GET_DC_SOURCE_DATA_FAILED]: (state) => {
                return immutable(state, {
                    isDcSourceDataLoading: { $set: false },
                    isDcSourceDataFailed: { $set: true },
                    isDcSourceDataSuccess: { $set: false },
                });
            },
            [storeConfigActionTypes.GET_DC_SOURCE_DATA_SUCCESS]: (state, { response }) => {
                return immutable(state, {
                    isDcSourceDataLoading: { $set: false },
                    isDcSourceDataFailed: { $set: false },
                    isDcSourceDataSuccess: { $set: true },
                    dcSourceData: { $set: response.dc_details },
                    dcSourceRowsSelected: { $set: response.selectedRows },
                    dcSourceSelectedRowsData: { $set: [] },
                    editedDcSourceRowsTime: { $set: [] },
                });
            },
            [storeConfigActionTypes.SET_SELECTED_DC_ROWS_DETAIL]: (state, { payload }) => {
                let updatedDcSourceTime = cloneDeep(state?.editedDcSourceRowsTime);
                const keys = cloneDeep(payload).map(row => row.dc);
                updatedDcSourceTime = updatedDcSourceTime.filter((item) => keys.includes(item.dc_code));

                let updatedDcSrcData = cloneDeep(state.dcSourceData);
                updatedDcSourceTime.forEach((updateLead) => {
                    updatedDcSrcData.forEach(dcData => {
                        if (dcData?.dc === updateLead?.dc_code) {
                            dcData['transit_time'] = updateLead['lead_time'];
                        }
                    });
                })

                return immutable(state, {
                    dcSourceSelectedRowsData: { $set: payload },
                    editedDcSourceRowsTime: { $set: updatedDcSourceTime },
                    dcSourceData: { $set: updatedDcSrcData },
                });
            },
            [storeConfigActionTypes.SET_DC_ROWS_SELECTED]: (state, { payload }) => {
                return immutable(state, {
                    dcSourceRowsSelected: { $set: payload },
                });
            },
            [storeConfigActionTypes.RESET_STORE_DETAILS_CONFIG]: (state) => {
                return immutable(state, {
                    isStoreConfigDetailsLoading: { $set: false },
                    isStoreConfigDetailsFailed: { $set: false },
                    isStoreConfigDetailsSuccess: { $set: false },
                    newStoreDetailsData: { $set: [] },
                    newStoreOptionsData: { $set: [] },
                    selectedNewStore: { $set: {} },
                    selectedNewStoreCode: { $set: {} },
                    newStoreOpeningDate: { $set: null },
                    sisterStoreDetailsData: { $set: [] },
                    sisterStoreOptionsData: { $set: [] },
                    selectedSisterStore: { $set: [] },
                    selectedSisterStoreCode: { $set: {} },
                    isDcSourceDataLoading: { $set: false },
                    isDcSourceDataFailed: { $set: false },
                    isDcSourceDataSuccess: { $set: false },
                    dcSourceData: { $set: [] },
                    dcSourceRowsSelected: { $set: {} },
                    dcSourceSelectedRowsData: { $set: [] },
                    isUpdateDcLeadTimeRequested: { $set: false },
                    isUpdateDcLeadTimeFailed: { $set: false },
                    isUpdateDcLeadTimeSuccess: { $set: false },
                    editedDcSourceRowsTime: { $set: [] },
                    editModeDetails: { $set: { isEditMode: false, } },
                });
            },
            [storeConfigActionTypes.EDIT_OPENING_DATE]: (state, { payload }) => {
                return immutable(state, {
                    newStoreOpeningDate: { $set: payload },
                });
            },
            [storeConfigActionTypes.EDIT_DC_SOURCE_LEAD_TIME]: (state, { payload }) => {
                let updatedDcSourceTime = cloneDeep(state.editedDcSourceRowsTime);
                if (updatedDcSourceTime.length > 0) {
                    let index = updatedDcSourceTime.findIndex(el => el.dc_code === payload?.dc_code);
                    if (index >= 0) {
                        updatedDcSourceTime[index]['lead_time'] = payload?.['lead_time'];
                    } else {
                        updatedDcSourceTime.push(payload);
                    }
                } else {
                    updatedDcSourceTime.push(payload);
                }

                return immutable(state, {
                    editedDcSourceRowsTime: { $set: updatedDcSourceTime },
                });
            },
            [storeConfigActionTypes.UPDATE_DC_SOURCE_LEAD_TIME_REQ]: (state) => {
                return immutable(state, {
                    isUpdateDcLeadTimeRequested: { $set: true },
                    isUpdateDcLeadTimeFailed: { $set: false },
                    isUpdateDcLeadTimeSuccess: { $set: false },
                });
            },
            [storeConfigActionTypes.UPDATE_DC_SOURCE_LEAD_TIME_FAILED]: (state) => {
                return immutable(state, {
                    isUpdateDcLeadTimeRequested: { $set: false },
                    isUpdateDcLeadTimeFailed: { $set: true },
                    isUpdateDcLeadTimeSuccess: { $set: false },
                });
            },
            [storeConfigActionTypes.UPDATE_DC_SOURCE_LEAD_TIME_SUCCESS]: (state) => {
                return immutable(state, {
                    isUpdateDcLeadTimeRequested: { $set: false },
                    isUpdateDcLeadTimeFailed: { $set: false },
                    isUpdateDcLeadTimeSuccess: { $set: true },
                });
            },
            [storeConfigActionTypes.SET_EDIT_MODE_DETAILS]: (state, { payload }) => {
                return immutable(state, {
                    editModeDetails: { $set: payload },
                });
            },
        },
        storeConfigInitialSate
    ),
};

export default storeConfigReducer;