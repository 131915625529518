import React, { useEffect, useRef } from "react"
import { connect } from "react-redux";
import { getSisterStoreFilters, saveSisterStoreFilters, saveSelecetedSisterStores, saveDCSource,
setSisterStoreMapping } from "../../actions/DCConfigAction/DCConfigAction"
import { getDcSourceDetailsData } from "../../actions/StoreDetailsConfigActions/StoreConfigActions"
import { useState } from "react";
import * as Notify from "../../../../components/Notification/Notifications";
import FilterCard from "./FilterCard";
import DetailsTable from "./DetailsTable";
import { cloneDeep } from "lodash";
import PageLoader from "../../../../components/Loader/PageLoader";

const DCConfig = (props) => {
    const { getSisterStoreFilters, sisterStores, dcDetails, selectedStore, saveSisterStoreFilters, selectedSisterStores, editMode,
        sisterStoreFilters, saveSelecetedSisterStores, filterData, setDcSrcRowsSelection, saveDCSource, leadTimeDcMapping,
        setIsEditMode, saveDetailsLoading, setSisterStoreMapping } = props
    const [showTable, setShowTable] = useState(false)
    const [allowNextCard, setAllowNextCard] = useState(false)
    // const [filterCards, setFilterCards] = useState([1])
    const [filterValues, setFilterValues] = useState([{}])
    const [sisterStoreData, setSisterStoreData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (editMode) {
            setLoading(true)
            setAllowNextCard(true)
        }
        let req = {
            country: selectedStore?.country,
            channel: selectedStore?.channel
        }
        getSisterStoreFilters(editMode ? { ...req, store_code: selectedStore?.store_code } : req)
        getDcSourceDetailsData({ store_code: selectedStore })
    }, [])

    useEffect(() => {
        if (sisterStoreFilters.length) {
            setFilterValues(sisterStoreFilters)
            setAllowNextCard(true)
            setShowTable(true)
            // if(allowNextCard) {
            applyFilters(false, true)
            // }
        }
        // else if (filterData?.existing_filters?.length) {
        //     setFilterValues(filterData.existing_filters)
        //     setTimeout(() => {
        //         setLoading(false)
        //         applyFilters(true)
        //     }, 1000)
        // }
    }, [sisterStoreFilters])

    const saveFilterValues = (status, values, index) => {
        if (status) {
            let preFilterValues = cloneDeep(filterValues)
            preFilterValues[index] = values
            setFilterValues(preFilterValues)
            setAllowNextCard(true)
        }
        else {
            setAllowNextCard(false)
        }
    }

    const addFilterSet = () => {
        if (!allowNextCard) {
            Notify.error("Please Select Mandatory Options!!")
        }
        else {
            setFilterValues([...filterValues, {}])
            setAllowNextCard(false)
        }
    }

    const AlwaysScrollToBottom = () => {
        const elementRef = useRef();
        useEffect(() => elementRef.current.scrollIntoView({ behaviour: "smooth" }));
        return <div ref={elementRef} />;
    };

    const elementRef = useRef();
    const tableRef = useRef()

    const handleApplyFilters = () => {
        setIsEditMode(false)
        if (allowNextCard) {
            applyFilters(true, true)
        }
        else {
            Notify.error("Please Select Mandatory Options!!")
        }
    }

    const applyFilters = (status, type) => {
        if ((!sisterStoreFilters.length || !allowNextCard) && !type) {
            Notify.error("Please Select Mandatory Options!!")
        }
        else {
            setAllowNextCard(true)
            let departmentObj = {}
            let sisterStoreObj = {}
            if (status) {
                saveSisterStoreFilters(filterValues)
            }
            let filters = filterValues?.[0].departmentValue ? filterValues : sisterStoreFilters
            // filters.forEach(item => {
            //     sisterStoreObj[item.sisterStoreValue?.value] = { ...sisterStores?.filter(store => store.store_name === item.sisterStoreValue?.value)[0], department: item.departmentValue?.map(dep => dep.value), seasoncode: item.seasonCode?.map(season => season.value) }
            //     item.departmentValue.forEach(dep => {
            //         departmentObj[dep.value] = item.sisterStoreValue?.value
            //     })
            // })
            let sisterStoresData = []
            // for (let store in sisterStoreObj) {
            //     if (Object.values(departmentObj).includes(store)) {
            //         let department = []
            //         for (let d in departmentObj) {
            //             if (departmentObj[d] === store) {
            //                 department.push(d)
            //             }
            //         }
            //         sisterStoresData.push({ ...sisterStoreObj[sisterStoreObj[store].store_name], department })
            //     }
            // }
            departmentObj = {}

            filters.forEach(item => {
                sisterStoreObj[item.sisterStoreValue?.value] = { ...sisterStores?.filter(store => store.store_code === item.sisterStoreValue?.value)[0], department: item.departmentValue?.map(dep => dep.value), seasoncode: item.seasonCode?.map(season => season.value) }
                item.departmentValue.forEach(dep => {
                    if(dep.value !== "*") {
                        item.seasonCode.forEach(season => {
                            if(season.value !== "*") {
                                if(departmentObj[dep.value]) {
                                    departmentObj[dep.value][season.value] = [item.sisterStoreValue?.value]
                                }
                                else {
                                    departmentObj[dep.value] = {[season.value]: [item.sisterStoreValue?.value]}
                                }
                            }
                        })
                    }
                })
            })
            setSisterStoreData(Object.values(sisterStoreObj))
            setSelectedSisterStores(Object.values(sisterStoreObj))
            setSisterStoreMapping(departmentObj)
            setLoading(false)
            setShowTable(true)
            // setTimeout(() => {
            //     tableRef.current.scrollIntoView({ behaviour: "smooth" })
            // }, 1000)
        }
    }
    const scrollBottom = () => {
        elementRef.current.scrollIntoView({ behaviour: "smooth" })
    }

    const resetFilters = () => {
        setShowTable(false)
        setFilterValues([{}])
        saveSisterStoreFilters([])
        setAllowNextCard(false)
    }

    const closeCard = (index) => {
        // setShowTable(false)
        let filters = cloneDeep(filterValues)
        filters.splice(index, 1)
        setFilterValues(filters)
        // saveSisterStoreFilters(filters)
        setAllowNextCard(true)
    }

    const setSelectedSisterStores = (data) => {
        let sister_stores = {}
        data?.forEach(row => {
            row?.department?.forEach(item => {
                if (item !== "*") {
                    if (sister_stores[item]) {
                        sister_stores[item].push(row?.store_code)
                    }
                    else {
                        sister_stores[item] = [row?.store_code]
                    }
                }
            })
        })
        saveSelecetedSisterStores(sister_stores)
    }

    return (
        <PageLoader loader={loading || saveDetailsLoading}>
            <div className="container__wrapper p-0">
                {/* <div className="filters-div"> */}
                <section className="section mt-4 m-0 filter-set-section">
                    <div className="card__wrapper filter-set-container">
                        {filterValues.map((filter, index) =>
                            <FilterCard onFocusCall={scrollBottom} setAllowNextCard={saveFilterValues} index={index}
                                filterValues={filter} closeCard={closeCard} />
                        )}
                        <div ref={elementRef} />
                        <button className="btn-primary-rounded" onClick={addFilterSet}>
                            <i
                                className="fa fa-plus"
                                title="Create Allocation"
                                aria-hidden="true"
                            ></i>
                        </button>
                        {/* <AlwaysScrollToBottom /> */}
                    </div>

                </section>
                <div className="row justify-content-end sister-store_filter_buttons">
                    <button
                        onClick={handleApplyFilters}
                        className="btn btn-primary filter-constraints-mode"
                        title="Apply filters"
                    >
                        <i className="fa fa-filter mr-2" aria-hidden="true"></i>Save
                    </button>
                    <button
                        onClick={resetFilters}
                        className="btn undo-constraints-mode"
                        title="Reset filters"
                    >
                        <i className="fa fa-undo mr-2"></i>Reset
                    </button>
                </div>
                {/* </div> */}
                {
                    showTable &&
                    <DetailsTable data={{ sisterStores: sisterStoreData, dcDetails, filterData, selectedStore, leadTimeDcMapping, selectedDc: editMode ? selectedStore?.dcs : [] }} setDcSrcRowsSelection={setDcSrcRowsSelection}
                        saveDCSource={saveDCSource} />
                }
            </div>
        </PageLoader>
    )
}

const mapStateToProps = ({ sisterStoreDCConfig, newStoreDetailConfig }) => ({
    filterData: sisterStoreDCConfig.filterData,
    filterDataLoading: sisterStoreDCConfig.filterDataLoading,
    sisterStores: newStoreDetailConfig.sisterStoreDetailsData,
    dcDetails: newStoreDetailConfig.dcSourceData,
    selectedStore: newStoreDetailConfig?.selectedNewStore[0],
    sisterStoreFilters: sisterStoreDCConfig?.sisterStoreFilters,
    selectedSisterStores: sisterStoreDCConfig?.selectedSisterStores,
    leadTimeDcMapping: sisterStoreDCConfig?.leadTimeDcMapping?.mapping,
    saveDetailsLoading: sisterStoreDCConfig?.saveDetailsLoading,
});

const mapDispatchToProps = (dispatch) => ({
    getSisterStoreFilters: (payload) => dispatch(getSisterStoreFilters(payload)),
    getDcSourceDetailsData: (payload) => dispatch(getDcSourceDetailsData(payload)),
    saveSisterStoreFilters: (payload) => dispatch(saveSisterStoreFilters(payload)),
    saveSelecetedSisterStores: (payload) => dispatch(saveSelecetedSisterStores(payload)),
    saveDCSource: (payload) => dispatch(saveDCSource(payload)),
    setSisterStoreMapping: (payload) => dispatch(setSisterStoreMapping(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(DCConfig);