import React, { useCallback, useEffect, useState } from "react";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import ReactTable from "../../../components/Table/ReactTable"
import EditIconCell from '../../../components/Table/Cellrenderer/EditIconCell';
import EditProductAllocationModal from './EditProductAllocationModal'
import Notification from "../../../components/Notification/Notifications";
import * as Notify from "../../../components/Notification/Notifications";
import ColumnFilter from '../../UserManagement/ColumnFilter'
import { numberUSFormatting, oneDecimal } from "../../../utils/formatters/valueFormatters";
import NumberRangeColumnFilter from "../../../components/Filters/NumberRangeColumnFilter";
import { connect } from "react-redux";
import { getPackDetails, updatePacksEachesForMultipleStores } from "../FinalizeAction";
import EditPackDetails from "./EditPackDetails";
import { getNumberOfRecordsPerPages, isAshley, isSizeComponentForEdit, scrollIntoView } from "../../../utils/commonUtilities";
import InputCell from "../../../components/Table/Cellrenderer/InputCell";
import { cloneDeep, isEmpty } from "lodash";
import PageLoader from "../../../components/Loader/PageLoader";
import BulkEditPacksAndEaches from "./BulkEditPacksAndEaches";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const ProductDetailsTable = (props) => {
    const [RTinstance, setRTinstance] = useState(null)
    const [isDisabled, setIsDisabled] = useState(true)
    const [showEditModal, setShowEditModal] = useState(false)
    const [editData, setEditData] = useState({})
    const [initialHiddenColumns, setInitialHiddenColumns] = useState([])
    // const [keyRT, setKeyRT] = useState(props.viewAllocationCall ? "sortAndSearch" : "productDetailView")
    const [keyRT, setKeyRT] = useState(null)
    const [packsAvailable, setPacksAvailable] = useState(0)
    const [eachesAvailable, setEachesAvailable] = useState({})
    const [request1, setRequest1] = useState({})
    const [updatesEachesSizeMapping, setUpdatesEachesSizeMapping] = useState({})
    const [buttonLabel, setButtonLabel] = useState('')
    const [selectedRows, setSelectedRows] = useState([])
    const [availableColumns, setAvailableColumns] = useState([])
    const [availableData, setAvailableData] = useState([])
    const [showAvailable, setShowAvailable] = useState(false)
    const [showButtons, setShowButtons] = useState(false)
    const [buttonClicked, setButtonClicked] = useState(false)

    const { is_eaches, is_pack, pack_config, is_multiple_packs } = props.productDetails

    const pdtRef = React.useRef();

    useEffect(() => {
        setButtonLabel(is_eaches ? 'Eaches' : 'Packs')
    }, [props.productDetails, is_eaches])

    useEffect(() => {
        if (props.viewAllocationCall) {
            setShowButtons(false)
            setKeyRT("sortAndSearch")
            setInitialHiddenColumns(["actions", "allocated_reserve"]);
        }
        else if (!props.viewAllocationCall && (is_pack && is_eaches)) {
            setShowButtons(false)
            setKeyRT("sortAndSearch")
        }
        else if (!props.viewAllocationCall && (!is_pack && !is_eaches)) {
            setShowButtons(false)
            setKeyRT("sortAndSearch")
        }
        else if (!props.viewAllocationCall && (is_pack || is_eaches)) {
            setShowButtons(true)
            setKeyRT("productDetailView")
        }
    }, [is_pack, is_eaches, props.viewAllocationCall])

    useEffect(() => {
        props.isFinalized && setInitialHiddenColumns(["actions", "allocated_reserve"]);
    }, [props.isFinalized])

    const productDetailsDatacolumns = React.useMemo(() => [
        {
            Header: ' ',
            sticky: "left",
            columns: [
                {
                    Header: "Store Number",
                    accessor: 'store_code',
                    Filter: (instance) => <ColumnFilter {...instance} placeholder="Search in bulk..." />,
                    filter: 'bulkFilterSpaceSeperated',
                },
                {
                    Header: "Store Name",
                    accessor: "store_name",
                    Filter: ColumnFilter,
                },
                {
                    Header: "Store Grade",
                    accessor: "store_grade",
                    Filter: ColumnFilter,
                    filter: 'exactText',
                },
                {
                    Header: "Climate",
                    accessor: "climate",
                    Filter: ColumnFilter,
                    filter: 'exactText',
                },
                // {
                //     Header: 'Country',
                //     accessor: 'country',
                //     Filter: ColumnFilter,
                // }

            ],
        },
        // {
        //     Header: ' ',
        //     sticky: "left",
        //     columns: [
        //         {
        //             Header: "Store Name",
        //             accessor: "store_name",
        //             Filter: ColumnFilter,
        //         }
        //     ],
        // },
        // {
        //     Header: ' ',
        //     sticky: "left",
        //     columns: [
        //         {
        //             Header: "Store Grade",
        //             accessor: "store_grade",
        //             Filter: ColumnFilter,
        //             filter: 'exactText',
        //         }
        //     ],
        // },
        // {
        //     Header: ' ',
        //     sticky: "left",
        //     columns: [
        //         {
        //             Header: "Climate",
        //             accessor: "climate",
        //             Filter: ColumnFilter,
        //             filter: 'exactText',
        //         }
        //     ],
        // },
        // {
        //     Header: ' ',
        //     sticky: "left",
        //     columns: [
        //         {
        //             Header: 'Country',
        //             accessor: 'country',
        //             Filter: ColumnFilter,
        //         }
        //     ]
        // },
        {
            Header: "Original APS",
            accessor: row => row.original_aps ? oneDecimal(Number(row.original_aps)) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'Actual APS',
            accessor: row => row.forecast_aps ? oneDecimal(Number(row.forecast_aps)) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',

        },
        {

            Header: 'OH',
            accessor: 'oh',
            Filter: NumberRangeColumnFilter,
            filter: 'between',

        },
        {
            Header: 'OO',
            accessor: 'oo',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'IT',
            accessor: 'it',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: "LW Sales Units",
            accessor: "lw_qty",
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: "WTD Sales Units",
            accessor: "tw_qty",
            Cell: (inst) => (
                <div>
                    {inst.value || inst.value === 0
                        ? numberUSFormatting(inst.value)
                        : null}
                </div>
            ),
            Filter: NumberRangeColumnFilter,
            filter: 'between',

        },
        {
            Header: 'Max Constraint',
            accessor: 'max',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'Min Constraint',
            accessor: 'min',
            Filter: NumberRangeColumnFilter,
            filter: 'between'

        },
        {
            Header: "Allocated Reserve",
            accessor: "allocated_reserve",
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        },
        {
            Header: 'Allocated Total Quantity',
            columns: [
                {
                    Header: 'Pack ID',
                    accessor: 'packs',
                    id: "pack_id",
                    Filter: ColumnFilter
                },
                {
                    Header: '# Packs Allocated',
                    accessor: 'packs_allocated',
                    Cell: (instance) => (
                        is_pack && selectedRows.includes(instance.row.id) && !buttonClicked ?
                            <InputCell {...instance} style={{ textAlign: "center" }} min={0} type="number" step={1}
                                blurHandler={(rowIdx, columnId, values, row_id, temp_id, row, focusedValue) => {
                                    let modified = []
                                    availableData.forEach(val => {
                                        if (val.dc_name === instance.row.original.dc_code) {
                                            val['packs_available'] = (Number(val['packs_available']) || 0) - (Number(values) - Number(focusedValue))
                                            modified.push(val)
                                        }
                                        else {
                                            val['packs_available'] = val['packs_available']
                                            modified.push(val)
                                        }

                                    })
                                    setAvailableData(modified)
                                }}
                                changeHandler={(rowIdx, columnId, values) => {
                                    setRequest1((old) => {
                                        return {
                                            ...old,
                                            [instance.row.original['store_code']]: `${values}+-+-${instance.row.original['packs']}`,
                                        }
                                    })
                                }}
                            /> :
                            <div>
                                {instance.row.original?.packs_allocated}
                            </div>
                    ),
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
                {
                    Header: '# Eaches Allocated',
                    accessor: 'loose_units_allocated',
                    Cell: (inst) => numberUSFormatting(inst?.value),
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
                {
                    Header: 'Total Allocated Units',
                    accessor: 'allocated_quantity',
                    Cell: (inst) => numberUSFormatting(inst?.value),
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                    width: 200
                }
            ]
        },
        {
            Header: 'Min Allocation',
            accessor: 'allocated_for_min',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'WOS Allocation',
            accessor: 'allocated_for_wos',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'DC Name',
            accessor: 'dc',
            Filter: ColumnFilter,
            // filter: 'exactText',
        },
        {

            Header: "Net DC Available",
            accessor: "dc_avai_art",
            Cell: (inst) => numberUSFormatting(inst?.value),
            Filter: NumberRangeColumnFilter,
            filter: 'between',

        },
        {


            Header: "Size Integrity in %",
            accessor: "size_integrity",
            Filter: NumberRangeColumnFilter,
            filter: 'between',


        },
        // {
        //     Header: "Allocated Quantity",
        //     columns: []
        // },

    ], [selectedRows,is_pack,props.isFinalized])

    const editAllocateQty = (p_instance) => {
        let { selectedRow } = props
        let req = {
            "allocationcode": props.allocationcode,
            "article": selectedRow.article,
            "store_code": p_instance.store_code
        }
        if (props.viewAllocationCall) {
            req["page"] = "viewPastAllocation"
        }
        if (props.releasedDate) {
            req["released_date"] = props.releasedDate
        }
        setEditData(req)
        props.getPackDetails(req);
        setShowEditModal(true)
    }

    const closeEditModal = (callAllApi) => {
        setShowEditModal(false)
        if (callAllApi) {
            props.callAllApis()
        }
    }

    const onBulkEdit = () => {
        if(isEmpty(Object.keys(RTinstance.state.selectedRowIds))) {
            Notify.error("Please select atleast 1 store to edit!!")
            return;
        }
        let selectedIds = RTinstance?.getSelectedRows
        let data = RTinstance.data.filter((val,ind) => selectedIds.includes(`${ind}`))
        let dcs = data?.map(val => val?.dc_code)
        let dcsLenght = dcs?.length ? [...new Set(dcs)] : 0
        if(+dcsLenght?.length === 1) {
            setSelectedRows(Object.keys(RTinstance.state.selectedRowIds))
            setButtonClicked(true);
        }
        else {
            Notify.error("Please select the stores mapped with same dc!!")
        }
    }

    const resetButtonClick = () => {
        setButtonClicked(false)
        setTimeout(() => {
            scrollIntoView(pdtRef)
        }, 200)
    }

    const onEdit = () => {

        if (isEmpty(Object.keys(RTinstance.state.selectedRowIds))) {
            Notify.error("Please select atleast 1 store to edit!!")
            showAvailable && setShowAvailable(false)
            return;
        }

        if (is_pack) {
            let max_pack_available = cloneDeep(props?.productDetails?.packs_available) || {};
            let keys = Object.keys(max_pack_available)
            const data = keys.map(val => {
                return {
                    ...pack_config,
                    dc_name: val,
                    packs_available: max_pack_available?.[val]
                }
            })

            setPacksAvailable(max_pack_available)
            let columnData = [{
                Header: "Dc Name",
                accessor: "dc_name",
                width: 200
            }]
            let column = props.sizes?.map(val => {
                return {
                    Header: val,
                    accessor: val
                }
            })
            column.push({
                Header: '# Packs Avaialble',
                accessor: 'packs_available'
            })
            setAvailableColumns([...columnData,...column])


            setAvailableData(data)
        }
        if (is_eaches) {
            let max_eaches_available = cloneDeep(props?.productDetails?.eaches_available) || {};
            let max_eaches_available_data = cloneDeep(props?.productDetails?.eaches_available) || {};
            let sizes = props.sizes
            Object.keys(RTinstance.state.selectedRowIds).forEach((rowId) => {
                sizes.forEach((s, index) => {
                    max_eaches_available_data[s] = (Number(max_eaches_available_data[s]) || 0) + (Number(props.data?.[rowId]?.["size_value"]?.[index]) || 0)
                })
            });
            let keys = Object.keys(max_eaches_available)
            let dc_map = props?.productDetails?.dc_map
            const data = keys.map(val => {
                return {
                    dc_name: val,
                    size: max_eaches_available?.[val]
                }
            })
            setEachesAvailable(max_eaches_available)
            setUpdatesEachesSizeMapping({})
            let columnData = []
            let column = [{
                Header: "Dc Name",
                accessor: "dc_name",
                width: 200
            }]

            let SizeColumn = sizes.map(val => {
                return {
                    Header: val,
                    accessor: (data) => data?.size[val]
                }
            })

            columnData = [...column, ...SizeColumn]
            setAvailableColumns(columnData)
            setAvailableData(data)
        }
        setRequest1({})
        setShowAvailable(true)
        setSelectedRows(Object.keys(RTinstance.state.selectedRowIds))
    }

    const onSave = () => {
        if (isEmpty(request1)) {
            Notify.error("Please Edit before saving!!")
            return;
        }

        let stores_details = []
        let l_pack_id
        let request = {}
        let l_available_eaches = {}
        const {article} = props.selectedRow
        const {allocationcode} = props       
        
        if(is_pack) {
            Object.keys(request1).forEach(val => {
                l_pack_id = request1[val].split("+-+-")[1]
                stores_details.push({
                    "store": val,
                    "updated_packs": {
                        [request1[val].split("+-+-")[1]]: request1[val].split("+-+-")[0]
                    }
                })
            })
           
            if(availableData?.some(val => val?.packs_available < 0)) {
                Notify.error("The allocated packs are more than the available units!!")
                return;
            }

            let l_available_packs = {}
            availableData.forEach(val => {
                l_available_packs = {
                    ...l_available_packs,
                    [val?.dc_name]: {
                        [l_pack_id]: val["packs_available"]
                    }
                }
            })

            request = {
                "allocation_code": allocationcode,
                "available_packs": {
                    ...l_available_packs
                },
                "article": article,
                "stores": stores_details
            }
        }

        if (is_eaches) {
            let l_sizes = availableData?.map(val => val.size)
            for (let i = 0; i < l_sizes.length; i++) {
                if (Object.values(l_sizes[i]).some(val => val < 0)) {
                    Notify.error(`The allocated eaches for one of the sizes are more than the available units!!`)
                    return;
                }
            }
            // for(let i in availableData) {
            //     if(Object.values(availableData[i].size) < 0) {
            //         Notify.error(`The allocated eaches are more than the available units for size ${i}!!`) 
            //         return;
            //     } 
            // }

            Object.keys(request1).forEach(val => {
                stores_details.push({
                    "store": val,
                    "updated_eaches": request1[val]
                })
            })

            for (let i in updatesEachesSizeMapping) {
                l_available_eaches[i] = (Number(eachesAvailable[i]) || 0) - (Number(updatesEachesSizeMapping[i]) || 0)
            }
            // let old_eaches_available = cloneDeep(props?.productDetails?.eaches_available) || {};
            let available_eaches = {}
            availableData.forEach(val => {
                available_eaches = {
                    ...available_eaches,
                    [val?.dc_name]: val?.size
                }
            })

            request = {
                "allocation_code": allocationcode,
                "available_eaches": available_eaches,
                "article": article,
                "stores": stores_details
            }
            console.log('ss1', request)

        }
        
        let { originalAllocationCode } = props
        let l_original_allocation_code = allocationcode === originalAllocationCode ? "" : originalAllocationCode
        request["original_allocation_code"] = l_original_allocation_code
        setTimeout(() => {
            scrollIntoView(pdtRef)
        }, 200)
        if(props?.articles?.length) {
        request["articles"] = props?.articles
        }
        props.updatePacksEachesForMultipleStores(request)
    }

    useEffect(() => {
        if (props.updatePackEachesError) {
            setRequest1({})
            setSelectedRows([])
            Notify.error("Error in Editing Allocated Units")
        }
    }, [props.updatePackEachesError])


    useEffect(() => {
        if (props.updatePackEachesData) {
            setRequest1({})
            setSelectedRows([])
            Notify.success("Allocated Units Edited Sucessfully!!")
            props.callAllApis()
        }
    }, [props.updatePackEachesData])

    const setSizeColumns = useCallback(() => {
        if (props.sizes.length) {
            props.sizes.forEach((s, index) => {
                productDetailsDatacolumns.push({
                    Header: s,
                    columns: [
                        {
                            Header: 'Allocated Quantity',
                            id: `Allocated Quantity ${s} `,
                            accessor: row => row.size_value[index] ? Number(row.size_value[index]).toFixed(0) : 0,
                            Cell: (instance) => (
                                is_eaches && selectedRows.includes(instance.row.id) && !buttonClicked ? <InputCell {...instance} style={{ textAlign: "center" }} min={0} step={1} type="number"
                                    blurHandler={(rowIdx, columnId, values, row_id, temp_id, row, focusedValue) => {
                                        setUpdatesEachesSizeMapping((old) => {
                                            return {
                                                ...old,
                                                [s]: (Number(old[s]) || 0) + Number(values) - Number(focusedValue)
                                            }
                                        })
                                        let modified = []
                                        availableData.forEach(val => {
                                            if (val.dc_name === instance.row.original.dc_code) {

                                                val.size[s] = (Number(val?.size[s])) - (Number(values) - Number(focusedValue))
                                                modified.push(val)
                                            }
                                            else {
                                                val.size[s] = val.size[s]
                                                modified.push(val)
                                            }

                                        })
                                        setAvailableData(modified)
                                        // return [{
                                        //     [s]:  (Number(old[0][s]) || 0) - (Number(values) - Number(focusedValue))
                                        // }]
                                        // })
                                    }}
                                    changeHandler={(rowIdx, columnId, values) => {
                                        setRequest1((old) => {
                                            return {
                                                ...old,
                                                [instance.row.original['store_code']]: {
                                                    ...old?.[instance.row.original['store_code']],
                                                    [s]: values
                                                },
                                            }
                                        })
                                    }}
                                /> :
                                    <div> {instance.row.original.size_value[index] ? Number(instance.row.original.size_value[index]).toFixed(0) : 0} </div>
                            ),
                            Filter: NumberRangeColumnFilter,
                            filter: 'between',
                            width: 200,
                        },
                        {
                            Header: 'OH+OO+IT',
                            id: `OH+OO+IT ${s}`,
                            accessor: row => Number(row.it_value[index]) + Number(row.oh_value[index]) + Number(row.oo_value[index]),
                            Cell: (instance) => (
                                <div>
                                    {(instance.row.original.it_value[index] === undefined && instance.row.original.oh_value[index] === undefined && instance.row.original.oo_value[index] === undefined ) ?  "-": Number(instance.row.original.it_value[index]) + Number(instance.row.original.oh_value[index]) + Number(instance.row.original.oo_value[index])}   
                                </div>
                            ),
                            Filter: NumberRangeColumnFilter,
                            filter: 'between'
                        },
                        {
                            Header: 'LW Sales Units',
                            id: `LW ${s}`,
                            accessor: row => Number(row?.lw_qty_value?.[index]),
                            Cell: (instance) => (
                                <div>
                                    {instance.row.original.lw_qty_value[index] === undefined ?  "-": Number(instance.row.original.lw_qty_value?.[index])}   
                                </div>
                            ),
                            Filter: NumberRangeColumnFilter,
                            filter: 'between',
                        },
                        {
                            Header: 'WTD Sales Units',
                            id: `WTD ${s}`,
                            accessor: row => Number(row?.tw_qty_value?.[index]),
                            Cell: (instance) => (
                                <div>
                                    {instance.row.original.tw_qty_value[index] === undefined ?  "-": Number(instance.row.original.tw_qty_value?.[index])}   
                                </div>
                            ),
                            Filter: NumberRangeColumnFilter,
                            filter: 'between',
                        }
                    ],
                })
            })
            productDetailsDatacolumns.push({
                Header: " ",
                sticky: "right",
                columns: [
                    {
                        Header: "Actions",
                        accessor: "actions",
                        Cell: (instance) => (
                            <div className={props.isFinalized ? "disable__column" : ""}>
                                <EditIconCell
                                    handleEdit={editAllocateQty}
                                    instance={instance}
                                ></EditIconCell>
                            </div>
                        ),
                        width: 100,
                        resizable: true,
                        disableFilters: true,
                    }
                ]


            })
            // setTableColumns(columns)
            return true
        }
        return false
    }, [selectedRows, is_eaches, props.sizes])

    return (
        keyRT &&
        setSizeColumns() &&
        <>
            {/* {
                !showEditModal &&
                <Notification />
            } */}
            {
                selectedRows.length > 0 && buttonClicked &&
                <BulkEditPacksAndEaches 
                    selectedFlatRows={RTinstance?.selectedFlatRows} 
                    buttonClicked={buttonClicked} 
                    resetButtonClick={resetButtonClick}
                    packConfig={pack_config}
                    sizes={props.sizes}
                    buttonLabel={buttonLabel}
                    packsAvailable = {props?.productDetails?.packs_available}
                    eachesAvailable = {props?.productDetails?.eaches_available}
                    article={props.selectedRow.article}
                    allocationCode={props.selectedRow.allocationcode}
                    allocationCodeAfterUpdate= {props.allocationcode}
                    originalAllocationCode={props.originalAllocationCode}
                    articles={props?.articles}
                /> 
            }

            {
                showAvailable &&
                <div style={{ margin: "3rem 0" }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <h1 className="fnt-md fnt-bold">Available {buttonLabel}</h1>
                    </div>
                    <ReactTableWithPlugins
                        hideColumnsFilter
                        data={availableData.length ? availableData : []}
                        columns={availableColumns.length ? availableColumns : []}
                        renderMarkup='TableMarkup'
                        tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                        headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                    />
                </div>
            }

            <div className="storesStore__heading" ref={pdtRef}>
                <h1 className="fnt-md fnt-bold">{props.selectedRow.article}</h1>
                {
                    props.data.length > 1 &&
                    <h1 className="fnt-md fnt-bold">Stores</h1>
                }
            </div>
            <PageLoader loader={props.updatePackEachesLoading} gridLoader={true}>
                <ReactTableWithPlugins
                    hideOptions={props.sizes}
                    // shouldPagination
                    embededScroll
                    totalRecordsLen={props.data ? props.data.length : 0}
                    data={props.data ? props.data : []}
                    columns={productDetailsDatacolumns}
                    sortBy={[{ id: 'store_code', desc: false }]}
                    renderMarkup='TableMarkup'
                    prepareRequest={true}
                    setIsDisabled={setIsDisabled}
                    getInstanceOnMount={(instance) => {
                        setRTinstance(instance)
                    }}
                    keyRT={keyRT}
                    features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                    initialHiddenColumns={initialHiddenColumns}
                    tableId="finalize_product_details"
                    pageSize={getNumberOfRecordsPerPages("finalize_product_view", props.data.length)}
                    tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                    headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                />
                {
                    !props.allocationStatus && !isSizeComponentForEdit() && showButtons && !is_multiple_packs && !props.viewAllocationCall && !props.isFinalized &&
                    <div style={{ textAlign: "center", margin: '2rem' }}>
                        <button
                            style={{ margin: '2.5rem 1.5rem 1.5rem' }}
                            onClick={onBulkEdit}
                            className="btn btn-primary"
                        // disabled={isDisabled}
                        >
                            Bulk Edit {buttonLabel}
                        </button>
                        <button
                            style={{ margin: '2.5rem 1.5rem 1.5rem' }}
                            onClick={onEdit}
                            className="btn btn-primary"
                        // disabled={isDisabled}
                        >
                            Edit {buttonLabel}
                        </button>
                        <button
                            style={{ margin: '2.5rem 1.5rem 1.5rem' }}
                            onClick={onSave}
                            className="btn btn-primary"
                        // disabled={isDisabled}
                        >
                            Save
                        </button>
                    </div>
                }
            </PageLoader>

            {showEditModal ? (
                isSizeComponentForEdit() ? 
                <EditProductAllocationModal 
                    fields={productDetailsDatacolumns[5].columns}
                    showAlert={true}
                    closeAlert={closeEditModal}
                    data={editData}
                    articles={props?.articles}
                /> :
                <EditPackDetails
                    showAlert={true}
                    closeAlert={closeEditModal}
                    data={editData}
                    originalAllocationCode={props.originalAllocationCode}
                    articles={props?.articles}
                />
            ) : null}
        </>
    )

}


const mapStateToProps = ({ finalize }) => {
    return {
        updatePackEachesLoading: finalize.updatePackEachesLoading,
        updatePackEachesData: finalize.updatePackEachesData,
        updatePackEachesError: finalize.updatePackEachesError,
        allocationStatus: finalize.allocationStatus
    }
}

const mapDispatchToProps = (dispatch) => ({
    getPackDetails: (payload) => dispatch(getPackDetails(payload)),
    updatePacksEachesForMultipleStores: (payload) => dispatch(updatePacksEachesForMultipleStores(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailsTable)