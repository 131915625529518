import React, { useEffect, useState } from "react"
import Modal from "react-awesome-modal";
import PageLoader from "../../../../components/Loader/PageLoader";
import Notification from "../../../../components/Notification/Notifications";
import DemandConstraintsTable from "./DemandConstraintsTable"
import * as Notify from "../../../../components/Notification/Notifications";
import Filters from "./Filters"
import { cloneDeep } from "lodash";
import SetAllModal from "../../../../components/ErrorModal/SetAllModal";


const AddProducts = (props) => {
    const { resetAddProductsData, getAddProducts, data, dataLoading, dataError, closeModal, sisterStores,
        showModal, addProducts, store, sisterStore, dcSelected, sisterStoresL1Mapping, setMoveProducts } = props

    const [RTInstance, setRTInstance] = useState({})
    const [showStoreSelection, setShowStorePopup] = useState(false)
    const [refStore, setRefStore] = useState()
    const [addProductsData, setAddProductsData] = useState([])
    const [tableData, setTableData] = useState([])
    const [selectedRows, setSelectedRows] = useState({})

    useEffect(() => {
        setTableData(data)
    }, [data])

    const handleAddProducts = () => {
        let flag = true
        if (RTInstance?.getSelectedRows?.length) {
            RTInstance?.selectedFlatRows?.forEach(row => {
                if (!row?.original?.sister_store_ref) {
                    flag = false
                }
            });
            if (flag) {
                setMoveProducts(RTInstance.selectedFlatRows?.map(row => row?.original))
            }
            else {
                Notify.error(`Please select reference store for all the selected products!!`)
            }
        }
        else {
            Notify.error(`Please select atleast 1 product!!`)
        }
    }

    const applyFilters = (filters) => {
        getAddProducts({
            "sister_stores": sisterStoresL1Mapping,
            "dc": ["930"],
            ...filters
        })
    }

    useEffect(() => {
        return () => {
            resetAddProductsData()
        }
    }, [])

    const onSisterStoreChange = (val) => {
        setRefStore(val)
    }

    const sisterStorePrep = [{
        id: 1,
        isRequired: true,
        label: "Sistor Store",
        name: "sisterStore",
        className: "setAll__filter",
        onChange: onSisterStoreChange,
        placeHolder: `Select Store eligibility Group`,
        options: sisterStores.filter(store => !(Object.values(sisterStoresL1Mapping).join().split(",").includes(store.label.split("-")[0]))),
        isMultiSelect: false,
        hideSelectAll: true,
    }]

    const handleSetStoreOk = () => {
        let setAllRows = Object.keys(RTInstance?.state?.selectedRowIds)
        showStoreSelection && setShowStorePopup(false)
        let selectedRows = {}
        RTInstance?.getSelectedRows?.map(item => selectedRows[item] = true)
        setSelectedRows(selectedRows)
        setTableData((oldData) => {
            let newData = cloneDeep(oldData);
            setAllRows.forEach(setAllRow => {
                newData[setAllRow]["sister_store_ref"] = refStore.value
                newData[setAllRow]["sister_store_codes"] = [refStore.label.split("-")[0]]
            })
            return newData;
        });
    }

    const handleSelectStoreClick = () => {
        if (RTInstance?.getSelectedRows?.length) {
            setShowStorePopup(true)
        }
        else {
            Notify.error(`Please select atleast 1 product to use Set All functionality!!`)
        }
    }

    return (
        <div className="modal__div" style={{ zIndex: 1000 }}>
            {
                showStoreSelection &&
                <SetAllModal filter={sisterStorePrep}
                    heading={"Select Sistor Store"}
                    modalBodyStyle={{ height: '33rem' }}
                    handleOk={handleSetStoreOk} handleCancel={() => { setShowStorePopup(false) }}
                    handleClose={() => { setShowStorePopup(false) }}
                />
            }
            <Modal
                visible={showModal}
                width="80%"
                effect="fadeInDown"
                onClickAway={closeModal}
            >
                {/* <Notification /> */}
                <div className="modal-container">
                    <div className="modal-header">
                        <h5 className="page-heading text-center m-0">
                            Add Products
                        </h5>
                        <button type="button" className="close" data-dismiss="modal">
                            <span style={{ cursor: "pointer" }}>
                                <i
                                    className="fa fa-times text-right m-2 "
                                    aria-hidden="true"
                                    onClick={closeModal}
                                />
                            </span>
                        </button>
                    </div>
                    <div className="modal-middle-container modal-body">
                        <Filters handleFilterRequest={applyFilters} l1_name={props?.l1_name}
                            selectedDepartments={props?.selectedDepartments} />

                        <PageLoader loader={dataLoading} gridLoader={true}>

                            {(dataError) ? (<div className="error">{dataError}</div>) :
                                (data ? (
                                    <div className="mx-2 p-2">
                                        <DemandConstraintsTable data={tableData ? tableData : []}
                                            isAddProduct={true} setRTInstance={setRTInstance} addProSelectedRows={selectedRows} />
                                    </div>
                                ) : null)}
                        </PageLoader>

                    </div>

                    <div className="text-center" style={{ padding: "1rem" }}>
                        <div className="center">
                            <button
                                className="btn btn-primary store-grp-modal__btn"
                                onClick={closeModal}
                            >
                                Close
                            </button>
                            <button
                                className="btn btn-primary fn__btn mr-4"
                                onClick={handleSelectStoreClick}
                            >
                                Select Sister Store
                            </button>
                            <button
                                className="btn btn-primary fn__btn"
                                onClick={handleAddProducts}
                            >
                                Add Products

                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}


export default AddProducts