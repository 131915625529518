import * as actionTypes from "../../actions/DemandAction/DemandActionTypes"
import { demandInitialState } from "./DemandInitialState"
import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

export default {
    demandConstraints: handleActions(
        {
            [actionTypes.GET_DEMAND_DATA]: (state, payload) => {
                return immutable(state, {
                    checkAllData: { $set: [] },
                    data: { $set: [] },
                    demandDataLoading: { $set: true },
                    demandDataError: { $set: null },
                    selectedRows: { $set: [] },
                    outOfDataCA: {$set: false}
                });
            },
            [actionTypes.GET_DEMAND_DATA_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    data: { $set: (payload && payload.data) || [] },
                    pageIndex: { $set: (payload && state.pageIndex + payload?.data?.demandData?.length) || 0 },
                    out_of_data: { $set: (payload && payload.data && payload.data?.out_of_data) || false },
                    style_index: { $set: (payload && payload.data && payload.data?.styleIndex) || 0 },
                    demandDataLoading: { $set: false },
                    demandDataError: { $set: null }
                });
            },
            [actionTypes.GET_DEMAND_DATA_ERROR]: (state, payload) => {
                return immutable(state, {
                    data: { $set: [] },
                    demandDataLoading: { $set: false },
                    demandDataError: { $set: (payload && payload.error) || null }
                });
            },
            [actionTypes.GET_REVIEW_ALLOCATION_DATA]: (state, payload) => {
                return immutable(state, {
                    reviewData: { $set: [] },
                    reviewDataLoading: { $set: true },
                    reviewDataError: { $set: null },
                });
            },
            [actionTypes.GET_REVIEW_ALLOCATION_DATA_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    reviewData: { $set: (payload && payload.data) || [] },
                    reviewDataLoading: { $set: false },
                    reviewDataError: { $set: null }
                });
            },
            [actionTypes.GET_REVIEW_ALLOCATION_DATA_ERROR]: (state, payload) => {
                return immutable(state, {
                    reviewData: { $set: [] },
                    reviewDataLoading: { $set: false },
                    reviewDataError: { $set: (payload && payload.error) || null }
                });
            },
            [actionTypes.GET_ADD_PRODUCTS_DATA]: (state, payload) => {
                return immutable(state, {
                    addProductsData: { $set: [] },
                    addProductsDataLoading: { $set: true },
                    addProductsDataError: { $set: null },
                });
            },
            [actionTypes.GET_ADD_PRODUCTS_DATA_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    addProductsData: { $set: (payload && payload.data) || [] },
                    addProductsDataLoading: { $set: false },
                    addProductsDataError: { $set: null }
                });
            },
            [actionTypes.GET_ADD_PRODUCTS_DATA_ERROR]: (state, payload) => {
                return immutable(state, {
                    addProductsData: { $set: [] },
                    addProductsDataLoading: { $set: false },
                    addProductsDataError: { $set: (payload && payload.error) || null }
                });
            },
            [actionTypes.GET_NEW_STORE_LIST]: (state, payload) => {
                return immutable(state, {
                    newStoreData: { $set: [] },
                    newStoreDataLoading: { $set: true },
                    newStoreDataError: { $set: null },
                });
            },
            [actionTypes.GET_NEW_STORE_LIST_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    newStoreData: { $set: (payload && payload.data) || [] },
                    newStoreDataLoading: { $set: false },
                    newStoreDataError: { $set: null }
                });
            },
            [actionTypes.GET_NEW_STORE_LIST_ERROR]: (state, payload) => {
                return immutable(state, {
                    newStoreData: { $set: [] },
                    newStoreDataLoading: { $set: false },
                    newStoreDataError: { $set: (payload && payload.error) || null }
                });
            },
            [actionTypes.SET_SELECTED_DEMAND_TABLE_ROWS]: (state, payload) => {
                return immutable(state, {
                    selectedDemandRows: { $set: (payload?.payload?.length && payload.payload) || [] }
                });
            },
            [actionTypes.SAVE_NEW_STORE_ALLOCATION]: (state, payload) => {
                return immutable(state, {
                    saveLoading: { $set: true },
                    saveNewStoreError: { $set: null },
                    saveNewStoreSuccess: { $set: false }
                });
            },
            [actionTypes.SAVE_NEW_STORE_ALLOCATION_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    saveLoading: { $set: false },
                    saveNewStoreError: { $set: null },
                    saveNewStoreSuccess: { $set: true }
                });
            },
            [actionTypes.SAVE_NEW_STORE_ALLOCATION_ERROR]: (state, payload) => {
                return immutable(state, {
                    saveLoading: { $set: false },
                    saveNewStoreError: { $set: payload.message },
                    saveNewStoreSuccess: { $set: null }
                });
            },

            [actionTypes.REVIEW_NEW_STORE_ALLOCATION]: (state, payload) => {
                return immutable(state, {
                    newAllocationLoading: { $set: true },
                    newAllocationError: { $set: null },
                    newAllocationSuccess: { $set: false },
                    newAllocationData: { $set: [] }
                });
            },
            [actionTypes.REVIEW_NEW_STORE_ALLOCATION_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    newAllocationLoading: { $set: false },
                    newAllocationError: { $set: null },
                    newAllocationSuccess: { $set: true },
                    newAllocationData: { $set: payload.data }
                });
            },
            [actionTypes.REVIEW_NEW_STORE_ALLOCATION_ERROR]: (state, payload) => {
                return immutable(state, {
                    newAllocationLoading: { $set: false },
                    newAllocationError: { $set: payload.message },
                    newAllocationSuccess: { $set: false },
                    newAllocationData: { $set: [] }
                });
            },

            [actionTypes.GET_ARTICLE_ALLOCATION]: (state, payload) => {
                return immutable(state, {
                    articleAllocationLoading: { $set: true },
                    articleAllocationError: { $set: null },
                    articleAllocationData: { $set: [] }
                });
            },
            [actionTypes.GET_ARTICLE_ALLOCATION_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    articleAllocationLoading: { $set: false },
                    articleAllocationError: { $set: null },
                    articleAllocationData: { $set: payload.data }
                });
            },
            [actionTypes.GET_ARTICLE_ALLOCATION_ERROR]: (state, payload) => {
                return immutable(state, {
                    articleAllocationLoading: { $set: false },
                    articleAllocationError: { $set: payload.message },
                    articleAllocationData: { $set: [] }
                });
            },

            [actionTypes.UPDATE_ARTICLE_ALLOCATION]: (state, payload) => {
                return immutable(state, {
                    updateArticleAllocationLoading: { $set: true },
                    updateArticleAllocationError: { $set: null },
                    updateArticleAllocationSuccess: { $set: false }
                });
            },
            [actionTypes.UPDATE_ARTICLE_ALLOCATION_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    updateArticleAllocationLoading: { $set: false },
                    updateArticleAllocationError: { $set: null },
                    updateArticleAllocationSuccess: { $set: payload.message }
                });
            },
            [actionTypes.UPDATE_ARTICLE_ALLOCATION_ERROR]: (state, payload) => {
                return immutable(state, {
                    updateArticleAllocationLoading: { $set: false },
                    updateArticleAllocationError: { $set: payload.error },
                    updateArticleAllocationSuccess: { $set: false }
                });
            },
            [actionTypes.GET_NEW_STORE_SIZE_CURVE]: (state, payload) => {
                return immutable(state, {
                    sizeCurveLoading: { $set: true },
                    sizeCurveError: { $set: null },
                    sizeCurveData: { $set: [] }
                });
            },
            [actionTypes.GET_NEW_STORE_SIZE_CURVE_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    sizeCurveLoading: { $set: false },
                    sizeCurveError: { $set: null },
                    sizeCurveData: { $set: payload.data }
                });
            },
            [actionTypes.GET_NEW_STORE_SIZE_CURVE_ERROR]: (state, payload) => {
                return immutable(state, {
                    sizeCurveLoading: { $set: false },
                    sizeCurveError: { $set: payload.error },
                    sizeCurveData: { $set: [] }
                });
            },

            [actionTypes.RESET_STYLE_INDEX]: (state, { payload }) => {
                return immutable(state, {
                    out_of_data: { $set: false },
                    style_index: { $set: 0 },
                    pageIndex: { $set: 0 },
                });
            },


            [actionTypes.RESET_CA_STYLE_INDEX]: (state, { payload }) => {
                return immutable(state, {
                    checkAllStyleIndex: { $set: 0 },
                });
            },

            [actionTypes.RESET_ALL]: (state) => {
                return immutable(state, {
                    data: { $set: [] },
                    demandDataLoading: { $set: false },
                    demandDataError: { $set: null },
                    addProductsData: { $set: [] },
                    addProductsDataLoading: { $set: false },
                    addProductsDataError: { $set: null },
                    saveNewStoreError: { $set: null },
                    saveNewStoreSuccess: { $set: false },
                    selectedDemandRows: { $set: [] },
                    newAllocationLoading: { $set: false },
                    newAllocationData: { $set: [] },
                    newAllocationError: { $set: null },
                    newAllocationSuccess: { $set: false },
                    articleAllocationLoading: { $set: true },
                    articleAllocationError: { $set: null },
                    articleAllocationData: { $set: [] },
                    updateArticleAllocationSuccess: { $set: false },
                    updateArticleAllocationLoading: { $set: false },
                    updateArticleAllocationError: { $set: false },
                    pageIndex: { $set: 0 },
                    out_of_data: { $set: false },
                    style_index: { $set: 0 },
                    checkAllStyleIndex: { $set: 0 }
                });
            },
            [actionTypes.RESET_RESERVED_DATA]: (state) => {
                return immutable(state, {
                    newStoreData: { $set: [] },
                    newStoreDataLoading: { $set: false },
                    newStoreDataError: { $set: null },
                });
            },
            [actionTypes.RESET_ADD_PRODUCTS_DATA]: (state) => {
                return immutable(state, {
                    addProductsData: { $set: [] },
                    addProductsDataLoading: { $set: false },
                    addProductsDataError: { $set: null },
                });
            },
            [actionTypes.SET_PAGE_INDEX]: (state, {payload}) => {
                return immutable(state, {
                    pageIndex: { $set: payload?.pageIndex },
                });
            },
            [actionTypes.GET_CHECK_ALL_DEMAND_DATA_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    checkAllData: { $set: (payload && payload.data?.demandData) || [] },
                    // pageIndex: { $set: (payload && state.pageIndex + payload?.data?.demandData?.length) || 0 },
                    outOfDataCA: { $set: (payload && payload.data && payload.data?.out_of_data) || false },
                    checkAllStyleIndex: { $set: (payload && payload.data && payload.data?.styleIndex) || 0 },
                    demandDataLoading: { $set: false },
                    // demandData: {$set: [] },
                    demandDataError: { $set: null }
                });
            },
        },
        demandInitialState
    )
}