import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import './Charts.css'

import React, { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';
import ReactTooltip from 'react-tooltip';
import { splitMyString } from '../BarchartHorizontal/BarchartHorizontal';

const PieChart = (props) => {
    
    const lightOptions = {
        legend: {
            display: false
        }
    };

    return (
        <div>
        {
            props?.data?.length ? ( <> <div className="pieChart__container"> {props.data.map((val,indx) => (
            <div style={{ margin: "1rem" }} key={indx}>
            <p data-tip={val['heading']} style={{textAlign:"center", fontSize:"1rem"}} className="text__ellipsis">{splitMyString(val['heading'],4)}</p>
            <ReactTooltip className="react__tool__tip__custom__class"/>
            <Chart width="21rem" type="pie" data={val} options={lightOptions} />
            </div>
            ))}</div>
            <center>
            <ul className="pieChart__legends">
                {props?.legends?.length &&
                    props.legends.map(item => {
                    return (
                        <li key={item.text} className="pieChart__legends__list">
                        <div
                            style={{
                            marginRight: "8px",
                            width: "20px",
                            height: "20px",
                            backgroundColor: item.backgroundColor
                            }}
                        />
                        {item.text}
                        </li>
                    );
                    })}
        </ul> </center></>) : <div class="overlay"><div>No data available</div></div>
        }
           
        </div>
    )
}
    
export default PieChart;