import React, { useEffect, useRef } from "react"
import { connect } from "react-redux";
import ReactTable from "../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import { getSisterStoreFilters } from "../../actions/DCConfigAction/DCConfigAction"
import { useState } from "react";
import MultiSelect from "../../../../components/Filters/MultiSelect";
import { handleSelectAll } from "../../../../utils/SelectAllHandler/SelectAllHander";
import { isEmpty } from "lodash";
import Select from "../../../../components/Filters/SingleSelect";

const FilterCard = (props) => {
    const { filterData, setAllowNextCard, filterValues, onFocusCall, index, closeCard } = props

    const [departmentValue, setDepartmentValue] = useState([])
    const [seasonCode, setSeasonCode] = useState([])
    const [sisterStoreValue, setSisterStoreValue] = useState([])

    useEffect(() => {
        if (isEmpty(filterValues)) {
            setDepartmentValue([])
            setSeasonCode([])
            setSisterStoreValue([])
        }
        else {
            filterValues?.departmentValue && setDepartmentValue(filterValues?.departmentValue)
            filterValues?.seasonCode && setSeasonCode(filterValues?.seasonCode)
            filterValues?.sisterStoreValue && setSisterStoreValue(filterValues?.sisterStoreValue)
        }
    }, [filterValues])

    const onDepartmentChange = (val, event, that) => {
        let valuesArray = handleSelectAll(val, event, that);
        setDepartmentValue(valuesArray[1]);
        setSisterStoreValue([])
        setAllowNextCard(false, {}, null)
    };

    const onStoreChange = (val) => {
        setSisterStoreValue(val);
        setAllowNextCard(seasonCode.length && departmentValue.length, { seasonCode, departmentValue, sisterStoreValue: val}, index)
    };

    const onSeasonCodeChange = (val, event, that) => {
        let valuesArray = handleSelectAll(val, event, that);
        setSeasonCode(valuesArray[1]);
        setDepartmentValue([])
        setSisterStoreValue([])
        setAllowNextCard(false, {}, null)
    };


    return (
        <div className="filter-card__container filter-set">
            {
                !!index &&
                <span className="close-card__icon" onClick={() => { closeCard(index) }}>
                    <i
                        className="fa fa-times text-right m-2"
                        aria-hidden="true"
                    />
                </span>
            }
            <div className="p-field p-grid required mt-4">
                <label className="non-editable-labels p-col-fixed label-35">
                    Season
                </label>
                <div className="p-col w-50">
                    <MultiSelect
                        id="NEW_STORE_SELECTOR"
                        name="seasoncode"
                        placeholder="Select Season Code"
                        className="width-50"
                        dropdownValue={seasonCode}
                        onDropDownChange={onSeasonCodeChange}
                        optionsArray={filterData?.seasoncode || []}
                    />
                </div>
            </div>
            <div className="p-field p-grid required">
                <label className="non-editable-labels p-col-fixed label-35">
                    Department
                </label>
                <div className="p-col w-50">
                    <MultiSelect
                        id="NEW_STORE_SELECTOR"
                        name="storeNumber"
                        placeholder="Select Department"
                        className="width-50"
                        dropdownValue={departmentValue}
                        onDropDownChange={onDepartmentChange}
                        optionsArray={filterData?.l1_name || []}
                    />
                </div>
            </div>
            <div className="p-field p-grid required">
                <label className="non-editable-labels p-col-fixed label-35">
                    Sister Store
                </label>
                <div className="p-col w-50 font-weight-normal">
                    <Select
                        name="storeNumber"
                        value={sisterStoreValue}
                        onChange={onStoreChange}
                        placeholder="Select Sister Store"
                        options={filterData?.stores}
                        className="p-col"
                    />
                </div>
            </div>
        </div>)
}

const mapStateToProps = ({ sisterStoreDCConfig }) => ({
    filterData: sisterStoreDCConfig.filterData,
});

const mapDispatchToProps = (dispatch) => ({
    getSisterStoreFilters: () => dispatch(getSisterStoreFilters()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterCard);