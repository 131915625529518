import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import WithDynamicPlugins from "../WithPlugins";
import ReactTable from "../ReactTable";
import { GetInstanceContext } from "../../Clustering/CreateStoreGroupModal";
import * as Notify from "../../Notification/Notifications";
import { differenceBy, intersectionBy } from "lodash";
import { createStoreGroupStoreDataAction } from "../../../containers/StoreGroup/StoreGroupAction";
// import { cloneDeep } from "lodash";

import {
  isEmpty,
  find,
  intersectionWith,
  findIndex,
  isEqual,
  isObject,
  isString,
  isNil,
  debounce,
  cloneDeep,
} from 'lodash';

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const TransferTableRecords = React.memo((props) => {
  const [firstTableData, setfirstTableData] = useState([]);
  const [secondTableData, setsecondTableData] = useState([]);
  const [secondOriginalTableData, setsecondOriginalTableData] = useState([]);
  const [columns, setcolumns] = useState([]);

  const [firstRTinstance, setFirstRTinstance] = useState(null);
  const [secondRTinstance, setSecondRTinstance] = useState(null);
  const [searchStoreIDCreate, setSearchStoreIDCreate] = useState(null);
  const [searchStoreIDEdit, setSearchStoreIDEdit] = useState(null);

  useEffect(() => {
    if (props.firstTableData) {
      let intersection = intersectionBy(
        props.firstTableData,
        secondTableData,
        "store_code"
      );
      let difference = differenceBy(
        props.firstTableData,
        intersection,
        "store_code"
      );
      setfirstTableData(difference);
    }
    if (props.storeDataInFirstOrSecondTable === "firstTable") {
      if (props.secondTableData) setsecondTableData((old) => [...old]);
      if (props.secondTableData) setsecondOriginalTableData((old) => [...old]);
    } else {
      if (props.secondTableData)
        setsecondTableData((old) => [...props.secondTableData]);
        setsecondOriginalTableData((old) => [...props.secondTableData]);
    }

    if (props.columns) setcolumns(props.columns);
  }, [props.firstTableData, props.secondTableData, props.columns]);

  const columnsMemoized = React.useMemo(() => columns, [columns]);

  function addRecords() {
    let firstSelectedRowsIds =
      firstRTinstance && firstRTinstance.state.selectedRowIds;

    if (Object.keys(firstSelectedRowsIds).length === 0) {
      Notify.error("Please select atleast One store to add");
    }

    let storesToAdd =
      (firstSelectedRowsIds &&
        Object.keys(firstSelectedRowsIds).map(
          (o) => firstRTinstance.rowsById[o].original
        )) ||
      [];
    let storesToRemove = firstTableData.filter(
      (row) => !storesToAdd.map((o) => o.store_code).includes(row.store_code)
    );

    setsecondTableData([...secondTableData, ...storesToAdd]);
    setsecondOriginalTableData([...secondTableData, ...storesToAdd]);
    setfirstTableData(storesToRemove);
  }

  function removeRecords() {
    let secondselectedRowIds =
      secondRTinstance && secondRTinstance.state.selectedRowIds;

    if (Object.keys(secondselectedRowIds).length === 0) {
      Notify.error("Please select atleast One store to remove");
    }

    let storesToAdd =
      (secondselectedRowIds &&
        Object.keys(secondselectedRowIds).map(
          (o) => secondRTinstance.rowsById[o].original
        )) ||
      [];

    let storesToRemove = secondTableData.filter(
      (row) => !storesToAdd.map((o) => o.store_code).includes(row.store_code)
    );

    setfirstTableData([...firstTableData, ...storesToAdd]);
    setsecondTableData(storesToRemove);
    setsecondOriginalTableData(storesToRemove);
  }

  const onInputChange = (value,id)=>{
    if(id === "firstTable"){
      setSearchStoreIDCreate(value)
    }
    if(id === "secondTableEdit"){
      setSearchStoreIDEdit(value)
    }
  }

  useEffect(() => {
        if (searchStoreIDCreate) {
          let filtername = null;
          let userNewTabledata = null;
          filtername = cloneDeep(firstTableData).filter((el) => {
            var string = el.store_code;
            return (
              string.toUpperCase().includes(searchStoreIDCreate.toUpperCase())
            );
          });
          userNewTabledata = filtername;
            setfirstTableData(userNewTabledata)
        } 
        else {
          if(props.callBackData && props.callBackData.data && props.callBackData.tableData){
            props.getStoreDataApi(props.callBackData.data, props.callBackData.tableData);
          }
         }
      
  }, [searchStoreIDCreate,props.getStoreDataApi]);

  useEffect(() => {
        if(searchStoreIDEdit){
      let filtername = null;
      let userNewTabledata = null;
      filtername = cloneDeep(secondTableData).filter((el) => {
        var string = el.store_code;
        return (
          string.toUpperCase().includes(searchStoreIDEdit.toUpperCase())
        );
      });
      userNewTabledata = filtername;
      setsecondTableData(userNewTabledata)
    }else{
      // if(props.callBackData && props.callBackData.data && props.callBackData.tableData){
      // props.getStoreDataApi(props.callBackData.data, props.callBackData.tableData);
      // }
      setsecondTableData(secondOriginalTableData)
    }
      
  }, [searchStoreIDEdit,props.getStoreDataApi]);

  useEffect(() => {
    if(props.shouldResetTableData){
      setfirstTableData([])
      setsecondTableData([])
    }
  },[props.shouldResetTableData])

  return (
    <GetInstanceContext.Consumer>
      {({ setRTinstance1, setRTinstance2 }) => (
        <div className="row mx-3">
        
          <div className="col-md-6 h-100">
          <h5 className="page-subHeading  mt-2 mr-2 mb-4">
          Filtered Stores
          </h5>
          {/* <div className="mb-2 mt-2" style={{width: "300px"}}>
          <label className="mr-1">
            Search By StoreID:
          </label>
          <input
          className="form-input form-control"
          type="text"
          // key={key}
          name="storeID"
          placeholder="Search By storeID"
          value={searchStoreIDCreate || ""}
          onChange={e => {
            onInputChange(e.target.value,"firstTable");
          }}
        />
          </div> */}
          
            <ReactTableWithPlugins
              getInstanceOnMount={(instance) => {
                setRTinstance1(instance);
                setFirstRTinstance(instance);
              }}
              height={props.height}
              data={firstTableData}
              columns={columnsMemoized}
              keyRT={props.keyRT}
              features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
              shouldPagination={true}
              // renderMarkup={"TableMarkup"}
              totalRecordsLen={firstTableData.length}
              tableId="transfer_records1"
              tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
              headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
            ></ReactTableWithPlugins>
            <button
              onClick={addRecords}
              className="btn btn-primary"
              title="Add stores"
              disabled={props.storeGroupName === "All Stores" ? true : false}
            >
              Add
            </button>
          </div>
          <div className="col-md-6 h-100">
          <h5 className="page-subHeading  mt-2 mr-2 mb-4">
          Selected Stores
          </h5>
          {/* <div className="mb-2 mt-2" style={{width: "300px"}}>
          <label className="mr-1">
            Search By StoreID:
          </label>
          <input
          className="form-input form-control"
          type="text"
          // key={key}
          name="storeID"
          placeholder="Select storeID"
          value={searchStoreIDEdit || ""}
          onChange={e => {
            onInputChange(e.target.value,"secondTableEdit");
          }}
        />
         </div> */}
            <ReactTableWithPlugins
              getInstanceOnMount={(instance) => {
                setRTinstance2(instance);
                setSecondRTinstance(instance);
              }}
              height={props.height}
              data={secondTableData}
              columns={columnsMemoized}
              keyRT={props.keyRT}
              features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
              shouldPagination={true}
              // renderMarkup={"TableMarkup"}
              totalRecordsLen={secondTableData.length}
              tableId="transfer_records2"
            ></ReactTableWithPlugins>
            <button
              onClick={removeRecords}
              className="btn btn-primary"
              title="Remove stores"
              disabled={props.storeGroupName === "All Stores" ? true : false}
              tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
              headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
            >
              Remove
            </button>
          </div>
        </div>
      )}
    </GetInstanceContext.Consumer>
  );
});


const mapStateToProps = (store) => {
  return {

  };
};


const mapDispatchToProps = (dispatch) => ({
  getStoreDataApi: (payload, editingOrCreatingStoreGroup) =>
    dispatch(createStoreGroupStoreDataAction(payload, editingOrCreatingStoreGroup)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TransferTableRecords));

