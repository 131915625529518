import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ClusterFiltersInModal from '../../../components/Clustering/ClusterFiltersInModal'
import PageLoader from '../../../components/Loader/PageLoader'
import ReactTollTip from '../../../components/Table/Cellrenderer/ReactToolTip'
import ReactTable from '../../../components/Table/ReactTable'
import WithDynamicPlugins from '../../../components/Table/WithPlugins'
import ColumnFilter from '../../UserManagement/ColumnFilter'
import { fetchFilterData, fetchStoreStatusData, resetAll, updateStoreStatusData } from '../ProductStoreAction'
import SelectCellComponent from "../../../components/Table/Cellrenderer/SelectCellComponent"
import DateInput from '../../../components/Table/Cellrenderer/DateInput'
import moment from "moment";
import { isEmpty } from 'lodash'
import Notification from "../../../components/Notification/Notifications";
import * as Notify from "../../../components/Notification/Notifications";
import NumberRangeColumnFilter from '../../../components/Filters/NumberRangeColumnFilter'
import ConfirmationAlert from '../../../components/ConfirmationAlert/ConfirmationAlert'
import { HIDE_TABLE_COLUMNS, currentClientName, getStoreGradeColumnNamesByClient } from "../../../utils/commonUtilities"
import { levelFiltersLabelFormatter } from '../../../utils/filterLevelMapping'
import { numberUSFormatting } from '../../../utils/formatters/valueFormatters'


const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const getEndDate = (p_end, p_start) => {
    let d1 = new Date(p_end)
    let d2 = new Date(p_start)
    if (d1 > d2) {
        return moment(d1).format("YYYY-MM-DD")
    }
    else {
        return moment(d2).format("YYYY-MM-DD")
    }
}


const StoreStatus = (props) => {

    const [filterData, setFilterData] = useState([])
    const [storeStatusData, setStoreStatusData] = useState([])
    const [disabled, setDisabled] = useState(true)
    const [updatedId, setUpdatedId] = useState([])
    const [RTinstance, setRTinstance] = useState(null)
    const [isClosing, setIsClosing] = useState(false)
    const [req, setReq] = useState([])
    const [initialHiddenColumns, setInitialHiddenColumns] = useState([])
    const [storeGradeColumns, setStoreGradeColumns] = useState(getStoreGradeColumnNamesByClient())


    useEffect(() => {
        let hiddenColumns = HIDE_TABLE_COLUMNS[currentClientName]
        hiddenColumns && setInitialHiddenColumns(hiddenColumns)
        props.fetchFilterData({})
        props.fetchStoreStatusData({})
    }, [])

    useEffect(() => {
        setFilterData(props.filterData)
    }, [props.filterData])

    useEffect(() => {
        setStoreStatusData(props.storeStatusData)
    }, [props.storeStatusData])

    useEffect(() => {
        if (props.updateSucess) {
            Notify.success("Store Status Saved Sucessfully!!")
            props.fetchStoreStatusData({})
            setUpdatedId([])
        }
    }, [props.updateSucess])

    useEffect(() => {
        if (props.updateError) {
            Notify.error("Something Went Wrong!!")
            setUpdatedId([])
        }
    }, [props.updateError])


    useEffect(() => {
        return () => {
            props.resetAll()
        }
    }, [])


    const storecolumns = React.useMemo(() => [
        {
            Header: "",
            sticky: "left",
            id: "storeColumnSticky",
            columns: [{
                Header: 'Store Number',
                accessor: 'store_code',
                id: 'store_code',
                Filter: ColumnFilter,
                filter: 'equals'
            },
            {
                Header: 'Store Name',
                accessor: 'store_name',
                width: 210,
                Filter: ColumnFilter,
            }]
        },
        // {
        //     Header: "Country",
        //     accessor: "country",
        //     Filter: ColumnFilter
        // },
        {
            Header: levelFiltersLabelFormatter("level1"),
            id: "l1_name",
            accessor: "channel",
            Filter: ColumnFilter

        },
        storeGradeColumns,
        {
            Header: 'Store Group',
            accessor: 'store_groups',
            Cell: (instance) => <ReactTollTip {...instance} />,
            Filter: ColumnFilter,
            width: 200
        },
        {
            Header: 'Climate',
            accessor: 'climate',
            Filter: ColumnFilter,
        },
        {
            Header: 'Region Description',
            accessor: 'region',
            Filter: ColumnFilter,
        },
        {
            Header: 'District',
            accessor: 'district',
            Filter: ColumnFilter,
        },
        {
            Header: 'Size (Sq Ft)',
            accessor: 'size',
            Cell: (inst) => numberUSFormatting(inst?.value),
            width: 170,
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'Status',
            accessor: 'store_status',
            Filter: ColumnFilter,
            filter: 'filterSingleSelectCell',
            Cell: (instance) => <div>
                <SelectCellComponent
                    disabled={instance.row.original?.disable_status}
                    defaultValue={instance.row.original?.store_status}
                    options={instance.row.original?.options}
                    changeHandler={(rowIdx, columnId, value) => {
                        setDisabled(false)
                        setUpdatedId((old) => {
                            return [...old, instance.row.original?.store_code]
                        })
                        let l_instance = instance.row.original
                        if (l_instance.inactive_start === "-" && value === "under_renovation")
                            instance.updateMyData(rowIdx, "inactive_start", moment(new Date()).format("YYYY-MM-DD"));
                        if (l_instance.inactive_end === "-" && value === "under_renovation")
                            instance.updateMyData(rowIdx, "inactive_end", moment(new Date()).format("YYYY-MM-DD"));
                        if (value === "open" || value === "closed") {
                            instance.updateMyData(rowIdx, "inactive_start", '-');
                            instance.updateMyData(rowIdx, "inactive_end", '-')
                        }
                    }}
                    {...instance} />
            </div>,
        },
        {
            Header: 'Modified By',
            accessor: 'updated_by',
            width: 250,
            Filter: ColumnFilter,
          },
        {
            Header: 'From',
            accessor: 'inactive_start',
            disableFilters: true,
            Cell: (instance) => instance.row.original.store_status === "under_renovation" ? <DateInput {...instance} value={instance.row.original?.inactive_start == '-' ? moment(new Date()).format("YYYY-MM-DD") : instance.row.original.inactive_start}
            dateFormat={"MM-DD-YYYY"} 
            changeHandler={(rowIdx, columnId, value) => {
                 setUpdatedId((old) =>{
                     return [...old,instance.row.original?.store_code]
                 })
                 let l_date = getEndDate(instance.row.original.inactive_end, value)
                 setDisabled(false)
                 instance.updateMyData(rowIdx, "inactive_end", l_date)
              }}
            /> : '-',
            width: 170,
        },
        {
            Header: 'Upto Date',
            accessor: 'inactive_end',
            disableFilters: true,
            Cell: (instance) => instance.row.original.store_status === "under_renovation" ? <DateInput {...instance} min={instance.row.original?.inactive_start == '-'? moment(new Date()).format("YYYY-MM-DD") : instance.row.original.inactive_start }  
            value={instance.row.original?.inactive_end == '-' ? moment(new Date()).format("YYYY-MM-DD") : instance.row.original.inactive_end}
            changeHandler={(rowIdx, columnId, value) => {
                 setDisabled(false)
                 setUpdatedId((old) =>{
                     return [...old,instance.row.original?.store_code]
                 })
              }}
            dateFormat={"MM-DD-YYYY"} /> : '-',
            
            width: 170,
        },

    ], [initialHiddenColumns])

    const getStores = (p_req) => {
        setDisabled(true)
        setUpdatedId([])
        let req = {};
        if (!isEmpty(p_req)) {
            for (const key in p_req) {
                req[key] = p_req[key].map(function mapper(ele) {
                    if (Array.isArray(ele)) {
                        return ele.map(mapper);
                    } else {
                        return ele.value;
                    }
                });
            }
            for (let i in req) {
                req[i] = req[i].flat(Infinity);
            }
        }
        props.fetchStoreStatusData(req)
    };

    const resetTableData = () => {
        setDisabled(true)
        setStoreStatusData([])
        setUpdatedId([])
    }

    const updateChanges = () => {
        let uniqueUpdatedIds = [...new Set(updatedId)]
        let data = RTinstance?.data?.filter(val => uniqueUpdatedIds.includes(val.store_code))
        if (data.some(val => val.store_status == "closed")) {
            // if (window.confirm("Are you sure you wish to close the Store(s)?")){
            //     props.updateStoreStatusData(data)
            // }
            setReq(data)
            setIsClosing(true)
        }
        else {
            props.updateStoreStatusData(data)
        }
    }

    const handleOk = () => {
        props.updateStoreStatusData(req)
        setIsClosing(false)
    }

    const handleCancel = () => {
        setIsClosing(false)
    }

    const handleClose = () => {
        setIsClosing(false)

    }

    return (
        <React.Fragment>
            {/* <Notification /> */}
            {isClosing && <ConfirmationAlert
                modalStyle={{ width: '45rem' }}
                message="Are you sure you wish to close the Store(s)?"
                handleOk={handleOk}
                handleCancel={handleCancel}
                handleClose={() => handleClose()} />}
            <section className="row section">
                <ClusterFiltersInModal
                    storeConfig={true}
                    storeFilterData={filterData}
                    getStores={getStores}
                    resetTableData={resetTableData}
                />
            </section>
            <div className="store__status__table">
                <PageLoader loader={props.storeStausLoading} gridLoader={true}>
                    {(props.storeStatusError) ? (<div className="error">Something Went Wrong!!</div>) :
                        <ReactTableWithPlugins
                            initialHiddenColumns={initialHiddenColumns}
                            getInstanceOnMount={(inst) => {
                                setRTinstance(inst);
                            }}
                            sortBy={[{ id: 'store_code' }]}
                            data={storeStatusData}
                            columns={storecolumns}
                            keyRT="sortAndSearch"
                            renderMarkup='TableMarkup'
                            // shouldPagination
                            embededScroll
                            tableId="store_config"
                            tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                            headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                        />}
                </PageLoader>
            </div>
            <div style={{ textAlign: "center", margin: '8px auto' }}>
                <button
                    className="btn btn-primary"
                    disabled={disabled}
                    onClick={() => updateChanges()}
                >
                    Save Changes
                </button>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = ({storeGroup, productStoreStatus}) => {
    return {
        // filterData: productStoreStatus?.filterData,
        filterData: storeGroup?.storeGroupFiltersData,
        storeStatusData: productStoreStatus?.storeStatusData,
        storeStausLoading: productStoreStatus?.storeStausLoading,
        updateSucess: productStoreStatus?.updateSucess,
        updateError: productStoreStatus?.updateError,
        storeStatusError: productStoreStatus?.storeStatusError
    };
};


const mapDispatchToProps = (dispatch) => ({
    resetAll: () => dispatch(resetAll()),
    fetchFilterData: (payload) => dispatch(fetchFilterData(payload)),
    fetchStoreStatusData: (payload) => dispatch(fetchStoreStatusData(payload)),
    updateStoreStatusData: (payload) => dispatch(updateStoreStatusData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreStatus)
