import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Calendar } from "primereact/calendar";
import { cloneDeep, isEmpty } from 'lodash';
import moment from "moment";
import ReactTable from '../../../../components/Table/ReactTable';
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import Select from "../../../../components/Filters/SingleSelect";
import * as storeConfigDetailsActions from '../../actions/StoreDetailsConfigActions/StoreConfigActions';
import * as StoreDetailsConsts from './StoreDetailsDcConfigConstants';
import "./Storedetailsconfig.css";
import { getStoreGradeColumnNamesByClient, getCapacityColumnsByClient } from "../../../../utils/commonUtilities"
import MultiSelect from '../../../../components/Filters/MultiSelect';
import { handleSelectAll } from '../../../../utils/SelectAllHandler/SelectAllHander';
import * as Notify from "../../../../components/Notification/Notifications";
import DcLeadTimeCard from './DcLeadTimeCard';
import { saveLeadTimeDcMapping } from "../../actions/DCConfigAction/DCConfigAction"
import PageLoader from '../../../../components/Loader/PageLoader';

/**
 * Table Plugin
 */
const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

/**
 * New Store Filetring Component
 * @param {*} props 
 * @returns 
 */
const StoreOpeningDetails = (props) => {
    let groupId = localStorage.getItem('group_id');

    const [dcLeadTimeCards, setDcLeadTimeCards] = useState([{}])
    const [leadTimeDcOptions, setDcOptions] = useState([])
    const [leadTimeDcMapping, setLeadTimeMapping] = useState({})

    const { storeConfigDetailsActionsProp, newStoreDetailsData, newStoreOptionsData, dcDetails, saveLeadTimeDcMapping,
        selectedNewStoreCode, selectedNewStore, newStoreOpeningDate, editModeDetails, storeGroups, dcLeadMapping, editMode
    } = props;


    useEffect(() => {
        if (!isEmpty(editModeDetails) && editModeDetails?.isEditMode) {
            storeConfigDetailsActionsProp.setSelectedNewStoreCode(
                {
                    value: editModeDetails?.editModeNewStoreCode,
                    label: editModeDetails?.editModeNewStoreCode
                }
            );
            const selectedStore = newStoreDetailsData.filter(detail => detail.store_code === editModeDetails?.editModeNewStoreCode);
            storeConfigDetailsActionsProp.setSelectedNewStoreDetail(selectedStore);
        }
    }, []);

    useEffect(() => {
        setDcOptions(dcDetails)
        if (dcLeadMapping?.cards) {
            setDcLeadTimeCards(dcLeadMapping?.cards)
        }
        else {
            let dcLeadTimeCards = dcDetails.filter(dc => dc.transit_time)?.map(item => {return {dc: {label: item.dc_name, value: item.dc}, leadTime: item.transit_time}})
            setDcLeadTimeCards(dcLeadTimeCards?.length ? dcLeadTimeCards : [{}])
        }
        if (!isEmpty(dcLeadMapping?.mapping)) {
            setLeadTimeMapping(dcLeadMapping?.mapping)
            setDcOptions(dcDetails.filter(item => !Object.keys(dcLeadMapping?.mapping).includes(item.dc)))
        }
    }, [dcDetails, dcLeadMapping])

    const onStoreNumberChange = (storeCode) => {
        storeConfigDetailsActionsProp.setSelectedNewStoreCode(storeCode);
        const selectedStore = newStoreDetailsData.filter(detail => detail.store_code === storeCode.value);
        storeConfigDetailsActionsProp.setSelectedNewStoreDetail(selectedStore);
    };

    const handleOpeningDateChange = (e) => {
        if (e) {
            let selectedStore = cloneDeep(selectedNewStore)

            const changedOpeningDate = moment(e.value).format("YYYY-MM-DD");
            storeConfigDetailsActionsProp.setSelectedNewStoreDetail([{ ...selectedStore[0], open_date: changedOpeningDate }]);
        } else {
            //No code here
        }
    };

    const handleReservationDateChange = (e) => {
        if (e) {
            let selectedStore = cloneDeep(selectedNewStore)

            const changedReserveDate = moment(e.value).format("YYYY-MM-DD");
            storeConfigDetailsActionsProp.setSelectedNewStoreDetail([{ ...selectedStore[0], reservation_start_date: changedReserveDate, open_date: "" }]);

        } else {
            //No code herez
        }
    };

    const updateNewStoreDetail = (key, value) => {
        let selectedStore = cloneDeep(selectedNewStore)
        storeConfigDetailsActionsProp.setSelectedNewStoreDetail([{ ...selectedStore[0], [key]: value }]);
    }

    const onStoreGroupChange = (val, event, that) => {
        let valuesArray = handleSelectAll(val, event, that);
        updateNewStoreDetail("store_group", valuesArray[1])
    };

    const saveLeadTimeCard = (status, value, index) => {
        if (status) {
            let preCards = cloneDeep(dcLeadTimeCards)
            preCards[index] = { dc: value?.selectedDC, leadTime: value?.leadTime }
            setDcLeadTimeCards(preCards)
            let obj = cloneDeep(leadTimeDcMapping)
            obj[value?.selectedDC?.value] = value?.leadTime
            // value?.selectedDC?.forEach(element => {
            //     if (element.value !== "*") {
            //         obj[element?.value] = value?.leadTime
            //     }
            // });
            setLeadTimeMapping(obj)
            let dcOptions = cloneDeep(leadTimeDcOptions)
            setDcOptions(dcOptions.filter(item => !Object.keys(obj).includes(item.dc)))
            saveLeadTimeDcMapping({ data: { mapping: obj, cards: preCards } })
        }
    }

    const addFilterSet = () => {
        if (!leadTimeDcOptions.length) {
            Notify.error("Maximum limit reached!!")
        }
        else if (dcLeadTimeCards[dcLeadTimeCards.length - 1]?.leadTime >= 0) {
            setDcLeadTimeCards([...dcLeadTimeCards, {}])
        }
        else {
            Notify.error("Please Fill Current Card to add new!!")
        }
    }

    const getNewStoreFields = () => {
        let d = new Date(selectedNewStore[0]?.reservation_start_date);
        d.setDate(d.getDate() + 1)

        const arr = [
            <section className="section mt-4 m-0">

                <div className='column-1'>
                    <div className='store-info-container'>
                        <div className="p-field p-grid required">
                            <label className="non-editable-labels p-col-fixed" style={{ width: "45%" }}>
                                Reservation Start Date
                            </label>
                            <div className="p-col" style={{ marginTop: "1%" }}>
                                <Calendar
                                    minDate={new Date()}
                                    id="OPENING_DATE"
                                    className="p-col p-mb-2 opening-date-cal"
                                    dateFormat="mm-dd-yy"
                                    value={editModeDetails?.isEditMode && editModeDetails?.editModeOpeningDate ? new Date(editModeDetails.editModeOpeningDate) : new Date(selectedNewStore[0]?.reservation_start_date || "")}
                                    onChange={(e) => handleReservationDateChange(e)}
                                    readOnlyInput
                                    showIcon
                                    disabled={groupId != 1 || (editMode && new Date(selectedNewStore[0]?.reservation_start_date) < new Date())}
                                />
                            </div>
                        </div>
                        
                        <div className="p-field p-grid required">
                            <label className="non-editable-labels p-col-fixed" style={{ width: "45%" }}>
                                Store Opening Date
                            </label>
                            <div className="p-col" style={{ marginTop: "1%" }}>
                                <Calendar
                                    minDate={d}
                                    id="OPENING_DATE"
                                    className="p-col p-mb-2 opening-date-cal"
                                    dateFormat="mm-dd-yy"
                                    value={editModeDetails?.isEditMode && editModeDetails?.editModeOpeningDate ? new Date(editModeDetails.editModeOpeningDate) : new Date(selectedNewStore[0]?.open_date || "")}
                                    onChange={(e) => handleOpeningDateChange(e)}
                                    readOnlyInput
                                    showIcon
                                    disabled={groupId != 1 || (editMode && new Date(selectedNewStore[0]?.reservation_start_date) < new Date())}
                                />
                            </div>
                        </div>

                        {/* <div className="p-field p-grid required">
                            <label className="non-editable-labels p-col-fixed" style={{ width: "45%" }}>
                                Store Group
                            </label>
                            <div className="p-col w-50" style={{ marginTop: "1%" }}>
                                <MultiSelect
                                    id="NEW_STORE_SELECTOR"
                                    name="storeNumber"
                                    placeHolder={`Select Store Group(s) `}
                                    optionsArray={storeGroups}
                                    dropdownValue={selectedNewStore[0]?.store_group}
                                    className="width-50"
                                    isDisabled={editModeDetails?.isEditMode}
                                    onDropDownChange={onStoreGroupChange}
                                    isDisabled={groupId != 1}
                                />
                            </div>
                        </div> */}
                    </div>

                    <div className="card__wrapper filter-set-container">
                        {dcLeadTimeCards?.map((dc, index) => (
                            <DcLeadTimeCard saveCard={saveLeadTimeCard} dcDetails={dcDetails} card={dc} index={index} />
                        ))}
                        {/* <button className="btn-primary-rounded" onClick={addFilterSet}>
                            <i
                                className="fa fa-plus"
                                title="Create Allocation"
                                aria-hidden="true"
                            ></i>
                        </button> */}
                    </div>
                </div>
            </section>

        ]
        return arr
    }

    return (
        <>
            <h3 className="fnt-bold m-0 px-4 py-2">Store Opening Info</h3>
            <hr />
            <div className="store-search-container">
                <PageLoader loader = {props?.isDcSourceDataLoading}>
                    {getNewStoreFields().map(element => element)}
                </PageLoader>
                {/* <div className="p-field p-grid required">
                <label className="non-editable-labels p-col-fixed">
                    {StoreDetailsConsts.STORE_SEARCH_FIELD_LABEL}
                </label>
                <div className="p-col" style={{ marginTop: "1%" }}>
                    <Select
                        id="NEW_STORE_SELECTOR"
                        name="storeNumber"
                        value={selectedNewStoreCode}
                        onChange={onStoreNumberChange}
                        placeHolder={`Select df `}
                        options={newStoreOptionsData}
                        className="p-col"
                        isDisabled={editModeDetails?.isEditMode}
                    />
                </div>
            </div> */}
                {/* {selectedNewStoreCode && !isEmpty(selectedNewStoreCode.value) && !isEmpty(selectedNewStore) ?
                <>
                    <div className="p-field p-grid" style={{ marginTop: "2%" }}>
                        <label className="non-editable-labels p-col-fixed">
                            {StoreDetailsConsts.EXPECTED_OPENING_DATE_FIELD_LABEL}
                        </label>
                        <div className="p-col">
                            <Calendar
                                id="OPENING_DATE"
                                className="p-mb-2 opening-date-cal"
                                dateFormat="mm-dd-yy"
                                value={editModeDetails?.isEditMode && editModeDetails?.editModeOpeningDate ? new Date(editModeDetails.editModeOpeningDate) : new Date(newStoreOpeningDate)}
                                onChange={(e) => handleOpeningDateChange(e)}
                                readOnlyInput
                                showIcon
                            />
                        </div>
                    </div>
                    <ReactTableWithPlugins
                        shouldPagination={false}
                        data={selectedNewStore}
                        columns={newStoreTableColumns}
                        renderMarkup='TableMarkup'
                        tableId="newstore_enter_new"
                        tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                        headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                    />
                </>
                : null} */}
            </div>
        </>
    );
}

const mapStateToProps = ({ newStoreDetailConfig, sisterStoreDCConfig }) => ({
    newStoreDetailsData: newStoreDetailConfig.newStoreDetailsData,
    newStoreOptionsData: newStoreDetailConfig.newStoreOptionsData,
    selectedNewStoreCode: newStoreDetailConfig.selectedNewStoreCode,
    selectedNewStore: newStoreDetailConfig.selectedNewStore,
    newStoreOpeningDate: newStoreDetailConfig.newStoreOpeningDate,
    editModeDetails: newStoreDetailConfig?.editModeDetails,
    storeGroups: newStoreDetailConfig?.storeGroup,
    dcDetails: newStoreDetailConfig.dcSourceData,
    dcLeadMapping: sisterStoreDCConfig?.leadTimeDcMapping,
    isDcSourceDataLoading: newStoreDetailConfig?.isDcSourceDataLoading
});

const mapDispatchToProps = (dispatch) => ({
    storeConfigDetailsActionsProp: bindActionCreators(storeConfigDetailsActions, dispatch),
    saveLeadTimeDcMapping: (payload) => dispatch(saveLeadTimeDcMapping(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreOpeningDetails);