import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import * as Notify from "../../../../components/Notification/Notifications";
import PageLoader from "../../../../components/Loader/PageLoader";
import { getNewStoreArticleDetails, approveNewStoreArticles, resetApproveDetails } from "../../actions/NewStoreApprove/NewApproveAction"
import ArticleListTable from "./ArticleListTable";
import { getPackDetails } from "../../../Finalize/FinalizeAction";
import Filters from "./Filter";
import { getSisterStoreFilters, resetAll } from "../../../NewStoreSetUp/actions/DCConfigAction/DCConfigAction"

const ArticleListView = (props) => {
    const { articleData, articleDataError, articleDataLoading, selectedStore, getPackDetails, getNewStoreArticleDetails,
        resetApproveDetails, approveNewStoreArticles, approveNewStoreArticlesLoading, approveNewStoreArticlesSuccess,
        approveNewStoreArticlesError, getNewStoreApproveList, getStoreFilters, department, resetAll } = props

    const [selectedFilters, setFilters] = useState({})
    const [errorModalMessage, setErrorMessage] = useState(false)

    useEffect(() => {
        getStoreFilters({ store_code: selectedStore?.store_code, channel: selectedStore?.channel })
        // setFilters({ l1_name: [JSON.parse(localStorage.getItem("user_preference")).filter?.value] })
    }, [])

    const applyFilters = (req) => {
        setFilters(req)
        getNewStoreArticlesData(req)
    }

    const getNewStoreArticlesData = (req = selectedFilters) => {
        getNewStoreArticleDetails({ isRelease: props?.type === "new_store_release", store_code: selectedStore?.store_code, ...req })
    }

    useEffect(() => {
        if (approveNewStoreArticlesError) {
            if (approveNewStoreArticlesError === "Name already exists!!") {
                setErrorMessage(approveNewStoreArticlesError)
            }
            else {
                Notify.error(approveNewStoreArticlesError)
            }
        }
        else if (approveNewStoreArticlesSuccess) {
            applyFilters(selectedFilters)
            let msg = `Articles ${props.type === "new_store_release" ? "Released" : "Approved"} Successfully!!`
            Notify.success(msg)
            getNewStoreApproveList()
        }
        resetApproveDetails()
    }, [approveNewStoreArticlesError, approveNewStoreArticlesSuccess])


    useEffect(() => {
        return () => {
            resetAll()
        }
    }, [])

    return (
        <>
            {/* <section className="row section"> */}
            <PageLoader loader={articleDataLoading || approveNewStoreArticlesLoading} gridLoader={true}>

                <div className="container__header m-0">
                    <h3 className="fnt-bold">Reserved Articles (Store - {selectedStore?.store_code})</h3>
                </div>
                <hr />
                <Filters handleFilterRequest={applyFilters} l1_name={selectedStore?.channel} l2_name={department} />
                {articleDataError ?
                    <div className="error">{articleDataError}</div> :
                    <ArticleListTable data={articleData} selectedStore={selectedStore} getPackDetails={getPackDetails}
                        errorMessage={errorModalMessage} approveNewStoreArticles={approveNewStoreArticles} type={props.type}
                        loading={approveNewStoreArticlesLoading} approveNewStoreArticlesSuccess={approveNewStoreArticlesSuccess}
                        getNewStoreArticleDetails={getNewStoreArticlesData} setErrorMessage={setErrorMessage}
                        getNewStoreApproveList={getNewStoreApproveList} />}
            </PageLoader>
            {/* </section> */}
        </>
    )
}

const mapStateToProps = ({ nsApproveReducer, sisterStoreDCConfig }) => ({
    articleData: nsApproveReducer.articleData,
    articleDataLoading: nsApproveReducer.articleDataLoading,
    articleDataError: nsApproveReducer.articleDataError,
    approveNewStoreArticlesLoading: nsApproveReducer.approveNewStoreArticlesLoading,
    approveNewStoreArticlesSuccess: nsApproveReducer.approveNewStoreArticlesSuccess,
    approveNewStoreArticlesError: nsApproveReducer.approveNewStoreArticlesError,
    department: sisterStoreDCConfig?.sisterStoreFilters?.[0]?.departmentValue
});

const mapDispatchToProps = (dispatch) => ({
    getPackDetails: (payload) => dispatch(getPackDetails(payload)),
    getNewStoreArticleDetails: (payload) => dispatch(getNewStoreArticleDetails(payload)),
    approveNewStoreArticles: (payload) => dispatch(approveNewStoreArticles(payload)),
    resetApproveDetails: () => dispatch(resetApproveDetails()),
    getStoreFilters: (payload) => dispatch(getSisterStoreFilters(payload)),
    resetAll: () => dispatch(resetAll())
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleListView);