import { takeLatest, put, all, call, takeEvery } from "redux-saga/effects";
import * as actionTypes from "./Actions/ActionTypes"
import { isEmpty } from "lodash";
import { getDailySummaryStoreView, getDailySummaryStoreDetails } from "../../../../routes/api";
import { CARDS, DAILY_SUMMARY_TABLE_HEADERS } from "../DailySummaryConstants";
import { levelFiltersLabelFormatter } from "../../../../utils/filterLevelMapping";

function* getStoreViewWorker(action) {
  try {
    const { payload } = action;
    const { request } = payload;
    const req = {}
    for (let i in request) {
      if (!isEmpty(request[i])) {
        if (Array.isArray(request[i])) {
          req[i] = request[i].map(val => val.value)
        }
        else {
          req[i] = request[i]
        }
      }
    }

    const res = yield call(getDailySummaryStoreView, req);

    if (res.data.status) {
      const { aggregated_data, table_data } = res?.data

      const l_aggregated_data = aggregated_data[0]

      const cardData = [
        {
          'title': CARDS.CARD1,
          value: l_aggregated_data?.allocation_count || 0
        },
        {
          'title': CARDS.CARD2,
          value: l_aggregated_data?.style_color_count || 0
        },
        {
          'title': CARDS.CARD3,
          value: l_aggregated_data?.units_allocated || 0
        },
        {
          'title': CARDS.CARD4,
          value: l_aggregated_data?.dc_available || 0
        },
        // {
        //   'title': CARDS.CARD5,
        //   value: l_aggregated_data?.reserved_quantity || 0
        // },
      ]

      const excelData = res.data.table_data.map(detail => ({
        [DAILY_SUMMARY_TABLE_HEADERS.ARTICLE_ID]: detail.article,
        [DAILY_SUMMARY_TABLE_HEADERS.STYLE_DESCRIPTION]: detail.style_name,
        [DAILY_SUMMARY_TABLE_HEADERS.STYLE_COLOR]: detail.color_desc,
        [DAILY_SUMMARY_TABLE_HEADERS.COLOR]: detail.color,
        [levelFiltersLabelFormatter("level1")]: detail.l1_name,
        [levelFiltersLabelFormatter("level2")]: detail.l2_name,
        [levelFiltersLabelFormatter("level3")]: detail.l3_name,
        [levelFiltersLabelFormatter("level4")]: detail.l4_name,
        [levelFiltersLabelFormatter("level5")]: detail.l5_name,
        [DAILY_SUMMARY_TABLE_HEADERS.ALLOCATION_ID]: detail.allocation_code,
        [DAILY_SUMMARY_TABLE_HEADERS.UNITS_ALLOCATED]: detail.units_allocated,
        [DAILY_SUMMARY_TABLE_HEADERS.DC_UNITS_OH]: detail.dc_available,
      }));

      yield put({ type: actionTypes.GET_DAILY_SUMMARY_STORE_VIEW_SUCCESS, data: { tableData: res.data.table_data, cardData: cardData, excelData: excelData } });
    }
    else {
      yield put({ type: actionTypes.GET_DAILY_SUMMARY_STORE_VIEW_ERROR, error: "Something went wrong!" });
    }
  }
  catch (error) {
    yield put({ type: actionTypes.GET_DAILY_SUMMARY_STORE_VIEW_ERROR, error: "Something went wrong!" });
  }

}

function* getStoreDetailsWorker(action) {
  try {
    const { payload } = action;
    const req = {}
    for (let i in payload) {
      if (!isEmpty(payload[i])) {
        if (Array.isArray(payload[i])) {
          req[i] = payload[i].map(val => val.value)
        }
        else {
          req[i] = payload[i]
        }
      }
    }
    const res = yield call(getDailySummaryStoreDetails, req);

    if (res?.data?.status) {
      yield put({ type: actionTypes.GET_DAILY_SUMMARY_STORE_DETAILS_SUCCESS, data: res?.data?.data });
    }
    else {
      yield put({ type: actionTypes.GET_DAILY_SUMMARY_STORE_DETAILS_ERROR, error: res.data.message ? res.data.message : "Something went wrong!" });
    }
  }
  catch (error) {
    yield put({ type: actionTypes.GET_DAILY_SUMMARY_STORE_DETAILS_ERROR, error: "Something went wrong!" });
  }

}


function* getStoreViewWatcher() {
  yield takeLatest(actionTypes.GET_DAILY_SUMMARY_STORE_VIEW, getStoreViewWorker);
}

function* getStoreDetailsWatcher() {
  yield takeLatest(actionTypes.GET_DAILY_SUMMARY_STORE_DETAILS, getStoreDetailsWorker);
}

export function* storeViewSaga() {
  yield all([,
    getStoreViewWatcher(),
    getStoreDetailsWatcher()
  ]);
}