import React, { useEffect, useState } from "react"
import { connect } from "react-redux";
import { DEMAND_HEADER, DEMAND_TABLE_HEADERS } from "./DemandConstants"
import DemandConstraintsTable from "./DemandConstraintsTable"
import PageLoader from "../../../../components/Loader/PageLoader";
import {
    getDemandData, getAddProductsData, resetAddProductsData, setSelectedDemandTableRows, resetStyleIndex, setPageIndex,
    resetCAStyleIndex
} from "../../actions/DemandAction/DemandAction"
import { getReservedArticles } from "../../actions/DCConfigAction/DCConfigAction"
import AddProducts from "./AddProducts"
import Filters from "./Filters"
import { cloneDeep, isEmpty, isEqual } from "lodash";
import { getFinalCheckedRowsForCAData, getNewData, removeCheckedArticles } from "./DemandPagination"
import ColumnFilterServerSide from "../../../../components/Filters/ColumnFilterServerSide";
import ColumnFilter from "../../../UserManagement/ColumnFilter";
import { numberUSFormatting } from "../../../../utils/formatters/valueFormatters";
import NumberRangeColumnFilter from "../../../../components/Filters/NumberRangeColumnFilter";
import { getSizeCurve } from "../../actions/DemandAction/DemandAction"
import { levelFiltersLabelFormatter } from "../../../../utils/filterLevelMapping";

let demandSelectedRows = []
let sisterStore = ""
let store = ""
let selectedFilters = {}

const DemandConstraints = (props) => {
    const { demandData, demandDataLoading, getDemandData, getAddProductsData, demandDataError, setDemandSelectedRows, addProductsData,
        addProductsDataLoading, setIsAddProduct, selectedSisterStore, selectedNewStore, setIsMaxError, addProductsDataError, editMode,
        selectedDemandRows, resetAddProductsData, allocation_id, store_code, sister_store_code, setSelectedDemandTableRows, reservedArticles,
        loading, sisterStoresL1Mapping, setFinalSelectedProducts, dcSourceSelected, reservedArticlesError, reservedArticlesLoading,
        getReservedArticles } = props

    const [showAddProducts, setShowAddProducts] = useState(false)
    const [tableData, setTableData] = useState([])
    const [addTableData, setAddTableData] = useState([])
    const [capacity_ap, setApCapacity] = useState(null)
    const [capacity_acc, setAccCapacity] = useState(null)
    const [capacity_ft, setFtCapacity] = useState(null)
    const [isEditMode, setIsEditMode] = useState(false)
    const [isStoreChanged, setIsStoreChanged] = useState(selectedSisterStore != sisterStore || selectedNewStore != store)
    const [movedProducts, setMoveProducts] = useState([])
    const [initFinalSelectedRows, setInitSelectedRows] = useState({})

    const [pageIndex, setPageIndex] = useState(0)
    const [isFilterClicked, setIsFilterClicked] = useState(false)
    const [initialIndex, setInitialIndex] = useState(0)
    const [prevIndex, setPrevIndex] = useState(0)
    const [searchTermReq, setSearchTermReq] = useState({})
    const [sortReq, setSortReq] = useState({})
    const [isFirstCall, setisFirstCall] = useState(true)
    const [RTinstance, setRTinstance] = useState({})
    const [checkAll, setCheckAll] = useState([])
    const [checkAllReqArr, setCheckAllReqArr] = useState([])
    const [prevAction, setPrevAction] = useState("")
    const [checkedRows, setCheckedRows] = useState([])
    const [callCheckAllApi, setCallCheckAllApi] = useState(true)
    const [checkAllApisWithSearchTerm, setCheckAllApisWithSearchTerm] = useState([])
    const [isGetAllLoading, setIsGetAllLoading] = useState(false)
    const [checkAllData, setCheckAllData] = useState([])
    const [prevCheckAllCall, setPrevCheckAllCall] = useState(false)


    const fecthDataWithSearchTerm = (p_term, p_id, p_type) => {
        // if (demandData?.length) {
        if (p_type === "array") {
            setSearchTermReq((old) => {
                let l_searchTermReq = cloneDeep(old)
                var id = p_term.split(" ")
                if (p_term?.trim() == 0) {
                    l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
                }
                else {

                    l_searchTermReq[p_id] = {
                        "type": p_type,
                        "values": id,
                        "search": p_term
                    }
                }
                return {
                    ...l_searchTermReq
                }
            })
        }
        else if (Array.isArray(p_term)) {
            setSearchTermReq((old) => {
                let l_searchTermReq = cloneDeep(old)
                let min = p_term[0] == "" ? null : p_term[0]
                let max = p_term[1] == "" ? null : p_term[1]
                if (min == null && max == null) {
                    l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
                }
                else {
                    l_searchTermReq[p_id] = {
                        "type": p_type,
                        "value": {
                            "min": min,
                            "max": max,
                        }
                    }
                }
                return {
                    ...l_searchTermReq,
                }
            })
        }
        else {
            setSearchTermReq((old) => {
                let l_searchTermReq = cloneDeep(old)
                if (p_term?.trim() == 0) {
                    l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
                }
                else {
                    l_searchTermReq[p_id] = {
                        "type": p_type,
                        "value": p_term
                    }
                }
                return {
                    ...l_searchTermReq,
                }
            })
        }
        isFirstCall && setisFirstCall(false)
        // setUpdatedArticles([])
        // setIsDisabled((prev) => {
        //   if (!prev) {
        //     return true;
        //   }
        // });
        // }
    }

    const fecthDataWithSorting = (p_sort) => {
        if (!isEmpty(sortReq))
            isFirstCall && setisFirstCall(false)
        setSortReq(p_sort[0])
    }

    useEffect(() => {
        if ((selectedSisterStore != sisterStore || selectedNewStore != store) || !demandData?.length) {
            demandSelectedRows = []
            selectedFilters = {}
            // setTableData([])
            // setRTinstance({})
            sisterStore = selectedSisterStore
            store = selectedNewStore
            if (allocation_id && sisterStore == sister_store_code && store == store_code && !demandData.length) {
                setIsEditMode(true)

                getDemandData({
                    allocation_id,
                    "store_id": store,
                    "sister_store_id": sisterStore,
                    "dc": dcSourceSelected,
                    "l1_name": [{ value: "Accessories" }, { value: "Apparal" }, { value: "Footwear" }]
                })
                setIsStoreChanged(false)
            }
        }

    }, [selectedNewStore, selectedSisterStore])

    // useEffect(() => {
    //     if (isGetAllLoading) {

    //     }
    //     else if (!isStoreChanged && demandData?.length) {
    //         let data = cloneDeep(demandData)
    //         let selectedIds = []
    //         demandSelectedRows = cloneDeep(selectedDemandRows)
    //         demandSelectedRows?.map(row => selectedIds.push(row?.article))
    //         let remainingRows = []
    //         data?.map(item => {
    //             if (item.selected && !(selectedIds.includes(item.article)) && !selectedDemandRows.length) {
    //                 selectedIds.push(item.article)
    //                 demandSelectedRows.push(item)
    //             }
    //             else if (!(selectedIds.includes(item.article))) {
    //                 remainingRows.push(item)
    //             }
    //         })
    //         let allData = []
    //         demandSelectedRows.map(row => allData.push(row))
    //         allData = allData.concat(remainingRows)
    //         console.log("allData", allData, allData.filter(demand => !(movedProducts.map(item => item.article).includes(demand.article))))
    //         setTableData(allData?.length ? allData.filter(demand => !(movedProducts.map(item => item.article).includes(demand.article))) : [])
    //         if (isEditMode) {
    //             setSelectedDemandTableRows(demandSelectedRows)
    //         }
    //         // }
    //         // else {
    //         //     demandSelectedRows = cloneDeep(selectedDemandRows)
    //         //     setTableData(demandData)
    //         // }
    //     }
    // }, [demandData])

    const setSelectedRows = (data, selectedRows, isLastTable) => {
        if (data?.length) {
            setIsMaxError("")
            let rows = []
            for (let i = 0; i < selectedRows.length; i++) {
                if (!data[Number(selectedRows[i])]?.approved) {
                    rows.push(data[Number(selectedRows[i])])
                }
            }
            if (isLastTable) {
                if (selectedRows?.length) {
                    rows = rows.concat(data.filter(item => item?.approved))
                }
            }
            // maxCheck(rows)
            if (!isLastTable) {
                demandSelectedRows = rows
                setDemandSelectedRows(rows)
            }
            else {
                if (movedProducts?.length) {
                    setFinalSelectedProducts(rows)
                }
                else {
                    setFinalSelectedProducts([])
                }
            }
        }
    }

    const finalSelectedRows = (data, selectedRows) => {
        setSelectedRows(data, selectedRows, true)
    }

    const maxCheck = (selectedRows) => {
        let apSum = 0, accSum = 0, ftSum = 0

        selectedRows.forEach(row => {
            if (row?.l1_name === "Accessories") {
                accSum += Number(row?.demand_estimated)
            }
            else if (row?.l1_name === "Footwear") {
                ftSum += Number(row?.demand_estimated)
            }
            else {
                apSum += Number(row?.demand_estimated)
            }
        });
        setAccCapacity(accSum)
        setApCapacity(apSum)
        setFtCapacity(ftSum)

        let errors = []
        if (accSum > Number(selectedNewStore?.ac_capacity)) {
            errors.push(<li style={{ marginBottom: "5px" }}>Accessories: Capacity - {selectedNewStore?.ac_capacity}&nbsp;&nbsp;&nbsp;&nbsp;Demand Estimated - {accSum}</li>)
        }
        if (apSum > Number(selectedNewStore?.ap_capacity)) {
            errors.push(<li style={{ marginBottom: "5px" }}>Apparal: Capacity - {selectedNewStore?.ap_capacity}&nbsp;&nbsp;&nbsp;&nbsp;Demand Estimated - {apSum}</li>)
        }
        if (ftSum > Number(selectedNewStore?.ft_capacity)) {
            errors.push(<li style={{ marginBottom: "5px" }}>Footwear: Capacity - {selectedNewStore?.ft_capacity}&nbsp;&nbsp;&nbsp;&nbsp;Demand Estimated - {ftSum}</li>)
        }
        if (errors.length) {
            errors = [<h4>Demand Estimated Units crossing the store capacity for below department(s)</h4>, <h4 style={{ margin: '5px 0 1.5rem' }}>Please Adjust to Continue</h4>, ...errors]
            setIsMaxError(errors)
        }
    }

    const addProductsClickHandler = () => {
        setIsAddProduct(true)
        setShowAddProducts(true)
    }

    const addProducts = (allData, selectedProducts) => {
        setIsAddProduct(false)
        setShowAddProducts(false)
        let selectedIds = demandSelectedRows.map(row => row?.article)
        let remainingRows = []
        tableData.map(item => {
            if (!(selectedIds.includes(item.article))) {
                remainingRows.push(item)
            }
        })
        let data = []
        let selectedRows = []
        demandSelectedRows.map(row => data.push(row))
        for (let i = 0; i < selectedProducts.length; i++) {
            selectedRows.push(allData[Number(selectedProducts[i])])
        }
        data = data.concat(selectedRows)
        data = data.concat(remainingRows)
        demandSelectedRows = demandSelectedRows.concat(selectedRows)
        setSelectedDemandTableRows(demandSelectedRows)
        setTableData(data)
    }

    const closeAddProductsModal = () => {
        setIsAddProduct(false)
        setShowAddProducts(false)
    }

    useEffect(() => {
        let existingProducts = [...tableData, ...movedProducts]?.map(item => item.article)
        setAddTableData(addProductsData?.filter(item => !existingProducts.includes(item.article)))
    }, [addProductsData])

    const applyFilters = (filters) => {
        selectedFilters = filters
        if (!isEditMode) {
            demandSelectedRows = []
            setSelectedDemandTableRows([])
            // setMoveProducts([])
            // setFinalSelectedProducts([])
        }
        else {
            setSelectedDemandTableRows(demandSelectedRows)
        }
        resetState()
        // getDemandData({
        //     "sister_stores": sisterStoresL1Mapping,
        //     "dc": dcSourceSelected,
        //     // "sister_store_codes": sisterStoresL1Mapping[Object.keys(sisterStoresL1Mapping)],
        //     ...filters,
        //     styleIndex: 0
        // })
        getDemandConstraintsData(
            0,
            {},
            {},
            false,
            // "styleIndex": (props.checkAllStyleIndex || props.styleIndex),
        )
        setIsStoreChanged(false)
    }

    const resetState = () => {
        setCheckAllApisWithSearchTerm([]);
        props.resetStyleIndex()
        setRTinstance({})
        setPrevIndex(0)
        setPageIndex(0);
        setInitialIndex(0)
        setIsFilterClicked(true)
        setCheckAllData([])
        // setMoveProducts([])
        setTableData([])
        setPrevCheckAllCall(false)
        setSearchTermReq({})
        setSortReq({})
        setisFirstCall(true)
        setCheckAll([])
    }


    useEffect(() => {
        window.scrollTo(0, 0)
        return () => {
            setSelectedDemandTableRows(demandSelectedRows)
            // demandSelectedRows = []
        }
    }, [])

    useEffect(() => {
        if (prevAction === "" && isGetAllLoading) {
            setIsGetAllLoading(false)
        }
    }, [prevAction])

    const moveSelectedProducts = (products, setAll) => {
        let l_checkedUnchecked = checkAll.filter(val => val.checkAll || val.unCheckAll)
        if (!products && l_checkedUnchecked?.length && !prevCheckAllCall) {
            let l_checkedUnchecked = checkAll.filter(val => val.checkAll || val.unCheckAll)
            let l_checkAll = []
            for (let i = 0; i < l_checkedUnchecked.length; i++) {
                if (l_checkedUnchecked[i].checkAll) {
                    l_checkAll.push(l_checkedUnchecked[i])
                }
                if (l_checkedUnchecked[i].unCheckAll) {
                    l_checkAll = []
                }
            }

            let checked = l_checkAll.filter(val => val.checkAll)
            let unchecked = l_checkAll.filter(val => val.unCheckAll)
            for (let i = 0; i < checked.length; i++) {
                for (let j = 0; j < unchecked.length; j++) {
                    if ((isEqual(checked[i].searchTermReq, unchecked[j].searchTermReq))) {
                        checked.splice(i, 1)
                    }
                }
            }
            setCheckAllReqArr(checked)
            // setCheckAllData(tableData)
            setIsGetAllLoading(true)
            // setTableData([])
            getCheckAllData(checked)
        }
        else {
            setPrevAction("")
            setIsGetAllLoading(false)
            setIsAddProduct(false)
            setShowAddProducts(false)
            setCheckAll([])
            let productsToAdd = products?.length ? products : demandSelectedRows
            let articles = productsToAdd?.map(item => item.article)
            let nonSelectedRows = tableData.filter(row => !(articles.includes(row.article)))
            setTableData(nonSelectedRows)
            if (setAll) {
                setMoveProducts(productsToAdd)
            }
            else {
                let all = [...productsToAdd, ...movedProducts]
                let allObj = {}
                all.forEach(item => {
                    allObj[item.article] = item
                })
                setMoveProducts(Object.values(allObj))
                props?.setPageIndex({pageIndex: nonSelectedRows?.length})
                setInitialIndex(0)
            }
            setFinalSelectedProducts(demandSelectedRows)
            demandSelectedRows = []
        }
    }

    const resetFinalTable = () => {
        setMoveProducts([])
        setFinalSelectedProducts([])
    }

    const getDemandConstraintsData = (styleIndex, searchReq, sortReq, checkAllCall) => {
        let req = {
            "sister_stores": sisterStoresL1Mapping,
            "dc": dcSourceSelected,
            "styleIndex": styleIndex,
            "searchColumns": searchReq,
            "sortColumn": sortReq,
            checkAllCall,
            ...selectedFilters
        }
        getDemandData(req)
    }

    const getCheckAllData = (p_checkAllReq, data) => {
        if (!isEmpty(p_checkAllReq)) {
            let l_checkAllRequest = p_checkAllReq[0]
            if(l_checkAllRequest?.checkAll && isEmpty(l_checkAllRequest?.searchTermReq)) {
                setPrevCheckAllCall(true)
            }
            getDemandConstraintsData(
                props.checkAllStyleIndex,
                l_checkAllRequest?.searchTermReq,
                sortReq,
                true,
                // "styleIndex": (props.checkAllStyleIndex || props.styleIndex),
            )
        }
        else {
            moveSelectedProducts(data?.length ? data : checkAllData)
            setPrevAction("")
            setIsGetAllLoading(false)
        }
    }

    const removeProducts = (selectedRows) => {
        let newTableData = [...selectedRows, ...tableData]
        setTableData(newTableData)
        let productsToRemove = selectedRows?.map(row => row.article)
        setFinalSelectedProducts(movedProducts?.filter(row => !productsToRemove.includes(row.article)))
        setMoveProducts(pre => pre?.filter(row => !productsToRemove.includes(row.article)))
        // setMoveProducts()
        // demandSelectedRows = []
        let preSelectedRows = {}
        let articles = newTableData?.map(item => item?.article)
        demandSelectedRows.forEach(item => {
            preSelectedRows[articles.indexOf(item.article)] = true
        })
        demandSelectedRows = preSelectedRows
    }

    useEffect(() => {
        if (editMode) {
            getReservedArticles({
                "sister_stores": sisterStoresL1Mapping,
                "dc": dcSourceSelected,
                "store_code": selectedNewStore
            })
        }
    }, [])

    useEffect(() => {
        if (reservedArticles?.length) {
            setMoveProducts(reservedArticles)
            setTableData(tableData?.length ? tableData?.filter(demand => !(reservedArticles?.map(item => item.article).includes(demand.article))) : [])
        }
    }, [reservedArticles])

    useEffect(() => {
        if (props?.checkAllData?.length) {
            let l_finalCheckedArticles = getFinalCheckedRowsForCAData(checkAll, props.checkAllData)
            // let data = cloneDeep(checkAllData).concat(props?.checkAllData)
            let data = cloneDeep(checkAllData).concat(Object.values(l_finalCheckedArticles))
            props?.checkAllData?.length && setCheckAllData(data)
            if (!props.outOfDataCA && isGetAllLoading) {
                getCheckAllData(checkAllReqArr)
            }
            else if (props?.outOfDataCA) {
                let checkAllArr = [...checkAllReqArr]
                checkAllArr.shift()
                // setCheckAll(checkAllArr)
                setCheckAllReqArr(checkAllArr)
                getCheckAllData(checkAllArr, data)
            }
            else if (isGetAllLoading) {
                setPrevAction("")
            }
        }
        else if(props?.outOfDataCA) {
            let checkAllArr = [...checkAllReqArr]
            checkAllArr.shift()
            setCheckAllReqArr(checkAllArr)
            getCheckAllData(checkAllArr)
        }
    }, [props?.checkAllData, props?.outOfDataCA])


    useEffect(() => {
        if (demandData?.length > 0 && !isGetAllLoading) {
            if (RTinstance && RTinstance.data) {
                let l_newData = getNewData({ "p_newData": [...RTinstance?.data, ...demandData], "p_checkAll": checkAll })
                demandSelectedRows = l_newData?.finalCheckedRows
                let movedRows = movedProducts?.map(item => item.article)
                // if (!isStoreChanged && demandData?.length && movedProducts?.length) {
                //     setDemandTableData(l_newData?.finalCheckedRows)
                // }
                // else {
                    let dataforTable = [...RTinstance.data, ...demandData.filter(item => !movedRows?.includes(item.article))]
                    setTableData(dataforTable);
                    movedProducts?.length && props.setPageIndex(dataforTable?.length)
                    // setDemandTableData();
                // }

            }
            else {
                if (!isStoreChanged && demandData?.length) {
                    setDemandTableData()
                }
                else {
                    setTableData([...demandData]);
                }
            }
            // setReportingData(true)
        }
        // else {
        //     setTableData([])
        //     setRTinstance(null)
        // }
        if (isFilterClicked) {
            setInitialIndex(0)
            setPrevIndex(0)
            setIsFilterClicked(false)
        }
    }, [demandData])

    const setDemandTableData = (selectedRows) => {
        demandSelectedRows = []
        let data = cloneDeep(demandData)
        let selectedIds = []
        demandSelectedRows = cloneDeep(selectedDemandRows)
        demandSelectedRows?.map(row => selectedIds.push(row?.article))
        let remainingRows = []
        data?.map(item => {
            if (item.selected && !(selectedIds.includes(item.article)) && !selectedDemandRows.length) {
                selectedIds.push(item.article)
                demandSelectedRows.push(item)
            }
            else if (!(selectedIds.includes(item.article))) {
                remainingRows.push(item)
            }
        })
        let allData = []
        demandSelectedRows.map(row => allData.push(row))
        allData = allData.concat(remainingRows)
        let dataforTable = allData?.length ? allData.filter(demand => !(movedProducts.map(item => item.article).includes(demand.article))) : []
        setTableData(dataforTable)
        props.setPageIndex(dataforTable?.length)
        if (isEditMode) {
            setSelectedDemandTableRows(demandSelectedRows)
        }
        if (!isEmpty(selectedRows)) {
            demandSelectedRows = selectedRows
        }
        // }
        // else {
        //     demandSelectedRows = cloneDeep(selectedDemandRows)
        //     setTableData(demandData)
        // }
    }


    const fetchData = (index) => {
        if ((prevIndex >= index || index < pageIndex) || props?.out_of_data)
            return;

        // retainCheckboxState()
        setPrevIndex(index)
        setInitialIndex(Math.floor(pageIndex / 10))
        // getDemandData({
        //     "sister_stores": sisterStoresL1Mapping,
        //     "dc": dcSourceSelected,
        //     // "sister_store_codes": sisterStoresL1Mapping[Object.keys(sisterStoresL1Mapping)],
        //     ...selectedFilters,
        //     "styleIndex": props.styleIndex,
        //     "searchColumns": searchTermReq,
        //     "sortColumn": sortReq,
        // })
        getDemandConstraintsData(
            props.styleIndex,
            searchTermReq,
            sortReq,
            false,
            // "styleIndex": (props.checkAllStyleIndex || props.styleIndex),
        )
    }

    const updateSetAll = (value) => {
        let l_newAction = {
            "searchTermReq": searchTermReq
        }
        if (!value.checked) {
            setPrevAction("checkAll")
            l_newAction["checkAll"] = true

        }
        else {
            setPrevAction("unCheckAll")
            l_newAction["unCheckAll"] = true
            let l_updatedCheckedRows = removeCheckedArticles(checkedRows, searchTermReq)
            setCheckedRows(l_updatedCheckedRows)
        }
        setCheckAll((old) => [...old, l_newAction])
    }

    const updateCheckedRows = (value) => {
        let l_newAction = {}, l_article = value.instance.article

        if (!value.checked) {
            setPrevAction("checkRow")
            if (prevAction === "checkRow") {
                let checkAllCopy = [...checkAll]
                let l_lastValueIndex = (checkAllCopy.length - 1 === -1) ? 0 : checkAllCopy.length - 1
                let l_checekdRows = checkAllCopy?.[l_lastValueIndex]
                l_checekdRows["checkedRows"].push(l_article)
                checkAllCopy.pop()
                setCheckAll([...checkAllCopy, l_checekdRows])
            }
            else {
                l_newAction["checkedRows"] = [l_article]
                setCheckAll((old) => [...old, l_newAction])
            }

            let l_checkedRow = { [l_article]: value.instance }
            setCheckedRows((old) => {
                return {
                    ...old,
                    ...l_checkedRow
                }
            })

        }
        else {
            setPrevAction("unCheckRow")
            if (prevAction === "unCheckRow") {
                let checkAllCopy = [...checkAll]
                let l_lastValueIndex = (checkAllCopy.length - 1 === -1) ? 0 : checkAllCopy.length - 1
                let l_checekdRows = checkAllCopy[l_lastValueIndex]
                l_checekdRows["unCheckedRows"].push(l_article)
                checkAllCopy.pop()
                setCheckAll([...checkAllCopy, l_checekdRows])
            }
            else {
                l_newAction["unCheckedRows"] = [l_article]
                setCheckAll((old) => [...old, l_newAction])
            }


            let l_checkedRow = cloneDeep(checkedRows)
            delete l_checkedRow[l_article]
            setCheckedRows((old) => {
                return {
                    ...l_checkedRow
                }
            })
        }
    }

    useEffect(() => {
        setPageIndex(props.pageIndex)
    }, [props.pageIndex])

    useEffect(() => {
        if (RTinstance) {
            //   let l_tableData = cloneDeep(tableInstance)
            //   let uniqUpdatedArticles = [...new Set(updatedArticles)]; 
            //   let l_updatedRows = l_tableData?.data?.filter(val => uniqUpdatedArticles.includes(val.article)) || []
            //   let l_finalUpdatedRows = getRecentUpdatesOnArticle(updatedRows,l_updatedRows)
            //   setUpdatedRows(l_finalUpdatedRows)
            //   if(userEditAfterValidationOnRest){
            //     let uniqUpdatedArticles = [...new Set(updatedArticlesForRest)]; 
            //     let l_updatedRows = l_tableData?.data?.filter(val => uniqUpdatedArticles.includes(val.article)) || []
            //     let l_finalUpdatedRows = getRecentUpdatesOnArticle(updatedRowsForRest,l_updatedRows)
            //     setUpdatedRowsForRest(l_finalUpdatedRows)
            //   }
            if ((!isEmpty(searchTermReq) || !isFirstCall || !isEmpty(sortReq)) && !isEmpty(selectedFilters)) {
                props.resetStyleIndex();
                callCheckAllApi && setCallCheckAllApi(false);
                setTableData([])
                setRTinstance(null);
                setInitialIndex(0);
                setPrevIndex(0);
                setPageIndex(0);
                // setInitialSelectedRows({})
                // getDemandData({
                //     ...selectedFilters, "sister_stores": sisterStoresL1Mapping, "dc": dcSourceSelected,
                //     "styleIndex": 0, "searchColumns": searchTermReq, "sortColumn": sortReq
                // });
                getDemandConstraintsData(
                    0,
                    searchTermReq,
                    sortReq,
                    false,
                    // "styleIndex": (props.checkAllStyleIndex || props.styleIndex),
                )

            }
        }
    }, [searchTermReq, sortReq])

    return (
        <PageLoader loader={isGetAllLoading} gridLoader={true}>
            <div>
                <section className="section mb-4">
                    <div className="container__header m-0">
                        <h3 className="fnt-bold">{DEMAND_HEADER}</h3>
                    </div>
                    <hr />

                    <Filters selectedFilters={selectedFilters} handleFilterRequest={applyFilters} l1_name={props?.selectedStore?.channel}
                        selectedDepartments={Object.keys(sisterStoresL1Mapping).map(dep => { return { label: dep, value: dep } })} />
                </section>

                <section className="section mr-4 mt-4">
                    {
                        // !!(tableData.length || movedProducts.length) &&
                        process.env.REACT_APP_ENV_ID != 8 &&
                        <div className="row add-pro-btn config-btn-container mr-2" onClick={addProductsClickHandler}>
                            <button className="btn-primary-rounded create-config-btn mr-2">
                                <i
                                    className="fa fa-plus"
                                    title="Create Allocation"
                                    aria-hidden="true"
                                ></i>
                            </button>
                            <p className="fnt-sm m-0">Add Products</p>
                        </div>
                    }
                    <PageLoader loader={demandDataLoading || loading} gridLoader={true}>
                        {(demandDataError) ? (<div className="error">{demandDataError}</div>) :
                            (tableData ? (
                                <DemandConstraintsTable
                                    initialPageIndex={initialIndex}
                                    paginateTable
                                    setRTInstance={setRTinstance}
                                    fetchData={fetchData}
                                    setMoveProducts={() => { moveSelectedProducts(false) }}
                                    demandSelectedRows={demandSelectedRows} store={sisterStoresL1Mapping[Object.keys(sisterStoresL1Mapping)[0]]}
                                    data={tableData} setSelectedRows={setSelectedRows}
                                    updateSetAll={(val) => updateSetAll(val)}
                                    updateCheckedRows={(val) => updateCheckedRows(val)}
                                    fecthDataWithSearchTerm={fecthDataWithSearchTerm}
                                    fecthDataWithSorting={fecthDataWithSorting}
                                    searchTermReq={searchTermReq} />
                            ) : null)}
                    </PageLoader>
                </section>
                <section className="section mr-4 mt-4">
                    <PageLoader loader={reservedArticlesLoading} gridLoader={true}>
                        <div className="container__header m-0 justify-content-center">
                            <h3 className="fnt-bold">Selected Styles (Store - {selectedNewStore})</h3>
                        </div>
                        {(reservedArticlesError) ? (<div className="error">{reservedArticlesError}</div>) :
                            (tableData ? (
                                <DemandConstraintsTable setSelectedRows={finalSelectedRows} data={movedProducts} isFinalTable={true}
                                    initSelectedRows={initFinalSelectedRows} isFinal tableId={"demand_table2"}
                                    editMode={editMode} setPreSelectedProducts={moveSelectedProducts} removeProducts={removeProducts}
                                />

                            ) : null)}
                    </PageLoader>
                </section>
                {
                    showAddProducts &&
                    <AddProducts setMoveProducts={moveSelectedProducts} getAddProducts={getAddProductsData} data={addTableData}
                        dataLoading={addProductsDataLoading} dataError={addProductsDataError} addProducts={addProducts}
                        showModal={showAddProducts} sisterStoresL1Mapping={sisterStoresL1Mapping} store={store} sisterStore={sisterStore}
                        dcSelected={dcSourceSelected} sisterStores={props.sisterStores} resetAddProductsData={resetAddProductsData}
                        closeModal={closeAddProductsModal} l1_name={props?.selectedStore?.channel}
                        selectedDepartments={Object.keys(sisterStoresL1Mapping).map(dep => { return { label: dep, value: dep } })} />
                }
            </div>
        </PageLoader>
    )
}

const mapStateToProps = (newStoreSetup) => {
    const { demandConstraints, newStoreDetailConfig, sisterStoreDCConfig } = newStoreSetup
    return {
        demandDataLoading: demandConstraints.demandDataLoading,
        demandData: demandConstraints.data?.demandData,
        demandDataError: demandConstraints.demandDataError,
        addProductsDataLoading: demandConstraints.addProductsDataLoading,
        addProductsData: demandConstraints.addProductsData,
        addProductsDataError: demandConstraints.addProductsDataError,
        selectedSisterStore: newStoreDetailConfig?.selectedSisterStore[0]?.store_code,
        selectedNewStore: newStoreDetailConfig?.selectedNewStore[0]?.store_code,
        selectedStore: newStoreDetailConfig?.selectedNewStore[0],
        selectedDemandRows: demandConstraints.selectedDemandRows,
        // sisterStoresL1Mapping: sisterStoreDCConfig?.selectedSisterStores,
        sisterStores: sisterStoreDCConfig?.filterData?.stores,
        dcSourceSelected: sisterStoreDCConfig?.selectedDc,
        reservedArticles: sisterStoreDCConfig?.reservedArticles,
        reservedArticlesLoading: sisterStoreDCConfig?.reservedArticlesLoading,
        reservedArticlesError: sisterStoreDCConfig?.reservedArticlesError,
        sisterStoresL1Mapping: sisterStoreDCConfig?.sisterStoreMapping,
        styleIndex: demandConstraints?.style_index,
        checkAllStyleIndex: demandConstraints?.checkAllStyleIndex,
        pageIndex: demandConstraints?.pageIndex,
        out_of_data: demandConstraints?.out_of_data,
        checkAllData: demandConstraints?.checkAllData,
        outOfDataCA: demandConstraints?.outOfDataCA
    }
}

const mapDispatchToProps = (dispatch) => ({
    getDemandData: (payload) => dispatch(getDemandData(payload)),
    getAddProductsData: (payload) => dispatch(getAddProductsData(payload)),
    setSelectedDemandTableRows: (payload) => dispatch(setSelectedDemandTableRows(payload)),
    resetAddProductsData: () => dispatch(resetAddProductsData()),
    getReservedArticles: (payload) => dispatch(getReservedArticles(payload)),
    getSizeCurve: (payload) => dispatch(getSizeCurve(payload)),
    resetStyleIndex: () => dispatch(resetStyleIndex()),
    setPageIndex: (payload) => dispatch(setPageIndex(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DemandConstraints)