import { isEmpty, pickBy } from "lodash";
import { func } from "prop-types";
import { takeLatest, put, all, call } from "redux-saga/effects";
import {
    getUserData,
    getFormData,
    createUser,
    editUser,
    deleteUser,
} from "../../routes/api";
import { createUserError, createUserSuccesss, CREATE_USER, deleteUserError, deleteUserSuccesss, DELETE_USER, EDIT_USER, fetchUserDataError, fetchUserDataSuccesss, FETCH_USER_DATA, getFormDataError, getFormDataSuccesss, GET_FORM_DATA, } from "./UserManagementActions";

function* getUsersDataWorker(action) {
  try {
    const { payload } = action
    const res = yield call(getUserData, payload);

    if (res.data.status) {
        const respose = res?.data?.userData.map(elem => {
            return {
                ...elem,
                'l1_name': elem?.attributes?.l1_name?.join(', ') || null,
                'l2_name': elem?.attributes?.l2_name?.join(', ') || null
            }
        })
        res.data.userData = respose
      yield put(fetchUserDataSuccesss({ data: res.data.userData }));
    } else {
      yield put(fetchUserDataError({ error: "Something went wrong!" }));
    }
  } catch (error) {
    yield put(fetchUserDataError({ error: "Something went wrong!" }));
  }
}

function* getFormDataWorker(action) {
    try {
        const { payload } = action        
        const res = yield call(getFormData, payload);
   
        if (res.data.status) {
            let roleOptions = []
            let l_groupResult = res.data?.getGroupResult[0]
            for ( let i in l_groupResult?.["group_name"]){
                roleOptions.push({
                    label : l_groupResult["group_name"][i],
                    value: l_groupResult["group_id"][i]
                })
            }

            let level1Options = res.data?.getLevelResult[0]?.l1_name.map((elem)=> {
                return{
                    label: elem,
                    value: elem
                }
            })
            let level2Options = (res.data?.getLevelResult[0]?.l2_name || [])?.map((elem)=> {
              return{
                  label: elem,
                  value: elem
              }
          })
          let level3Options = (res.data?.getLevelResult[0]?.l3_name || [])?.map((elem)=> {
            return{
                label: elem,
                value: elem
            }
          })

            res.data.roleOptions = roleOptions
            res.data.level1Options = level1Options
            res.data.level2Options = level2Options
            res.data.level3Options = level3Options

          yield put(getFormDataSuccesss({ data: res.data }));
        } else {
          yield put(getFormDataError({ error: "Something went wrong!" }));
        }
      } catch (error) {
        yield put(getFormDataError({ error: "Something went wrong!" }));
      }
}

function* createUserWorker(action) {
    try {
        const {payload} = action
        const res = yield call(createUser, payload);
        if (res.data.status) {
          yield put(createUserSuccesss({ data: res.data }));
        } else {
          yield put(createUserError({ error: res?.data?.error || "Something went wrong!" }));
        }
      } catch (error) {
        yield put(createUserError({ error: error }));
      }
}

function* editUserWorker(action) {
    try {
        const {payload} = action
        const res = yield call(editUser, payload);
        if (res.data.status) {
          yield put(createUserSuccesss({ data: res.data }));
        } else {
          yield put(createUserError({ error: "Something went wrong!" }));
        }
      } catch (error) {
        yield put(createUserError({ error: error }));
      }
}

function* deleteUserWorker(action){
    try {
        const {payload} = action
        const res = yield call(deleteUser, payload);
        if (res.data.status) {
          yield put(deleteUserSuccesss({ data: res.data }));
        } else {
          yield put(deleteUserError({ error: "Something went wrong!" }));
        }
      } catch (error) {
        yield put(deleteUserError({ error: error }));
      }
}

function* getUsersDataWatcher() {
  yield takeLatest(FETCH_USER_DATA, getUsersDataWorker);
}

function* getFormDataWatcher() {
    yield takeLatest(GET_FORM_DATA, getFormDataWorker)
}

function* createUserWatcher(){
    yield takeLatest(CREATE_USER,createUserWorker)
}

function* editUserWatcher(){
    yield takeLatest(EDIT_USER, editUserWorker)
}

function* deleteUserWatcher(){
    yield takeLatest(DELETE_USER, deleteUserWorker)
}


export function* userManagementSaga() {
  yield all([
    getUsersDataWatcher(),
    getFormDataWatcher(),
    createUserWatcher(),
    editUserWatcher(),
    deleteUserWatcher(),
  ]);
}
