import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { cloneDeep, forEach, isEmpty } from 'lodash';
import AdaVisualFilters from './AdaVisualFilters';
import AdaVisualView from './AdaVisualView';
import PageLoader from "../../components/Loader/PageLoader";
import moment from "moment";
import Notification from "../../components/Notification/Notifications";
import * as Notify from "../../components/Notification/Notifications";
import * as adaVisualActions from './actions/AdaVisualActions';
import { getAdditionalDiffOfUpdatedArticles, getEditedWeeklyAdaData, getEditedWeeklyHierarchyData, getEditedWeeklyStoreHierarchyData, getStoreArticleCombination } from './AdaUtilities';
import { NO_REVIEW_MSG, NO_SAVE_MSG, REVIEW_FAILED_MSG, REVIEW_SUCCESS_MSG, SAVE_SUCCESS_MSG, SAVE_FAILED_MSG, SAVE_WARNING_MSG } from './AdaVisualConstants';
import "./AdaVisual.css";
import Card from '../../components/Card/Card'


/**
 * Stand Alone Ada container with respective state
 * and handler functions
 * @returns AdaVisualVIew with Filters section
 */
class StandAloneContainer extends React.Component {
    constructor(props) {
        super(props);
        this.handleFilterRequest = this.handleFilterRequest.bind(this);
        this.handleReviewRequest = this.handleReviewRequest.bind(this);
        this.handleSaveRequest = this.handleSaveRequest.bind(this);
        this.resetAdaDisplayState = this.resetAdaDisplayState.bind(this);
        this.getLoaderMessage = this.getLoaderMessage.bind(this);
        this.state = {
            displayAda: false,
        };
    }

    componentWillUnmount() {
        const { adaVisualActions } = this.props;
        adaVisualActions.resetAdaVisual();
    }

    /**
     * Function to handle Filter request
     * @param {*} request 
     */
    handleFilterRequest(request) {
        const { adaVisualActions, startFiscalYearWeek, endFiscalYearWeek } = this.props;
        let date = new Date()
        const zero = "0"
        const currentDate = moment().format("WW");
        let currentWeek = (26 + parseInt(currentDate)) % 52
        currentWeek = currentWeek === 0 ? 52 : currentWeek;
        
        let currentWeekYear = moment().format("YYYY")
        var currentMonth = moment().format("MM")
        if (currentMonth >= "07") {
            currentWeekYear = date.getFullYear() + 1
            currentWeekYear = currentWeekYear.toString()

        }
        if (currentWeek < 10) {

            currentWeek = zero?.concat(currentWeek)

        }
        const data = currentWeekYear.concat(currentWeek)
        adaVisualActions.setAdaCurrentDate(data);
        const currentDateKey = moment().format("YYYY-MM-DD")
        let reqBody = {};
        if (request) {
            reqBody = {
                start_fw: startFiscalYearWeek,
                end_fw: endFiscalYearWeek,
                current_fw: data,
                current_date: currentDateKey,
                l1_name: [request?.department.value],
                l2_name: Array.isArray(request?.gender) ? request?.gender?.map(item => item.value) : [request?.gender.value],
                l3_name: request?.subCat?.map(item => item.value),
                l4_name: request?.dcs?.map(item => item.value),
                l5_name: request?.level5?.map(item => item.value),
                l6_name: request?.level6?.map(item => item.value),
                l7_name: request?.level7?.map(item => item.value),
                // articles: request?.articleId?.length!==0 ? request?.articleId?.map(item => item.value):[],
                articles: request?.articleId?.length ? request?.articleId?.map(item => item.value):request?.articleFilter?.map(item => item.value),
                assortment_indicator: request?.assortmentIndicator?.map(item => item.value),
                store_code: request?.store?.map(item => item.value),
            };
            adaVisualActions.setAdaStandAloneRequest({...reqBody, allArticles: request?.articleFilter?.map(item => item.value)});
            adaVisualActions.getAdaStandAloneData(reqBody);
            this.setState({
                displayAda: true,
            });
        }
        else {
            //implement Negative scenarios
        }
    }

    /**
     * Function to handle Review Ada Visual Request
     * @param {event} e 
     */
    handleReviewRequest(e) {
        if (e) {
            const {
              adaVisualActions,
              startFiscalYearWeek,
              endFiscalYearWeek,
              isReviewSuccess,
              adaStandAloneReqBody,
              isForecastModified,
              isHierarchyModified,
              adaVisualData,
              adaVisualDataCopy,
              hierarchyDataCopy,
              hierarchyData,
              updatedStoreData,
              updatedArticleWeek,
              storeHierarchyData,
              updatedArticleData,
              updatedForecastData,
              currentArticlesInView,
            } = this.props;
            const { current_fw, l1_name, l2_name, l3_name, l4_name,l5_name,l6_name,l7_name, articles, assortment_indicator, store_code, allArticles } = adaStandAloneReqBody;
            const updatedAdaWeekData = Object.values(updatedForecastData); // Use the updated data for adjusted forecast.
            let updatedHierarchyWeekData = getEditedWeeklyHierarchyData(hierarchyData, hierarchyDataCopy, current_fw);
            let additionalHierarchyWeekData = getAdditionalDiffOfUpdatedArticles(updatedArticleData, 'nested'); // nested is used for multiple article data.
            
            forEach(additionalHierarchyWeekData, (items, article) => {
                forEach(items, (item, week) => {
                  // is the updated data have data for the same article in additional data ?
                  if (updatedHierarchyWeekData[article]) {
                    updatedHierarchyWeekData[article] = {
                      ...updatedHierarchyWeekData[article],
                      ...additionalHierarchyWeekData[article],
                    };
                  } else {
                    updatedHierarchyWeekData = {
                      ...updatedHierarchyWeekData,
                      [article]: additionalHierarchyWeekData[article],
                    };
                  }
                });
            })

            let reviewReq = {};
            let articlesUpdated = Object.keys(updatedArticleWeek);

            forEach(articlesUpdated, key => {
                // If there is an update on both level check the weeks different or same ?
                if (updatedStoreData[key] && updatedArticleWeek[key]) {
                    forEach(updatedArticleWeek[key], week => {
                        // if there is an entry for a week that mean article + store update exists hence prepare data
                        // for all the stores.
                        if (updatedStoreData[key][week]) {
                            forEach(storeHierarchyData[key], stores => {
                                forEach(stores, storeData => {
                                    // Collect all stores data for edited weeks.
                                    const {fy_fw, fy_fm, adjusted_forecast, store, article } = storeData
                                    if (storeData.fy_fw === week) {
                                        if (updatedHierarchyWeekData[article]) {
                                          updatedHierarchyWeekData[article] = {
                                            ...updatedHierarchyWeekData[
                                              article
                                            ],
                                            [`${fy_fw}-${store}`]: {
                                              fy_fm: fy_fm,
                                              fy_fw: fy_fw,
                                              input: adjusted_forecast,
                                              store: store,
                                            },
                                          };
                                        } else {
                                          updatedHierarchyWeekData = {
                                            ...updatedHierarchyWeekData,
                                            [article]: {
                                              [`${fy_fw}-${store}`]: {
                                                fy_fm: fy_fm,
                                                fy_fw: fy_fw,
                                                input: adjusted_forecast,
                                                store: store,
                                              },
                                            },
                                          };
                                        }
                                    }
                                })
                            }) 
                        }
                    })
                }
            })
            
            // Remove the already updated data for stores + article.
            let remainingStoreData = cloneDeep(updatedStoreData);
            forEach(updatedArticleWeek, (weeks, article) => {
                forEach(weeks, week => {
                    delete remainingStoreData[article]?.[week];
                })
            })
            
            // Have all the only store level data added in the combined result.
            forEach(Object.keys(remainingStoreData), key => {
                forEach(remainingStoreData[key], stores => {
                    forEach(stores, storeData => {
                      // Collect all stores data for edited weeks.
                      const { fy_fw, fy_fm, store_input, store, article } =
                        storeData;
                      if (updatedHierarchyWeekData[article]) {
                        updatedHierarchyWeekData[article] = {
                          ...updatedHierarchyWeekData[article],
                          [`${fy_fw}-${store}`]: {
                            fy_fm: fy_fm,
                            fy_fw: fy_fw,
                            input: store_input,
                            store: store,
                          },
                        };
                      } else {
                        updatedHierarchyWeekData = {
                          ...updatedHierarchyWeekData,
                          [article]: {
                            [`${fy_fw}-${store}`]: {
                              fy_fm: fy_fm,
                              fy_fw: fy_fw,
                              input: store_input,
                              store: store,
                            },
                          },
                        };
                      }
                    })
                }) 
            })
            
            // This is done here to change the Object structure to array based structure for BE API. 
            Object.keys(updatedHierarchyWeekData).forEach( article => {
                updatedHierarchyWeekData[article] = Object.values(updatedHierarchyWeekData[article])
            })
            if (!isReviewSuccess && ((isHierarchyModified && !isEmpty(updatedHierarchyWeekData) )  || (isForecastModified && !isEmpty(updatedAdaWeekData)))) {

                const tabValue = isForecastModified ? 'all' : (isHierarchyModified ? 'individual' : '');
                reviewReq = {
                    user_id: localStorage.getItem("user_id"),
                    screen: "stand alone",
                    tab: tabValue,
                    review_save_tag: "review",
                    month_or_week: "week",
                    time_stamp: moment().toISOString(),
                    start_fw: startFiscalYearWeek,
                    end_fw: endFiscalYearWeek,
                    current_fw: current_fw,
                    level1: l1_name,
                    level2: l2_name,
                    level3: l3_name && l3_name ,
                    level4: l4_name && [...l4_name] ,
                    level5: l5_name && [...l5_name] ,
                    level6: l6_name && [...l6_name] ,
                    level7: l7_name && [...l7_name] ,
                    selected_articles: isForecastModified ? (articles?.length ? [...articles] : [...allArticles]) : (currentArticlesInView &&  [...currentArticlesInView]),
                    assortment_indicator: assortment_indicator && [...assortment_indicator],
                    store_code: store_code && [...store_code],
                    article_store_combination: null,
                    changed_combinations_lower_hierarchy_week:
                        (isHierarchyModified && !isEmpty(updatedHierarchyWeekData)) ?
                            { ...updatedHierarchyWeekData } : null,
                    changed_combinations_adjusted_forecast_week: isForecastModified && !isEmpty(updatedAdaWeekData) ? updatedAdaWeekData : null,
                };

                adaVisualActions.reviewAdaVisual(reviewReq);
            } else {
                Notify.error(NO_REVIEW_MSG);
            }
        }
    }

    /**
     * Function to handle Save Ada Visual Request
     * @param {*} e 
     */
    handleSaveRequest() {
        
      const { adaVisualActions, startFiscalYearWeek, endFiscalYearWeek,
          adaStandAloneReqBody, isReviewSuccess, isForecastModified, isHierarchyModified, currentArticlesInView } = this.props

            let saveReq = {};
            if (isReviewSuccess) {
                const { current_fw, l1_name, l2_name, l3_name, l4_name,l5_name,l6_name,l7_name, articles, assortment_indicator, store_code, allArticles } = adaStandAloneReqBody;
                const tabValue = isForecastModified ? 'all' : (isHierarchyModified ? 'individual' : '');
                saveReq = {
                    user_id: localStorage.getItem("user_id"),
                    screen: "stand alone",
                    tab: tabValue,
                    review_save_tag: "save",
                    month_or_week: "week",
                    start_fw: startFiscalYearWeek,
                    end_fw: endFiscalYearWeek,
                    current_fw: current_fw,
                    level1: l1_name,
                    level2: l2_name,
                    level3: l3_name && l3_name ,
                    level4: l4_name && [...l4_name] ,
                    level5: l5_name && [...l5_name] ,
                    level6: l6_name && [...l6_name] ,
                    level7: l7_name && [...l7_name] ,
                    selected_articles: isForecastModified ? (articles?.length ? [...articles] : [...allArticles]) : (currentArticlesInView && [...currentArticlesInView]),
                    assortment_indicator: assortment_indicator && [...assortment_indicator],
                    store_code: store_code && [...store_code],
                    article_store_combination: null,
                    changed_combinations_lower_hierarchy_week: null,
                    changed_combinations_adjusted_forecast_week: null,
                    time_stamp: moment().toISOString(),
                };
                adaVisualActions.saveAdaVisual(saveReq);
              } else {
                  Notify.error(NO_SAVE_MSG);
            }

    }

    resetAdaDisplayState() {
        this.setState({
            displayAda: false,
        });
    }

    getLoaderMessage() {
        const { isReviewReq, isSaveReq } = this.props;
        let loadingMsg = '';
        isReviewReq ? loadingMsg = 'Reviewing...'
            : (isSaveReq ? loadingMsg = 'Saving...' : loadingMsg = 'Loading...');

        return loadingMsg;
    }

    render() {
        const { isAdaDataLoading, isReviewReq, isSaveReq,isAdaLoadingSuccess } = this.props;

        return (
            <React.Fragment>
                {/* <Notification /> */}
               
                <div className="filter row" style={{ marginTop: "8%" }}>
                    <AdaVisualFilters handleFilterRequest={this.handleFilterRequest} resetAdaDisplayState={this.resetAdaDisplayState} />
                </div>

                <PageLoader loader={isAdaDataLoading || isReviewReq || isSaveReq} loaderMessage={this.getLoaderMessage()}>

                    {isAdaLoadingSuccess &&
                        <AdaVisualView handleReviewRequest={this.handleReviewRequest}
                            handleSaveRequest={this.handleSaveRequest} handleShowAlert={this.handleShowAlert} />}
                </PageLoader>
            </React.Fragment >
        )
    }
}

const mapStateToProps = ({ adaVisual }) => ({
    startFiscalYearWeek: adaVisual.startFiscalYearWeek,
    endFiscalYearWeek: adaVisual.endFiscalYearWeek,
    isAdaDataLoading: adaVisual.isAdaDataLoading,
    isAdaReqFailed: adaVisual.isAdaReqFailed,
    isAdaLoadingSuccess: adaVisual.isAdaLoadingSuccess,
    adaStandAloneReqBody: adaVisual.adaStandAloneReqBody,
    accuracy: adaVisual.accuracy,
    isReviewReq: adaVisual.isReviewReq,
    isReviewFailed: adaVisual.isReviewFailed,
    isReviewSuccess: adaVisual.isReviewSuccess,
    isForecastModified: adaVisual.isForecastModified,
    isHierarchyModified: adaVisual.isHierarchyModified,
    adaVisualData: adaVisual.adaVisualData,
    adaVisualDataCopy: adaVisual.adaVisualDataCopy,
    hierarchyData: adaVisual.hierarchyData,
    hierarchyDataCopy: adaVisual.hierarchyDataCopy,
    isSaveReq: adaVisual.isSaveReq,
    isSaveSuccess: adaVisual.isSaveSuccess,
    isSaveFailed: adaVisual.isSaveFailed,
    isHierarchyTable: adaVisual.isHierarchyTable,
    storeHierarchyData: adaVisual.storeHierarchyData,
    storeHierarchyDataCopy: adaVisual.storeHierarchyDataCopy,
    updatedStoreData: adaVisual.updatedStoreData,
    updatedArticleWeek: adaVisual.updatedArticleWeek,
    updatedArticleData: adaVisual.updatedArticleData,
    updatedForecastData: adaVisual.updatedForecastData,
    currentArticlesInView: adaVisual.currentArticlesInView,
});

const mapDispatchToProps = (dispatch) => ({
    adaVisualActions: bindActionCreators(adaVisualActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StandAloneContainer);